import { Alert, DialogTitle, TableHead } from 'components'
import { useDocumentTitle } from 'utils'
import { Box, Table, TableRow, TableCell, Button } from '@mui/material'
import { OrganizationSupplierV2ApiDto } from 'typescript-axios'
import { useState } from 'react'
import { AddCircleOutlineRounded } from '@mui/icons-material'
import { DataFeedItemDomainV2 } from 'services/mingle/__generated__/Api'
import { DataSubscription, SubscriptionType } from 'hooks/dataFeed/api'
import DiscoveryTypeListItem from '../../DiscoveryTypeListItem'
import { SharedDialogTitleProps } from '../index'
import ActionCell from '../../ActionCell'
import ResultCell from '../../ResultCell'
import OriginLabel from '../../OriginLabel'
import DomainSettings from './DomainSettingsDialog'
import StatusChip from '../../StatusChip'
import AddDomainDialog from './AddDomainDialog'

const DomainTableRow = ({
  result,
  subscription,
  status,
  handleAccept,
  handleReject,
  handleSettings,
  loading,
  type,
}: DataFeedItemDomainV2 & {
  handleAccept: () => void
  handleReject: () => void
  handleSettings: () => void
  loading: 'approved' | 'declined' | null
}) => {
  return (
    <TableRow>
      <TableCell>
        <ResultCell result={result} type={type} subscription={subscription} status={status} />
      </TableCell>
      <TableCell>
        <OriginLabel origin={subscription} />
      </TableCell>
      <TableCell sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
        <ActionCell
          loading={loading}
          status={status}
          handleAccept={handleAccept}
          handleReject={handleReject}
          handleSettings={handleSettings}
        />
      </TableCell>
    </TableRow>
  )
}

interface Props {
  items: DataFeedItemDomainV2[]
  supplier: OrganizationSupplierV2ApiDto
  sharedDialogTitleProps: SharedDialogTitleProps
  relatedSubscriptions?: DataSubscription[]
  loading: 'approved' | 'declined' | null
  updateDataFeedItemStatus: ({
    id,
    status,
    subscriptions,
  }: {
    id: string
    status: 'approved' | 'declined'
    subscriptions: {
      subscriptionType: SubscriptionType
      searchValue: string
      action: 'add' | 'remove'
    }[]
  }) => void
}

const DomainResults = ({
  items,
  supplier,
  sharedDialogTitleProps,
  relatedSubscriptions,
  loading,
  updateDataFeedItemStatus,
}: Props) => {
  useDocumentTitle(`${supplier.name} - Available domains`, true)

  const [domainSettingsOpen, setDomainSettingsOpen] = useState<string | null>(null)
  const [addDomainDialogOpen, setAddDomainDialogOpen] = useState<boolean>(false)

  const domainsInReview = items.filter((domain) => domain.status === 'in_review')
  const domainsInUse = items.filter(
    (domain) =>
      domain.status === 'approved' ||
      domain.status === 'auto_approved' ||
      domain.status === 'ai_approved'
  )
  const rejectedDomains = items.filter(
    (domain) =>
      domain.status === 'declined' ||
      domain.status === 'auto_declined' ||
      domain.status === 'ai_declined' ||
      domain.status === 'rejected'
  )

  const domainSettingsItem = items.find(
    (domain) => domainSettingsOpen && domain.id === domainSettingsOpen
  )

  const handleUpdateDataFeedItemStatus = ({
    id,
    status,
    subscriptions,
  }: {
    id: string
    status: 'approved' | 'declined'
    subscriptions: {
      subscriptionType: SubscriptionType
      searchValue: string
      action: 'add' | 'remove'
    }[]
  }) => {
    updateDataFeedItemStatus({
      id,
      status,
      subscriptions,
    })
    if (domainSettingsOpen) setDomainSettingsOpen(null)
  }

  return (
    <>
      <DialogTitle
        {...sharedDialogTitleProps}
        secondIcon={<DiscoveryTypeListItem type="domain" withText={false} />}
        secondTitle="Websites"
        description={`We monitor websites for sustainability related data like certificates. Websites can be discovered by our AI, through All data on your supplier or be
        added by you. Select what domains to monitor for ${supplier.name}.`}
      />
      <Box sx={{ p: 2 }}>
        <Table>
          <TableHead
            headCells={[
              {
                id: 'domain',
                label: 'Domain',
                disablePadding: false,
                numeric: false,
                sortable: false,
              },
              {
                id: 'origin',
                label: 'Origin',
                disablePadding: false,
                numeric: false,
                sortable: false,
              },
              {
                id: 'action',
                label: 'Action',
                disablePadding: false,
                numeric: true,
                sortable: false,
              },
            ]}
          />
          {items.length === 0 && (
            <TableRow>
              <TableCell colSpan={100}>
                <Alert severity="info" message="No domains found. Add a domain to get started." />
              </TableCell>
            </TableRow>
          )}
          {domainsInReview.length > 0 && (
            <>
              <TableRow>
                <TableCell colSpan={100} sx={{ backgroundColor: '#efefef' }}>
                  <StatusChip status="in_review" customLabel="Needs review" />
                </TableCell>
              </TableRow>
              {domainsInReview.map((domain) => {
                return (
                  <DomainTableRow
                    {...domain}
                    key={domain.id}
                    handleAccept={() =>
                      handleUpdateDataFeedItemStatus({
                        id: domain.id,
                        status: 'approved',
                        subscriptions: [
                          {
                            subscriptionType: SubscriptionType.INDUSTRY,
                            searchValue: domain.result.result,
                            action: 'add' as const,
                          },
                          {
                            subscriptionType: SubscriptionType.DATA,
                            searchValue: domain.result.result,
                            action: 'add' as const,
                          },
                        ].filter(
                          (sub) =>
                            !relatedSubscriptions?.find(
                              (s) =>
                                s.subscriptionType === sub.subscriptionType &&
                                s.searchValue === sub.searchValue
                            )
                        ),
                      })
                    }
                    handleReject={() =>
                      handleUpdateDataFeedItemStatus({
                        id: domain.id,
                        status: 'declined',
                        subscriptions: [
                          {
                            subscriptionType: SubscriptionType.INDUSTRY,
                            searchValue: domain.result.result,
                            action: 'remove' as const,
                          },
                          {
                            subscriptionType: SubscriptionType.DATA,
                            searchValue: domain.result.result,
                            action: 'remove' as const,
                          },
                        ].filter((sub) =>
                          relatedSubscriptions?.find(
                            (s) =>
                              s.subscriptionType === sub.subscriptionType &&
                              s.searchValue === sub.searchValue
                          )
                        ),
                      })
                    }
                    handleSettings={() => setDomainSettingsOpen(domain.id)}
                    loading={loading}
                  />
                )
              })}
            </>
          )}
          {domainsInUse.length > 0 && (
            <>
              <TableRow>
                <TableCell colSpan={100} sx={{ backgroundColor: '#efefef' }}>
                  <StatusChip status="approved" customLabel="In use" />
                </TableCell>
              </TableRow>
              {domainsInUse.map((domain) => {
                return (
                  <DomainTableRow
                    {...domain}
                    key={domain.id}
                    handleAccept={() =>
                      handleUpdateDataFeedItemStatus({
                        id: domain.id,
                        status: 'approved',
                        subscriptions: [
                          {
                            subscriptionType: SubscriptionType.INDUSTRY,
                            searchValue: domain.result.result,
                            action: 'add' as const,
                          },
                          {
                            subscriptionType: SubscriptionType.DATA,
                            searchValue: domain.result.result,
                            action: 'add' as const,
                          },
                        ].filter(
                          (sub) =>
                            !relatedSubscriptions?.find(
                              (s) =>
                                s.subscriptionType === sub.subscriptionType &&
                                s.searchValue === sub.searchValue
                            )
                        ),
                      })
                    }
                    handleReject={() =>
                      handleUpdateDataFeedItemStatus({
                        id: domain.id,
                        status: 'declined',
                        subscriptions: [
                          {
                            subscriptionType: SubscriptionType.INDUSTRY,
                            searchValue: domain.result.result,
                            action: 'remove' as const,
                          },
                          {
                            subscriptionType: SubscriptionType.DATA,
                            searchValue: domain.result.result,
                            action: 'remove' as const,
                          },
                        ].filter((sub) =>
                          relatedSubscriptions?.find(
                            (s) =>
                              s.subscriptionType === sub.subscriptionType &&
                              s.searchValue === sub.searchValue
                          )
                        ),
                      })
                    }
                    handleSettings={() => setDomainSettingsOpen(domain.id)}
                    loading={loading}
                  />
                )
              })}
            </>
          )}
          {rejectedDomains.length > 0 && (
            <>
              <TableRow>
                <TableCell colSpan={100} sx={{ backgroundColor: '#efefef' }}>
                  <StatusChip status="declined" customLabel="Rejected" />
                </TableCell>
              </TableRow>
              {rejectedDomains.map((domain) => {
                return (
                  <DomainTableRow
                    {...domain}
                    key={domain.id}
                    handleAccept={() =>
                      handleUpdateDataFeedItemStatus({
                        id: domain.id,
                        status: 'approved',
                        subscriptions: [
                          {
                            subscriptionType: SubscriptionType.INDUSTRY,
                            searchValue: domain.result.result,
                            action: 'add' as const,
                          },
                          {
                            subscriptionType: SubscriptionType.DATA,
                            searchValue: domain.result.result,
                            action: 'add' as const,
                          },
                        ].filter(
                          (sub) =>
                            !relatedSubscriptions?.find(
                              (s) =>
                                s.subscriptionType === sub.subscriptionType &&
                                s.searchValue === sub.searchValue
                            )
                        ),
                      })
                    }
                    handleReject={() =>
                      handleUpdateDataFeedItemStatus({
                        id: domain.id,
                        status: 'declined',
                        subscriptions: [
                          {
                            subscriptionType: SubscriptionType.INDUSTRY,
                            searchValue: domain.result.result,
                            action: 'remove' as const,
                          },
                          {
                            subscriptionType: SubscriptionType.DATA,
                            searchValue: domain.result.result,
                            action: 'remove' as const,
                          },
                        ].filter((sub) =>
                          relatedSubscriptions?.find(
                            (s) =>
                              s.subscriptionType === sub.subscriptionType &&
                              s.searchValue === sub.searchValue
                          )
                        ),
                      })
                    }
                    handleSettings={() => setDomainSettingsOpen(domain.id)}
                    loading={loading}
                  />
                )
              })}
            </>
          )}
        </Table>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button variant="text" onClick={() => sharedDialogTitleProps.handleClose()}>
            Close
          </Button>
        </Box>
      </Box>
      {domainSettingsItem && (
        <DomainSettings
          item={domainSettingsItem}
          handleClose={() => setDomainSettingsOpen(null)}
          itemSubscriptions={relatedSubscriptions?.filter(
            (subscription) => subscription.searchValue === domainSettingsItem.result.result
          )}
          loading={loading}
          handleSaveChanges={({ dataDiscovery, industryDiscovery }) => {
            handleUpdateDataFeedItemStatus({
              id: domainSettingsItem.id,
              status: dataDiscovery || industryDiscovery ? 'approved' : 'declined',
              subscriptions: [
                {
                  subscriptionType: SubscriptionType.DATA,
                  searchValue: domainSettingsItem.result.result,
                  action: dataDiscovery ? ('add' as const) : ('remove' as const),
                },
                {
                  subscriptionType: SubscriptionType.INDUSTRY,
                  searchValue: domainSettingsItem.result.result,
                  action: industryDiscovery ? ('add' as const) : ('remove' as const),
                },
              ].filter((sub) => {
                const existingSub = relatedSubscriptions?.find(
                  (s) =>
                    s.subscriptionType === sub.subscriptionType && s.searchValue === sub.searchValue
                )

                if (existingSub && sub.action === 'add') return false
                if (!existingSub && sub.action === 'remove') return false

                return true
              }),
            })
          }}
        />
      )}
      {addDomainDialogOpen && (
        <AddDomainDialog
          handleClose={() => setAddDomainDialogOpen(false)}
          supplierId={supplier.id}
          orgId={supplier.organizationId}
          otherDomains={items.map((domain) => domain.result.result)}
        />
      )}
      <Button
        startIcon={<AddCircleOutlineRounded />}
        onClick={() => setAddDomainDialogOpen(true)}
        variant="text"
        size="small"
        sx={{ ml: 2, mb: 2, mt: -10 }}
      >
        Add website
      </Button>
    </>
  )
}

export default DomainResults
