import { Chip, Tooltip, Typography } from '@mui/material'
import { TravelExploreOutlined } from '@mui/icons-material'
import { DataFeedItem } from '../../helpers'

interface Props {
  origin: DataFeedItem['subscription']
  tooltip?: string
}

const OriginLabel = ({ origin, tooltip }: Props) => {
  const label =
    (origin?.subscriptionType === 'supplier_alias' && 'Responsibly Co-pilot') ||
    (origin?.subscriptionType === 'global_db' && 'Responsibly Supplier Database') ||
    (origin?.subscriptionType === 'data' && origin.searchValue) ||
    (origin?.subscriptionType === 'industry' && 'Responsibly Co-pilot') ||
    (origin?.subscriptionType === 'website' && (
      <i style={{ fontWeight: 500, whiteSpace: 'nowrap' }}>
        &quot;{origin.searchValue.split('<>')[0]}&quot;
      </i>
    )) ||
    origin?.searchValue ||
    'Manually provided'
  return (
    <Tooltip title={tooltip || null}>
      {label === 'Manually provided' ? (
        <Typography variant="body2" noWrap>
          <i>Manually provided</i>
        </Typography>
      ) : (
        <Chip
          sx={{ maxWidth: 175, height: 18, marginTop: '-2px' }}
          icon={
            (origin?.subscriptionType === 'website' && (
              <TravelExploreOutlined sx={{ color: 'common.black' }} />
            )) ||
            undefined
          }
          label={label}
          size="small"
          // sx={{
          //   mr: 0.5,
          //   mb: 0.25,
          //   color: 'common.white',
          //   backgroundColor: (origin?.subscriptionType === 'website' && '#5140BF') || undefined,
          // }}
          // sx={[
          //   {
          //     whiteSpace: 'nowrap',
          //     borderRadius: '4px',
          //     fontWeight: 600,
          //     height: 18,
          //     marginTop: '-2px',
          //     '& .MuiChip-label': {
          //       paddingLeft: '6px',
          //       paddingRight: '6px',
          //     },
          //   },
          //   (theme) =>
          //     (origin?.subscriptionType === 'supplier_alias' && {
          //       color: theme.palette.success.main,
          //       backgroundColor: alpha(theme.palette.success.main, 0.1),
          //       '&:focus, &:hover': {
          //         color: theme.palette.success.main,
          //         backgroundColor: alpha(theme.palette.success.main, 0.1),
          //       },
          //     }) ||
          //     (origin?.subscriptionType === 'global_db' && {
          //       color: theme.palette.success.main,
          //       backgroundColor: alpha(theme.palette.success.main, 0.07),
          //       '&:focus, &:hover': {
          //         color: theme.palette.success.main,
          //         backgroundColor: alpha(theme.palette.success.main, 0.07),
          //       },
          //     }) ||
          //     (origin?.subscriptionType === 'data' && {
          //       color: theme.palette.warning.main,
          //       backgroundColor: alpha(theme.palette.warning.main, 0.1),
          //       '&:focus, &:hover': {
          //         color: theme.palette.warning.main,
          //         backgroundColor: alpha(theme.palette.warning.main, 0.1),
          //       },
          //     }) ||
          //     (origin?.subscriptionType === 'industry' && {
          //       color: theme.palette.primary.main,
          //       backgroundColor: alpha(theme.palette.primary.main, 0.1),
          //       '&:focus, &:hover': {
          //         color: theme.palette.primary.main,
          //         backgroundColor: alpha(theme.palette.primary.main, 0.1),
          //       },
          //     }) ||
          //     (origin?.subscriptionType === 'website' && {
          //       color: theme.palette.primary.main,
          //       backgroundColor: alpha(theme.palette.primary.main, 0.1),
          //       '&:focus, &:hover': {
          //         color: theme.palette.primary.main,
          //         backgroundColor: alpha(theme.palette.primary.main, 0.1),
          //       },
          //     }) || {
          //       color: theme.palette.primary.main,
          //       backgroundColor: alpha(theme.palette.primary.main, 0.12),
          //       '&:focus, &:hover': {
          //         color: theme.palette.primary.main,
          //         backgroundColor: alpha(theme.palette.primary.main, 0.12),
          //       },
          //     },
          // ]}
        />
      )}
    </Tooltip>
  )
}

export default OriginLabel
