import { DialogTitle, ListItem, TableHead } from 'components'
import { useDocumentTitle } from 'utils'
import {
  Box,
  Table,
  TableRow,
  TableCell,
  Typography,
  Link,
  Button,
  Skeleton,
  Badge,
  Grid,
  ButtonGroup,
} from '@mui/material'
import { OrganizationSupplierV2ApiDto } from 'typescript-axios'
import { useState, useEffect } from 'react'
import {
  CheckRounded,
  RemoveRounded,
  PlagiarismOutlined,
  TravelExploreOutlined,
  SendOutlined,
  UploadFileOutlined,
  InfoOutlined,
} from '@mui/icons-material'
import { DataFeedItemDomainV2, DataFeedItemDataEntryV2 } from 'services/mingle/__generated__/Api'
import { DataSubscription, SubscriptionType } from 'hooks/dataFeed/api'
import { useGetDataSource, useGetDataEntries } from 'hooks'
import config from 'constants/config'
import { SharedDialogTitleProps } from '../index'
import StatusChip from '../../StatusChip'
import ActionCell from '../../ActionCell'
import OriginLabel from '../../OriginLabel'

const DomainTableRow = ({
  result,
  subscription,
  status,
  handleAccept,
  handleReject,
  handleSettings,
  loading,
}: DataFeedItemDomainV2 & {
  handleAccept: () => void
  handleReject: () => void
  handleSettings: () => void
  loading: 'approved' | 'declined' | null
}) => {
  return (
    <TableRow>
      <TableCell>
        <Link
          href={result.result.includes('http') ? result.result : `https://${result.result}`}
          target="_blank"
          variant="body2"
        >
          {result.result}
        </Link>
      </TableCell>
      <TableCell>
        <OriginLabel
          origin={subscription}
          tooltip={`We performed a search with your supplier's name to find the supplier's website.`}
        />
      </TableCell>
      <TableCell>
        <StatusChip status={status} />
      </TableCell>
      <TableCell>
        <ActionCell
          loading={loading}
          status={status}
          handleAccept={handleAccept}
          handleReject={handleReject}
          handleSettings={handleSettings}
        />
      </TableCell>
    </TableRow>
  )
}

interface Props {
  items: DataFeedItemDataEntryV2[]
  dataSourceId: string
  supplier: OrganizationSupplierV2ApiDto
  sharedDialogTitleProps: SharedDialogTitleProps
  relatedSubscriptions?: DataSubscription[]
  loading: 'approved' | 'declined' | null
  updateDataFeedItemStatus: ({
    id,
    status,
    subscriptions,
  }: {
    id: string
    status: 'approved' | 'declined'
    subscriptions: {
      subscriptionType: SubscriptionType
      searchValue: string
      action: 'add' | 'remove'
    }[]
  }) => void
}

const DataEntryResults = ({
  items,
  dataSourceId,
  supplier,
  sharedDialogTitleProps,
  relatedSubscriptions,
  loading,
  updateDataFeedItemStatus,
}: Props) => {
  useDocumentTitle('Available domains', true)

  const { data: dataSource } = useGetDataSource(dataSourceId)
  const {
    data: dataEntries,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useGetDataEntries({
    supplierId: supplier.id,
    pageSize: 200,
    dataSourceId,
  })

  // Where did we check for data
  // 3rd party databases
  // The supplier's website -> rekakabel.com
  // Data requests
  // Uploads

  const newImageUrl = dataSource?.imgUrl
    ? `${config.largeMediaUrl}${dataSource.imgUrl}?nf_resize=fit&w=24&h=24`
    : undefined

  const flattenedDataEntries = dataEntries?.pages.map((x) => x.data || []).flat()

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage()
    }
  }, [hasNextPage, isFetchingNextPage])

  const [domainSettingsOpen, setDomainSettingsOpen] = useState<string | null>(null)

  const domainsInReview = items.filter((domain) => domain.status === 'in_review')
  const allDomains = items.filter((domain) => domain.status !== 'in_review')

  const domainSettingsItem = items.find(
    (domain) => domainSettingsOpen && domain.id === domainSettingsOpen
  )

  const handleUpdateDataFeedItemStatus = ({
    id,
    status,
    subscriptions,
  }: {
    id: string
    status: 'approved' | 'declined'
    subscriptions: {
      subscriptionType: SubscriptionType
      searchValue: string
      action: 'add' | 'remove'
    }[]
  }) => {
    updateDataFeedItemStatus({
      id,
      status,
      subscriptions,
    })
    if (domainSettingsOpen) setDomainSettingsOpen(null)
  }

  return (
    <>
      <DialogTitle
        {...sharedDialogTitleProps}
        secondIcon={
          dataSource ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {newImageUrl && (
                <Box
                  component="img"
                  sx={{ width: 24, height: 24, mr: 1 }}
                  src={newImageUrl}
                  alt={dataSource?.name}
                />
              )}
            </Box>
          ) : (
            <Skeleton variant="circular" sx={{ width: 24, height: 24 }} />
          )
        }
        secondTitle={
          dataSource ? (
            <Typography variant="h5">
              <strong>{dataSource.name}</strong>
            </Typography>
          ) : (
            <Skeleton variant="text" width="75%" />
          )
        }
      />
      <Box sx={{ p: 2 }}>
        <Typography variant="body1" color="textSecondary" sx={{ mt: -1 }}>
          Showing all instances of {dataSource?.name} discovered for {supplier.name}.
        </Typography>
        <Typography variant="body1" sx={{ mt: 3, mb: 1 }}>
          <strong>Filter by origin</strong>
        </Typography>
        <Grid container spacing={1} sx={{ mb: 2 }}>
          <Grid item xs={3}>
            <ListItem
              alignItems="flex-start"
              primaryText={
                <Typography variant="body2">
                  <strong>Supplier&apos;s website</strong>
                </Typography>
              }
              onClick={() => {}}
              listItemSize="smallListItem"
              secondaryText="Last scanned 10 days ago"
              size="smallest"
              icon={
                <Badge badgeContent={1} color="primary">
                  <PlagiarismOutlined />
                </Badge>
              }
              selected={false}
              marginBottom={2}
              sx={{ pt: 0, pb: 0 }}
            />
          </Grid>
          <Grid item xs={3}>
            <ListItem
              disabled
              primaryText={
                <Typography variant="body2">
                  <strong>3rd party databases</strong>
                </Typography>
              }
              onClick={() => {}}
              listItemSize="smallListItem"
              secondaryText="Last scanned 2 days ago"
              size="smallest"
              icon={<TravelExploreOutlined />}
              selected={false}
              marginBottom={2}
              sx={{ pt: 0, pb: 0 }}
            />
          </Grid>
          <Grid item xs={3}>
            <ListItem
              disabled
              primaryText={
                <Typography variant="body2">
                  <strong>Data requests</strong>
                </Typography>
              }
              onClick={() => {}}
              listItemSize="smallListItem"
              secondaryText="No data points"
              size="smallest"
              icon={<SendOutlined />}
              selected={false}
              marginBottom={2}
              sx={{ pt: 0, pb: 0 }}
              actionComponent={<InfoOutlined />}
            />
          </Grid>
          <Grid item xs={3}>
            <ListItem
              disabled
              primaryText={
                <Typography variant="body2">
                  <strong>Uploads</strong>
                </Typography>
              }
              onClick={() => {}}
              listItemSize="smallListItem"
              secondaryText="No files uploaded"
              size="smallest"
              icon={<UploadFileOutlined />}
              selected={false}
              marginBottom={2}
              sx={{ pt: 0, pb: 0 }}
            />
          </Grid>
        </Grid>
        <Table>
          <TableHead
            headCells={[
              {
                id: 'dateCreated',
                label: 'Date created',
                disablePadding: false,
                numeric: false,
                sortable: false,
              },
              {
                id: 'origin',
                label: 'Origin',
                disablePadding: false,
                numeric: false,
                sortable: false,
              },
              {
                id: 'status',
                label: 'Status',
                disablePadding: false,
                numeric: false,
                sortable: false,
              },
              {
                id: 'action',
                label: 'Action',
                disablePadding: false,
                numeric: false,
                sortable: false,
              },
            ]}
          />
          {domainsInReview.length > 0 && (
            <>
              <TableRow>
                <TableCell colSpan={100} sx={{ backgroundColor: '#efefef' }}>
                  <Typography variant="body2" sx={{ color: 'common.black' }}>
                    <strong>Domains in review</strong>
                  </Typography>
                </TableCell>
              </TableRow>
              {domainsInReview.map((domain) => {
                return (
                  <h1 key={domain.id}>{domain.result.dataSourceIds.join(',')}</h1>
                  // <DomainTableRow
                  //   {...domain}
                  //   key={domain.id}
                  //   handleAccept={() =>
                  //     handleUpdateDataFeedItemStatus({
                  //       id: domain.id,
                  //       status: 'approved',
                  //       subscriptions: [
                  //         {
                  //           subscriptionType: SubscriptionType.INDUSTRY,
                  //           searchValue: domain.result.website,
                  //           action: 'add' as const,
                  //         },
                  //         {
                  //           subscriptionType: SubscriptionType.DATA,
                  //           searchValue: domain.result.website,
                  //           action: 'add' as const,
                  //         },
                  //       ].filter(
                  //         (sub) =>
                  //           !relatedSubscriptions?.find(
                  //             (s) =>
                  //               s.subscriptionType === sub.subscriptionType &&
                  //               s.searchValue === sub.searchValue
                  //           )
                  //       ),
                  //     })
                  //   }
                  //   handleReject={() =>
                  //     handleUpdateDataFeedItemStatus({
                  //       id: domain.id,
                  //       status: 'declined',
                  //       subscriptions: [
                  //         {
                  //           subscriptionType: SubscriptionType.INDUSTRY,
                  //           searchValue: domain.result.website,
                  //           action: 'remove' as const,
                  //         },
                  //         {
                  //           subscriptionType: SubscriptionType.DATA,
                  //           searchValue: domain.result.website,
                  //           action: 'remove' as const,
                  //         },
                  //       ].filter((sub) =>
                  //         relatedSubscriptions?.find(
                  //           (s) =>
                  //             s.subscriptionType === sub.subscriptionType &&
                  //             s.searchValue === sub.searchValue
                  //         )
                  //       ),
                  //     })
                  //   }
                  //   handleSettings={() => setDomainSettingsOpen(domain.id)}
                  //   loading={loading}
                  // />
                )
              })}
            </>
          )}
          {allDomains.length > 0 && (
            <>
              <TableRow>
                <TableCell colSpan={100} sx={{ backgroundColor: '#efefef' }}>
                  <Typography variant="body2" sx={{ color: 'common.black' }}>
                    <strong>All domains</strong>
                  </Typography>
                </TableCell>
              </TableRow>
              {allDomains.map((domain) => {
                return (
                  <h1 key={domain.id}>{domain.result.dataSourceIds.join(',')}</h1> // <DomainTableRow
                  //   {...domain}
                  //   key={domain.id}
                  //   handleAccept={() =>
                  //     handleUpdateDataFeedItemStatus({
                  //       id: domain.id,
                  //       status: 'approved',
                  //       subscriptions: [
                  //         {
                  //           subscriptionType: SubscriptionType.INDUSTRY,
                  //           searchValue: domain.result.website,
                  //           action: 'add' as const,
                  //         },
                  //         {
                  //           subscriptionType: SubscriptionType.DATA,
                  //           searchValue: domain.result.website,
                  //           action: 'add' as const,
                  //         },
                  //       ].filter(
                  //         (sub) =>
                  //           !relatedSubscriptions?.find(
                  //             (s) =>
                  //               s.subscriptionType === sub.subscriptionType &&
                  //               s.searchValue === sub.searchValue
                  //           )
                  //       ),
                  //     })
                  //   }
                  //   handleReject={() =>
                  //     handleUpdateDataFeedItemStatus({
                  //       id: domain.id,
                  //       status: 'declined',
                  //       subscriptions: [
                  //         {
                  //           subscriptionType: SubscriptionType.INDUSTRY,
                  //           searchValue: domain.result.website,
                  //           action: 'remove' as const,
                  //         },
                  //         {
                  //           subscriptionType: SubscriptionType.DATA,
                  //           searchValue: domain.result.website,
                  //           action: 'remove' as const,
                  //         },
                  //       ].filter((sub) =>
                  //         relatedSubscriptions?.find(
                  //           (s) =>
                  //             s.subscriptionType === sub.subscriptionType &&
                  //             s.searchValue === sub.searchValue
                  //         )
                  //       ),
                  //     })
                  //   }
                  //   handleSettings={() => setDomainSettingsOpen(domain.id)}
                  //   loading={loading}
                  // />
                )
              })}
            </>
          )}
        </Table>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button variant="text">Close</Button>
        </Box>
      </Box>
    </>
  )
}

export default DataEntryResults
