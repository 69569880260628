import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Skeleton,
  Chip,
  alpha,
  Typography,
} from '@mui/material'
import { BusinessRounded as SuppliersIcon, ErrorRounded as ErrorIcon } from '@mui/icons-material'
import config from 'constants/config'
import { SupplierStatus } from 'typescript-axios'

export const ProspectiveChip = ({ ml }: { ml?: number }) => (
  <Chip
    size="small"
    label="Prospective"
    sx={(theme) => ({
      color: theme.palette.info.main,
      backgroundColor: alpha(theme.palette.info.main, 0.1),
      fontWeight: 600,
      borderRadius: '6px',
      height: 18,
      marginLeft: ml,
      '&:focus, &:hover': {
        color: theme.palette.info.main,
        backgroundColor: alpha(theme.palette.info.main, 0.1),
      },
    })}
  />
)

export const InactiveChip = ({ ml }: { ml?: number }) => (
  <Chip
    size="small"
    label="Inactive"
    sx={(theme) => ({
      color: theme.palette.error.main,
      backgroundColor: alpha(theme.palette.error.main, 0.1),
      fontWeight: 600,
      borderRadius: '6px',
      height: 18,
      marginLeft: ml,
      '&:focus, &:hover': {
        color: theme.palette.error.main,
        backgroundColor: alpha(theme.palette.error.main, 0.1),
      },
    })}
  />
)

interface Props {
  primaryText?: React.ReactNode
  secondaryText?: string
  loading?: boolean
  error?: boolean
  noWrap?: boolean
  countryIso2Code?: string
  secondaryError?: boolean
  status?: SupplierStatus
  variant?: 'body2' | 'body1'
}

const SupplierCell = ({
  primaryText,
  secondaryText,
  loading,
  error,
  countryIso2Code,
  noWrap = true,
  secondaryError,
  status,
  variant = 'body1',
}: Props) => {
  const primary =
    (loading && <Skeleton width="80%" />) || (error && 'Missing supplier') || primaryText

  const secondary = loading ? (
    <Skeleton width="50%" height={14} />
  ) : (
    <>
      {secondaryText} {status === 'Prospective' && <ProspectiveChip />}
      {status === 'Inactive' && <InactiveChip />}
    </>
  )

  const countryFlag =
    countryIso2Code && `${config.largeMediaUrl}/images/flags/${countryIso2Code}.svg`

  return (
    <ListItem sx={{ padding: 0 }}>
      <ListItemAvatar sx={{ minWidth: 40 }}>
        <Avatar
          sx={{
            color: 'text.primary',
            width: 24,
            height: 24,
            backgroundColor: loading ? 'transparent' : (secondaryError && 'transparent') || '',
          }}
          variant="circular"
          src={secondaryError ? undefined : countryFlag}
        >
          {loading ? (
            <Skeleton variant="circular" width={24} height={24} />
          ) : (
            (secondaryError && <ErrorIcon sx={{ color: '#eec748' }} />) || <SuppliersIcon />
          )}
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography variant={variant}>
            <strong>{primary}</strong>
          </Typography>
        }
        secondary={secondaryText ? secondary : <Skeleton width="25px" height={14} />}
        primaryTypographyProps={{
          noWrap,
        }}
        secondaryTypographyProps={{
          noWrap: true,
        }}
        sx={{
          marginTop: -2,
          margin: 0,
        }}
      />
    </ListItem>
  )
}

export default SupplierCell
