import { useState, useEffect } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { Box, Skeleton, Breakpoint, Button } from '@mui/material'
import { useQueryClient } from 'react-query'
import { Dialog, Alert } from 'components'
import { useGetDataSubscriptions, useGetDataFeedItemsV2 } from 'hooks/dataFeed/queries'
import { useSnackbar } from 'notistack'
import { useGetSettings, useGetSupplier, useGetCountry } from 'hooks'
import config from 'constants/config'
import {
  DataFeedItemDomainV2,
  DataFeedItemSupplierAliasV2,
  DataFeedItemDataEntryV2,
} from 'services/mingle/__generated__/Api'
import { SubscriptionType } from 'hooks/dataFeed/api'
import queryKeys from 'hooks/dataFeed/queryKeys'
import {
  useUpdateDataFeedItemStatus,
  useCreateDataSubscription,
  useDeleteDataSubscription,
} from 'hooks/dataFeed/mutations'
import { DataFeedItem, DataFeedItemType, typeToPathMapping } from '../../helpers'
import AliasResults from './AliasResults'
import DomainResults from './DomainResults'
import DataEntryResults from './DataEntryResults'

export interface SharedDialogTitleProps {
  title: string | undefined
  titleLink: () => void
  handleClose: () => void
  icon: JSX.Element
}

const dialogSizeMap = {
  data_entry: 'md',
  claim: 'md',
  domain: 'md',
  supplier_alias: 'md',
  industry: 'sm',
}

interface MatchProps {
  supplierId: string
  type: string
}

interface Props extends Partial<RouteComponentProps<MatchProps>> {
  handleClose: () => void
  supplierIdProp?: string
  typeProp?: string
}

const ItemDialog = ({ handleClose, match, supplierIdProp, typeProp }: Props) => {
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const { mutateAsync: updateDataFeedItemStatus } = useUpdateDataFeedItemStatus()

  const supplierId = supplierIdProp || match?.params.supplierId || ''
  const type = typeProp || match?.params.type || ''

  const mappedType = Object.entries(typeToPathMapping).find(([_x, y]) => y === type) || [
    'domain',
    'domain',
  ]

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState<'approved' | 'declined' | null>(null)

  const handleCloseWithTimeout = () => {
    setOpen(false)
    setTimeout(() => handleClose(), 300)
  }

  useEffect(() => setOpen(true), [])

  const { data: settings } = useGetSettings()

  const { data } = useGetDataFeedItemsV2(
    {
      supplierId,
      filter: {
        types: [mappedType?.[0] as DataFeedItemType],
      },
      pageSize: 10000,
    },
    {
      enabled: open,
    }
  )

  const { mutateAsync: createDataSubscription } = useCreateDataSubscription()
  const { mutateAsync: deleteDataSubscription } = useDeleteDataSubscription()

  const flattenedDataFeedItems: DataFeedItem[] =
    data?.pages.flatMap((x) => {
      return [...x.data.domains, ...x.data.supplierAliases, ...x.data.dataEntries]
    }) || []

  const dataEntryItem = !mappedType
    ? (flattenedDataFeedItems.find((x) => x.id === type) as DataFeedItemDataEntryV2 | undefined)
    : undefined

  const filteredDataFeedItems: DataFeedItem[] =
    flattenedDataFeedItems.filter((x) => {
      if (dataEntryItem) {
        const { dataSourceIds } = dataEntryItem.result
        const itemDataSourceIds =
          x.result && 'dataSourceIds' in x.result ? x.result.dataSourceIds : undefined
        return (
          itemDataSourceIds &&
          itemDataSourceIds.some((y) => dataSourceIds.includes(y)) &&
          x.subscription?.organizationSupplierId === supplierId
        )
      }

      if (mappedType) {
        return (
          x.type === mappedType[0] &&
          (x.subscription?.organizationSupplierId || x.organizationSupplierId) === supplierId
        )
      }

      return false
    }) || []

  const { data: supplier, error } = useGetSupplier(supplierId)

  const { data: country } = useGetCountry({
    iso3Code: supplier?.data.countryIso3Code || '',
    enabled: Boolean(supplier?.data?.countryIso3Code),
  })

  const { data: supplierDataSubscriptions } = useGetDataSubscriptions(
    `${settings?.data.id}#${supplierId}`
  )

  const sharedDialogTitleProps = {
    title: supplier?.data.name,
    titleLink: () => history.push(`/suppliers/${supplier?.data.id}/scorecards`),
    handleClose: handleCloseWithTimeout,
    icon: (
      <Box
        component="img"
        sx={{ width: 22, height: 22 }}
        src={`${config.largeMediaUrl}/images/flags/${country?.iso2Code}.svg`}
        alt={supplier?.data.name}
      />
    ),
  }

  const handleUpdateDataFeedItemStatus = async ({
    id,
    status,
    subscriptions,
  }: {
    id: string
    status: 'approved' | 'declined'
    subscriptions: {
      subscriptionType: SubscriptionType
      searchValue: string
      action: 'add' | 'remove'
    }[]
  }) => {
    setLoading(status)
    await updateDataFeedItemStatus({
      id,
      status,
    })
    await queryClient.invalidateQueries(queryKeys.allDataFeedItems)

    const subscriptionPromises = subscriptions.map((x) => {
      if (x.action === 'add') {
        return createDataSubscription({
          organizationSupplierId: `${settings?.data.id}#${supplierId}`,
          subscriptionType: x.subscriptionType,
          searchValue: x.searchValue,
        })
      }
      return deleteDataSubscription({
        organizationSupplierId: `${settings?.data.id}#${supplierId}`,
        subscriptionType: x.subscriptionType,
        searchValue: x.searchValue,
      })
    })

    await Promise.all(subscriptionPromises)

    await queryClient.invalidateQueries(['dataSubscriptions', `${settings?.data.id}#${supplierId}`])

    enqueueSnackbar('Status updated!', {
      variant: 'success',
    })

    setLoading(null)
  }

  let content = (
    <Box sx={{ mt: 1, p: 2 }}>
      <Skeleton variant="text" width="75%" />
      <Skeleton variant="text" width="30%" sx={{ mb: 1 }} />
      <Skeleton variant="rectangular" width="100%" height={150} />
      <Box
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', mt: 1.5, mb: 1.5 }}
      >
        <Skeleton variant="text" width={67} sx={{ mr: 1.5 }} />
        <Skeleton variant="rectangular" width={100} height={34} />
      </Box>
    </Box>
  )

  if (error) {
    content = <Alert severity="error" message={error.message} />
  }

  const resolved = Boolean(supplier?.data && country && data)

  // Show the suppliers name, the alias in question and any "name" subscriptions. Make it possible to remove any of them.
  // Should be subscriptions-based
  if (mappedType && mappedType[0] === 'supplier_alias' && resolved && supplier?.data) {
    const aliasRelatedSubscriptions = supplierDataSubscriptions?.items.filter((x) => {
      return (
        x.subscriptionType === SubscriptionType.GLOBAL_DB ||
        x.subscriptionType === SubscriptionType.NEWS_AND_MEDIA ||
        x.subscriptionType === SubscriptionType.WEBSITE
      )
    })
    content = (
      <AliasResults
        items={filteredDataFeedItems as DataFeedItemSupplierAliasV2[]}
        relatedSubscriptions={aliasRelatedSubscriptions}
        supplier={supplier.data}
        loading={loading}
        sharedDialogTitleProps={sharedDialogTitleProps}
        updateDataFeedItemStatus={handleUpdateDataFeedItemStatus}
      />
    )
  }

  if (mappedType && mappedType[0] === 'domain' && supplier?.data && country && resolved) {
    const domainRelatedSubscriptions = supplierDataSubscriptions?.items.filter((x) => {
      return (
        x.subscriptionType === SubscriptionType.INDUSTRY ||
        x.subscriptionType === SubscriptionType.DATA
      )
    })
    content = (
      <DomainResults
        items={filteredDataFeedItems as DataFeedItemDomainV2[]}
        relatedSubscriptions={domainRelatedSubscriptions}
        supplier={supplier.data}
        sharedDialogTitleProps={sharedDialogTitleProps}
        loading={loading}
        updateDataFeedItemStatus={handleUpdateDataFeedItemStatus}
      />
    )
  }

  // Data entry
  if (dataEntryItem && resolved && supplier?.data) {
    content =
      dataEntryItem.result.dataSourceIds.length > 1 ? (
        <h1>select data source id</h1>
      ) : (
        <DataEntryResults
          items={filteredDataFeedItems as DataFeedItemDataEntryV2[]}
          dataSourceId={dataEntryItem.result.dataSourceIds[0]}
          relatedSubscriptions={[]}
          supplier={supplier.data}
          sharedDialogTitleProps={sharedDialogTitleProps}
          loading={loading}
          updateDataFeedItemStatus={handleUpdateDataFeedItemStatus}
        />
      )
  }

  return (
    <Dialog
      open={open}
      onClose={handleCloseWithTimeout}
      withForm={false}
      withClose
      scroll="paper"
      contentPadding={false}
      maxWidth={
        mappedType && mappedType[0] in dialogSizeMap
          ? (dialogSizeMap[mappedType[0] as keyof typeof dialogSizeMap] as Breakpoint)
          : 'md'
      }
    >
      {content}
    </Dialog>
  )
}

export default ItemDialog
