import { SubscriptionType } from 'hooks'
import { DataFeedItemsResponseV2 } from 'services/mingle/__generated__/Api'

export type DataFeedItem = DataFeedItemsResponseV2[keyof DataFeedItemsResponseV2][0]
export type DataFeedItemType = NonNullable<DataFeedItem['type']>
export type DataFeedItemSubscriptionType = NonNullable<
  DataFeedItem['subscription']
>['subscriptionType']

// export enum DataFeedItemType {
//   ADVERSE_MEDIA = 'adverse_media' as Omit<DataFeedItem['type'], null>,
//   CLAIM = 'claim',
//   DATA_ENTRY = 'data_entry',
//   DOMAIN = 'domain',
//   INDUSTRY = 'industry',
//   KPI = 'kpi',
//   STATEMENT = 'statement',
//   SUPPLIER_ALIAS = 'supplier_alias',
// }

export const typeToPathMapping: Record<DataFeedItemType, string> = {
  adverse_media: 'adverse-media',
  claim: 'claims',
  data_entry: 'data-entries',
  domain: 'domains',
  industry: 'industries',
  kpi: 'kpis',
  statement: 'statements',
  supplier_alias: 'aliasses',
  data_request_answer: 'data-request-answers',
}

export const statusMap: { [key in NonNullable<DataFeedItem['status']>]: string } = {
  approved: 'Accepted',
  auto_approved: 'Auto accepted',
  auto_declined: 'Auto rejected',
  ai_approved: 'AI accepted',
  ai_declined: 'AI rejected',
  in_review: 'Needs review',
  rejected: 'Discarded',
  declined: 'Rejected',
}

export const getSubscriptionsForDataFeedItem = ({
  item,
  action,
}: {
  item: DataFeedItem
  action: 'add' | 'remove'
}) => {
  if (item.type === 'domain' && item.result && 'result' in item.result) {
    return [
      {
        subscriptionType: SubscriptionType.INDUSTRY,
        searchValue: item.result.result,
        action,
      },
      {
        subscriptionType: SubscriptionType.DATA,
        searchValue: item.result.result,
        action,
      },
    ]
  }
  return []
}

export const isAccepted = (status: DataFeedItem['status']) => {
  if (status === 'in_review') return undefined
  return status === 'approved' || status === 'auto_approved' || status === 'ai_approved'
}
