import { DialogTitle, TableHead } from 'components'
import { useDocumentTitle } from 'utils'
import { Box, Table, TableRow, TableCell, Typography, Link, Button } from '@mui/material'
import { OrganizationSupplierV2ApiDto } from 'typescript-axios'
import { useState } from 'react'
import { DataFeedItemSupplierAliasV2 } from 'services/mingle/__generated__/Api'
import { DataSubscription, SubscriptionType } from 'hooks/dataFeed/api'
import DiscoveryTypeListItem from '../../DiscoveryTypeListItem'
import { SharedDialogTitleProps } from '../index'
import StatusChip from '../../StatusChip'
import ActionCell from '../../ActionCell'
import OriginLabel from '../../OriginLabel'
import AliasSettings from './AliasSettingsDialog'

const AliasTableRow = ({
  result,
  subscription,
  status,
  handleAccept,
  handleReject,
  handleSettings,
  loading,
}: DataFeedItemSupplierAliasV2 & {
  handleAccept: () => void
  handleReject: () => void
  handleSettings: () => void
  loading: 'approved' | 'declined' | null
}) => {
  return (
    <TableRow>
      <TableCell>{result.alias}</TableCell>
      <TableCell>
        <OriginLabel
          origin={subscription}
          tooltip={`Our AI analyzes your supplier's name to find the best alias to monitor for sustainability data.`}
        />
      </TableCell>
      <TableCell>
        <StatusChip status={status} />
      </TableCell>
      <TableCell>
        <ActionCell
          loading={loading}
          status={status}
          handleAccept={handleAccept}
          handleReject={handleReject}
          handleSettings={handleSettings}
        />
      </TableCell>
    </TableRow>
  )
}

interface Props {
  items: DataFeedItemSupplierAliasV2[]
  supplier: OrganizationSupplierV2ApiDto
  sharedDialogTitleProps: SharedDialogTitleProps
  relatedSubscriptions?: DataSubscription[]
  loading: 'approved' | 'declined' | null
  updateDataFeedItemStatus: ({
    id,
    status,
    subscriptions,
  }: {
    id: string
    status: 'approved' | 'declined'
    subscriptions: {
      subscriptionType: SubscriptionType
      searchValue: string
      action: 'add' | 'remove'
    }[]
  }) => void
}

const AliasResults = ({
  items,
  supplier,
  sharedDialogTitleProps,
  relatedSubscriptions,
  loading,
  updateDataFeedItemStatus,
}: Props) => {
  useDocumentTitle('Available aliasses', true)

  const [aliasSettingsOpen, setAliasSettingsOpen] = useState<string | null>(null)

  const aliassesInReview = items.filter((alias) => alias.status === 'in_review')
  const allAliasses = items.filter((alias) => alias.status !== 'in_review')

  const aliasSettingsItem = items.find(
    (alias) => aliasSettingsOpen && alias.id === aliasSettingsOpen
  )

  const handleUpdateDataFeedItemStatus = ({
    id,
    status,
    subscriptions,
  }: {
    id: string
    status: 'approved' | 'declined'
    subscriptions: {
      subscriptionType: SubscriptionType
      searchValue: string
      action: 'add' | 'remove'
    }[]
  }) => {
    updateDataFeedItemStatus({
      id,
      status,
      subscriptions,
    })
    if (aliasSettingsOpen) setAliasSettingsOpen(null)
  }

  return (
    <>
      <DialogTitle
        {...sharedDialogTitleProps}
        secondIcon={<DiscoveryTypeListItem type="supplier_alias" withText={false} />}
        secondTitle="Available aliasses"
        description={`We use supplier aliasses to monitor 3rd party databases like ISO for sustainability data related to your supplier. Select what aliasses to monitor for ${supplier.name}.`}
      />
      <Box sx={{ p: 2 }}>
        <Table>
          <TableHead
            headCells={[
              {
                id: 'alias',
                label: 'Alias',
                disablePadding: false,
                numeric: false,
                sortable: false,
              },
              {
                id: 'origin',
                label: 'Origin',
                disablePadding: false,
                numeric: false,
                sortable: false,
              },
              {
                id: 'status',
                label: 'Status',
                disablePadding: false,
                numeric: false,
                sortable: false,
              },
              {
                id: 'action',
                label: 'Action',
                disablePadding: false,
                numeric: false,
                sortable: false,
              },
            ]}
          />
          {aliassesInReview.length > 0 && (
            <>
              <TableRow>
                <TableCell colSpan={100} sx={{ backgroundColor: '#efefef' }}>
                  <Typography variant="body2" sx={{ color: 'common.black' }}>
                    <strong>Aliasses in review</strong>
                  </Typography>
                </TableCell>
              </TableRow>
              {aliassesInReview.map((alias) => {
                return (
                  <AliasTableRow
                    {...alias}
                    key={alias.id}
                    handleAccept={() =>
                      handleUpdateDataFeedItemStatus({
                        id: alias.id,
                        status: 'approved',
                        subscriptions: [
                          {
                            subscriptionType: SubscriptionType.NEWS_AND_MEDIA,
                            searchValue: alias.result.alias,
                            action: 'add' as const,
                          },
                          {
                            subscriptionType: SubscriptionType.WEBSITE,
                            searchValue: alias.result.alias,
                            action: 'add' as const,
                          },
                        ].filter(
                          (sub) =>
                            !relatedSubscriptions?.find(
                              (s) =>
                                s.subscriptionType === sub.subscriptionType &&
                                s.searchValue === sub.searchValue
                            )
                        ),
                      })
                    }
                    handleReject={() =>
                      handleUpdateDataFeedItemStatus({
                        id: alias.id,
                        status: 'declined',
                        subscriptions: [
                          {
                            subscriptionType: SubscriptionType.NEWS_AND_MEDIA,
                            searchValue: alias.result.alias,
                            action: 'remove' as const,
                          },
                          {
                            subscriptionType: SubscriptionType.WEBSITE,
                            searchValue: alias.result.alias,
                            action: 'remove' as const,
                          },
                        ].filter((sub) =>
                          relatedSubscriptions?.find(
                            (s) =>
                              s.subscriptionType === sub.subscriptionType &&
                              s.searchValue === sub.searchValue
                          )
                        ),
                      })
                    }
                    handleSettings={() => setAliasSettingsOpen(alias.id)}
                    loading={loading}
                  />
                )
              })}
            </>
          )}
          {allAliasses.length > 0 && (
            <>
              <TableRow>
                <TableCell colSpan={100} sx={{ backgroundColor: '#efefef' }}>
                  <Typography variant="body2" sx={{ color: 'common.black' }}>
                    <strong>All aliasses</strong>
                  </Typography>
                </TableCell>
              </TableRow>
              {allAliasses.map((alias) => {
                return (
                  <AliasTableRow
                    {...alias}
                    key={alias.id}
                    handleAccept={() =>
                      handleUpdateDataFeedItemStatus({
                        id: alias.id,
                        status: 'approved',
                        subscriptions: [
                          {
                            subscriptionType: SubscriptionType.NEWS_AND_MEDIA,
                            searchValue: alias.result.alias,
                            action: 'add' as const,
                          },
                          {
                            subscriptionType: SubscriptionType.WEBSITE,
                            searchValue: alias.result.alias,
                            action: 'add' as const,
                          },
                        ].filter(
                          (sub) =>
                            !relatedSubscriptions?.find(
                              (s) =>
                                s.subscriptionType === sub.subscriptionType &&
                                s.searchValue === sub.searchValue
                            )
                        ),
                      })
                    }
                    handleReject={() =>
                      handleUpdateDataFeedItemStatus({
                        id: alias.id,
                        status: 'declined',
                        subscriptions: [
                          {
                            subscriptionType: SubscriptionType.NEWS_AND_MEDIA,
                            searchValue: alias.result.alias,
                            action: 'remove' as const,
                          },
                          {
                            subscriptionType: SubscriptionType.WEBSITE,
                            searchValue: alias.result.alias,
                            action: 'remove' as const,
                          },
                        ].filter((sub) =>
                          relatedSubscriptions?.find(
                            (s) =>
                              s.subscriptionType === sub.subscriptionType &&
                              s.searchValue === sub.searchValue
                          )
                        ),
                      })
                    }
                    handleSettings={() => setAliasSettingsOpen(alias.id)}
                    loading={loading}
                  />
                )
              })}
            </>
          )}
        </Table>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button variant="text">Close</Button>
        </Box>
      </Box>
      {aliasSettingsItem && (
        <AliasSettings
          item={aliasSettingsItem}
          handleClose={() => setAliasSettingsOpen(null)}
          itemSubscriptions={relatedSubscriptions?.filter(
            (subscription) => subscription.searchValue === aliasSettingsItem.result.alias
          )}
          loading={loading}
          handleSaveChanges={({ newsAndMedia, website }) => {
            handleUpdateDataFeedItemStatus({
              id: aliasSettingsItem.id,
              status: newsAndMedia || website ? 'approved' : 'declined',
              subscriptions: [
                {
                  subscriptionType: SubscriptionType.NEWS_AND_MEDIA,
                  searchValue: aliasSettingsItem.result.alias,
                  action: newsAndMedia ? ('add' as const) : ('remove' as const),
                },
                {
                  subscriptionType: SubscriptionType.WEBSITE,
                  searchValue: aliasSettingsItem.result.alias,
                  action: website ? ('add' as const) : ('remove' as const),
                },
              ].filter((sub) => {
                const existingSub = relatedSubscriptions?.find(
                  (s) =>
                    s.subscriptionType === sub.subscriptionType && s.searchValue === sub.searchValue
                )

                if (existingSub && sub.action === 'add') return false
                if (!existingSub && sub.action === 'remove') return false

                return true
              }),
            })
          }}
        />
      )}
    </>
  )
}

export default AliasResults
