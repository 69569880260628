import { ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material'
import {
  LanguageOutlined as DomainIcon,
  NewspaperOutlined as AdverseMediaIcon,
  TravelExploreOutlined as SupplierAliasIcon,
  DocumentScannerOutlined as DataEntryIcon,
  CategoryOutlined as IndustryIcon,
} from '@mui/icons-material'
import { DataFeedItem } from '../../helpers'

interface Props {
  type: DataFeedItem['type']
  withText?: boolean
  secondary?: string
}

const DiscoveryTypeListItem = ({ type, withText, secondary }: Props) => {
  return (
    <ListItem sx={{ p: 0, width: 'fit-content' }}>
      <ListItemIcon>
        {type === 'adverse_media' && <AdverseMediaIcon />}
        {type === 'data_entry' && <DataEntryIcon sx={{ color: 'primary.main' }} />}
        {type === 'domain' && <DomainIcon />}
        {type === 'industry' && <IndustryIcon />}
        {type === 'supplier_alias' && <SupplierAliasIcon />}
      </ListItemIcon>
      {withText && (
        <ListItemText
          disableTypography
          primary={
            <Typography variant="body2" noWrap>
              <strong>
                {type === 'adverse_media' && 'News found'}
                {type === 'data_entry' && 'Data found'}
                {type === 'domain' && 'Website found'}
                {type === 'industry' && 'Industry found'}
                {type === 'supplier_alias' && 'Supplier alias found'}
              </strong>
            </Typography>
          }
          secondary={
            secondary ? (
              <Typography variant="body2" color="textSecondary">
                {secondary}
              </Typography>
            ) : undefined
          }
        />
      )}
    </ListItem>
  )
}

export default DiscoveryTypeListItem
