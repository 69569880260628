import { Switch, Route, useHistory } from 'react-router-dom'
import { Table, TableRow, TableCell, TableBody } from '@mui/material'
import { TableHead, LoadingTableRow, Alert } from 'components'
import { HeadCellProps } from 'components/Table/TableHead'
import DataFeedItemTableRow from '../DataFeedItemTableRow'
import { DataFeedItem } from '../../helpers'
import ItemDialog from '../ItemDialog'

const headCells: HeadCellProps[] = [
  {
    id: 'supplier',
    label: 'Supplier',
    numeric: false,
    disablePadding: false,
    sortable: false,
    paddingLeft: 2.5,
  },
  {
    id: 'type',
    label: 'Type',
    numeric: false,
    disablePadding: false,
    sortable: false,
  },
  {
    id: 'origin',
    label: 'Origin',
    numeric: false,
    disablePadding: false,
    sortable: false,
  },
  {
    id: 'result',
    label: 'Result',
    numeric: false,
    disablePadding: false,
    sortable: false,
  },
  // {
  //   id: 'status',
  //   label: 'Status',
  //   numeric: false,
  //   disablePadding: false,
  //   sortable: false,
  // },
  {
    id: 'action',
    label: 'Action',
    numeric: false,
    disablePadding: false,
    sortable: false,
  },
]

interface Props {
  dataFeedItems: DataFeedItem[]
  loading: boolean
  totalCount?: number
  /** use for a single supplier as well */
  basePath: string
}

const DataFeedTable = ({ dataFeedItems, loading, basePath }: Props) => {
  const history = useHistory()

  return (
    <>
      <Table
        aria-labelledby="table"
        aria-label="Data feed items"
        style={{ position: 'relative', marginTop: 16 }}
      >
        <TableHead headCells={headCells} sticky />
        <TableBody>
          {dataFeedItems.map((item) => {
            return (
              <DataFeedItemTableRow
                key={item.id}
                item={item}
                handleOpenItem={(supplierId, type) =>
                  history.push(`${basePath}/${supplierId}/${type}`)
                }
              />
            )
          })}
          {loading && <LoadingTableRow rows={2} />}
          {!loading && dataFeedItems.length === 0 && (
            <TableRow>
              <TableCell colSpan={8}>
                <Alert message="No results" />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <Switch>
        <Route
          path={`${basePath}/:supplierId/:type`}
          render={(props) => (
            <ItemDialog {...props} handleClose={() => history.push('/data-feed')} />
          )}
        />
      </Switch>
    </>
  )
}

export default DataFeedTable
