import { TableRow, TableCell } from '@mui/material'
import SupplierCell from 'views/buyer/Suppliers/AllSuppliers/components/SupplierTableRow/components/SupplierCell'
import getDuration from 'utils/getDuration'
import { useSnackbar } from 'notistack'
import { useGetSupplier, useGetCountry, useGetSettings } from 'hooks'
import {
  useDeleteDataSubscription,
  useCreateDataSubscription,
  useUpdateDataFeedItemStatus,
} from 'hooks/dataFeed/mutations'
import { useQueryClient } from 'react-query'
import dataFeedQueryKeys from 'hooks/dataFeed/queryKeys'
import { useState } from 'react'
import DiscoveryTypeListItem from '../DiscoveryTypeListItem'
import { DataFeedItem, typeToPathMapping, getSubscriptionsForDataFeedItem } from '../../helpers'
import OriginLabel from '../OriginLabel'
import StatusChip from '../StatusChip'
import ResultCell from '../ResultCell'
import ActionCell from '../ActionCell'

interface Props {
  item: DataFeedItem
  handleOpenItem: (supplierId: string, type: string) => void
}

const DataFeedItemTableRow = ({ item, handleOpenItem }: Props) => {
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState<'approved' | 'declined' | null>(null)
  const queryClient = useQueryClient()

  const { mutateAsync: updateDataFeedItemStatus } = useUpdateDataFeedItemStatus()
  const { mutateAsync: createDataSubscription } = useCreateDataSubscription()
  const { mutateAsync: deleteDataSubscription } = useDeleteDataSubscription()

  const {
    data: supplier,
    isFetching,
    error,
  } = useGetSupplier(
    item.subscription?.organizationSupplierId || item.organizationSupplierId || '',
    {
      enabled: !!item.subscription?.organizationSupplierId || !!item.organizationSupplierId,
      retry: 1,
    }
  )
  const { data: country, isFetching: isCountryFetching } = useGetCountry({
    iso3Code: supplier?.data.countryIso3Code || '',
    enabled: !!supplier?.data?.countryIso3Code,
  })
  const { data: settings } = useGetSettings()

  const handleUpdateDataFeedItemStatus = async ({
    status,
  }: {
    status: 'approved' | 'declined'
  }) => {
    setLoading(status)
    await updateDataFeedItemStatus({
      id: item.id,
      status,
    })

    const subscriptions = getSubscriptionsForDataFeedItem({
      item,
      action: status === 'approved' ? 'add' : 'remove',
    })

    const subscriptionPromises = subscriptions.map((x) => {
      if (x.action === 'add') {
        return createDataSubscription({
          organizationSupplierId: `${settings?.data.id}#${item.subscription?.organizationSupplierId}`,
          subscriptionType: x.subscriptionType,
          searchValue: x.searchValue,
        })
      }
      return deleteDataSubscription({
        organizationSupplierId: `${settings?.data.id}#${item.subscription?.organizationSupplierId}`,
        subscriptionType: x.subscriptionType,
        searchValue: x.searchValue,
      })
    })

    await Promise.all(subscriptionPromises)
    await queryClient.invalidateQueries(dataFeedQueryKeys.allDataFeedItems)

    queryClient.invalidateQueries([
      'dataSubscriptions',
      `${settings?.data.id}#${item.subscription?.organizationSupplierId}`,
    ])

    enqueueSnackbar('Status updated!', {
      variant: 'success',
    })

    setLoading(null)
  }

  const createdAtTimeAgoDuration = getDuration(item.createdAt)

  if (error) {
    return (
      <TableRow>
        <TableCell>
          <DiscoveryTypeListItem type={item.type} withText secondary={createdAtTimeAgoDuration} />
        </TableCell>
        <TableCell style={{ paddingLeft: 0, maxWidth: 350 }} colSpan={10}>
          <SupplierCell
            primaryText={supplier?.data.name || 'Supplier not found'}
            secondaryText={country?.commonName}
            countryIso2Code={country?.iso2Code}
            noWrap={false}
            variant="body2"
            loading={isFetching || isCountryFetching}
            error={Boolean(error)}
            secondaryError={Boolean(error)}
          />
        </TableCell>
      </TableRow>
    )
  }

  return (
    <TableRow
      onClick={() =>
        handleOpenItem(
          item.subscription?.organizationSupplierId || item.organizationSupplierId || '',
          // If entry, sort things out in the dialog using the id
          (item.result && 'dataSourceIds' in item.result && item.id) ||
            (item.type && typeToPathMapping[item.type]) ||
            ''
        )
      }
      hover
    >
      <TableCell sx={{ maxWidth: 350, pl: '18px' }}>
        <SupplierCell
          primaryText={supplier?.data.name || 'Supplier not found'}
          secondaryText={country?.commonName}
          countryIso2Code={country?.iso2Code}
          noWrap={false}
          variant="body2"
          loading={isFetching || isCountryFetching}
          error={Boolean(error)}
          secondaryError={Boolean(error)}
        />
      </TableCell>
      <TableCell>
        <DiscoveryTypeListItem type={item.type} withText secondary={createdAtTimeAgoDuration} />
      </TableCell>
      <TableCell>
        <OriginLabel
          origin={item.subscription}
          tooltip={
            item.subscription
              ? `Search value: ${item.subscription?.searchValue.split('<>')[0]}`
              : undefined
          }
        />
      </TableCell>
      <TableCell>
        <ResultCell
          result={item.result}
          type={item.type}
          subscription={item.subscription}
          status={item.status}
        />
      </TableCell>
      {/* <TableCell>
        <StatusChip status={item.status} />
      </TableCell> */}
      <TableCell align="right">
        <ActionCell
          status={item.status}
          handleAccept={() => {
            handleUpdateDataFeedItemStatus({
              status: 'approved',
            })
          }}
          handleReject={() => {
            handleUpdateDataFeedItemStatus({
              status: 'declined',
            })
          }}
          loading={loading}
        />
      </TableCell>
    </TableRow>
  )
}

export default DataFeedItemTableRow
