import { alpha, Chip } from '@mui/material'
// import {
//   ManageSearchOutlined as NeedsReviewIcon,
//   CheckOutlined as ApprovedIcon,
//   CloseOutlined as RejectedIcon,
// } from '@mui/icons-material'
import { DataFeedItem, statusMap } from '../../helpers'

interface Props {
  status: DataFeedItem['status']
  customLabel?: string
}

// const iconMap: { [key in NonNullable<DataFeedItem['status']>]: JSX.Element } = {
//   approved: (
//     <ApprovedIcon
//       sx={{
//         color: (theme) => `${theme.palette.success.main} !important`,
//         mr: (theme) => `${theme.spacing(0.3)} !important`,
//       }}
//     />
//   ),
//   auto_approved: (
//     <ApprovedIcon
//       sx={{
//         color: (theme) => `${theme.palette.success.main} !important`,
//         mr: (theme) => `${theme.spacing(0.3)} !important`,
//       }}
//     />
//   ),
//   in_review: (
//     <NeedsReviewIcon
//       sx={{
//         color: (theme) => `${theme.palette.warning.main} !important`,
//         mr: (theme) => `${theme.spacing(0.3)} !important`,
//       }}
//     />
//   ),
//   rejected: (
//     <RejectedIcon
//       sx={{
//         color: (theme) => `${theme.palette.primary.main} !important`,
//         mr: (theme) => `${theme.spacing(0.3)} !important`,
//       }}
//     />
//   ),
//   declined: (
//     <RejectedIcon
//       sx={{
//         color: (theme) => `${theme.palette.primary.main} !important`,
//         mr: (theme) => `${theme.spacing(0.3)} !important`,
//       }}
//     />
//   ),
// }

const StatusChip = ({ status, customLabel }: Props) => {
  return (
    <Chip
      // icon={status ? iconMap[status] : undefined}
      size="small"
      label={customLabel || (status && statusMap[status]) || 'Unknown'}
      sx={[
        {
          whiteSpace: 'nowrap',
          borderRadius: '4px',
          fontWeight: 600,
          height: 18,
          marginTop: '-2px',
          '& .MuiChip-label': {
            paddingLeft: '6px',
            paddingRight: '6px',
          },
        },
        (theme) =>
          ((status === 'approved' || status === 'auto_approved' || status === 'ai_approved') && {
            // color: theme.palette.success.main,
            backgroundColor: alpha(theme.palette.success.main, 0.1),
            '&:focus, &:hover': {
              // color: theme.palette.success.main,
              backgroundColor: alpha(theme.palette.success.main, 0.1),
            },
          }) ||
          (status === 'auto_approved' && {
            // color: theme.palette.success.main,
            backgroundColor: alpha(theme.palette.success.main, 0.07),
            '&:focus, &:hover': {
              // color: theme.palette.success.main,
              backgroundColor: alpha(theme.palette.success.main, 0.07),
            },
          }) ||
          (status === 'in_review' && {
            background: `linear-gradient(90deg, ${alpha(
              theme.palette.warning.main,
              0.2
            )} 0%, ${alpha(theme.palette.success.main, 0.1)} 100%)`,
            '&:focus, &:hover': {
              background: `linear-gradient(90deg, ${alpha(
                theme.palette.warning.main,
                0.15
              )} 0%, ${alpha(theme.palette.success.main, 0.25)} 100%)`,
            },
          }) ||
          ((status === 'declined' ||
            status === 'rejected' ||
            status === 'auto_declined' ||
            status === 'ai_declined') && {
            // color: theme.palette.primary.main,
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
            '&:focus, &:hover': {
              // color: theme.palette.primary.main,
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
            },
          }) || {
            // color: theme.palette.primary.main,
            backgroundColor: alpha(theme.palette.primary.main, 0.12),
            '&:focus, &:hover': {
              // color: theme.palette.primary.main,
              backgroundColor: alpha(theme.palette.primary.main, 0.12),
            },
          },
      ]}
    />
  )
}

export default StatusChip
