import { PageTitle } from 'components'
import { useMemo, useRef, useState } from 'react'
import { useDocumentTitle, useIntersectionObserver } from 'utils'
import { useGetDataFeedItemsV2, useGetOverviewRiskParameters } from 'hooks'
import {
  createRiskFilters,
  defaultFilters,
  FilterProps,
  transformSupplierFilters,
} from 'components/Inputs/SupplierFilterDrawer/helpers'
import DataFeedTable from './components/DataFeedTable'
import { DataFeedItem } from './helpers'
import Filterbar from '../Suppliers/AllSuppliers/components/Filterbar'
import { formatFiltersForApi } from '../Scorecards/helpers'

const DataFeed = () => {
  useDocumentTitle('Data Feed')

  const [filters, setFilters] = useState<FilterProps>(defaultFilters)

  const loadMoreContainerRef = useRef<HTMLDivElement>(null)

  const transformedSupplierFilters = useMemo(() => {
    return transformSupplierFilters(filters)
  }, [filters])

  const riskFilter = useMemo(() => {
    return createRiskFilters({
      riskSeverity: filters.parameterRiskSeverity?.severity || [],
      parameterIds: filters.parameterRiskSeverity?.parameters || [],
    })
  }, [filters])

  const supplierFilter = useMemo(() => {
    return transformedSupplierFilters.filters && transformedSupplierFilters.filters.length > 0
      ? formatFiltersForApi(transformedSupplierFilters.filters)
      : undefined
  }, [filters])

  const {
    data: riskData,
    isLoading: isRiskLoading,
    isFetching: isRiskFetching,
  } = useGetOverviewRiskParameters(
    {
      supplierFilter: supplierFilter || undefined,
      riskFilter: riskFilter || undefined,
      geographicalRisk: true,
      industryRisk: true,
    },
    {
      enabled: Boolean(riskFilter || supplierFilter),
    }
  )

  const supplierIdsToFetch = useMemo(() => {
    return riskData?.data
      ? riskData.data.reduce((prev, curr) => {
          const newSuppliers = curr.riskScoresBySupplierId
            ?.filter((x) => !prev.find((y) => y === x.supplierId))
            .map((x) => x.supplierId)
          return [...prev, ...(newSuppliers || [])]
        }, [] as string[])
      : []
  }, [riskData])

  const { data, hasNextPage, isFetchingNextPage, fetchNextPage, isFetching } =
    useGetDataFeedItemsV2({
      pageSize: 20,
      supplierIds:
        riskFilter || supplierFilter || supplierIdsToFetch.length > 0
          ? supplierIdsToFetch
          : undefined,
      filter: {
        types: ['domain'],
      },
      order: {
        direction: 'desc',
      },
    })

  const flattenedDataFeedItems: DataFeedItem[] =
    data?.pages.flatMap((x) => {
      return [...x.data.domains]
    }) || []

  useIntersectionObserver({
    target: loadMoreContainerRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage && !isFetchingNextPage,
  })

  const handleChangeFilters = (newFilters: FilterProps) => {
    setFilters(newFilters)
  }

  const newDataFeedItems =
    (riskFilter || supplierFilter) && supplierIdsToFetch.length === 0 ? [] : flattenedDataFeedItems

  return (
    <div style={{ maxWidth: 1200, margin: 'auto' }}>
      <PageTitle title="Data feed" />
      <Filterbar
        handleChangeFilters={handleChangeFilters}
        filters={filters}
        segments={[]}
        searchWidth={275}
        withFilterBar={false}
      />
      <DataFeedTable
        dataFeedItems={newDataFeedItems}
        loading={isFetching || isRiskFetching || isRiskLoading}
        totalCount={flattenedDataFeedItems.length}
        basePath="/data-feed"
      />
      <div ref={loadMoreContainerRef} style={{ height: 200, width: '100%', opacity: 0 }}>
        Load more
      </div>
    </div>
  )
}

export default DataFeed
