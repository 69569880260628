import { useState } from 'react'
import { Dialog } from 'components'
import {
  Box,
  Button,
  ListItem,
  Checkbox,
  ListItemIcon,
  ListItemText,
  Chip,
  alpha,
  CircularProgress,
} from '@mui/material'
import { DataFeedItemSupplierAliasV2 } from 'services/mingle/__generated__/Api'
import { DataSubscription, SubscriptionType } from 'hooks/dataFeed/api'

interface Props {
  item: DataFeedItemSupplierAliasV2
  itemSubscriptions?: DataSubscription[]
  handleClose: () => void
  handleSaveChanges: ({
    globalDb,
    newsAndMedia,
    website,
  }: {
    globalDb: boolean
    newsAndMedia: boolean
    website: boolean
  }) => void
  loading: 'approved' | 'declined' | null
}

const AliasSettings = ({
  item,
  itemSubscriptions,
  handleClose,
  handleSaveChanges,
  loading,
}: Props) => {
  const globalDbSubscription = itemSubscriptions?.find(
    (sub) => sub.subscriptionType === SubscriptionType.GLOBAL_DB
  )
  const newsAndMediaSubscription = itemSubscriptions?.find(
    (sub) => sub.subscriptionType === SubscriptionType.NEWS_AND_MEDIA
  )
  const websiteSubscription = itemSubscriptions?.find(
    (sub) => sub.subscriptionType === SubscriptionType.WEBSITE
  )

  const [checked, setChecked] = useState({
    globalDb: Boolean(globalDbSubscription),
    newsAndMedia: Boolean(newsAndMediaSubscription),
    website: Boolean(websiteSubscription),
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked({
      ...checked,
      [event.target.name]: event.target.checked,
    })
  }

  return (
    <Dialog
      open
      title="Alias settings"
      description="Select features to enable for this alias."
      onClose={handleClose}
      withForm={false}
      withClose
      scroll="paper"
      maxWidth="xs"
      buttons={
        <>
          <Button variant="text" onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button onClick={() => handleSaveChanges(checked)} disabled={Boolean(loading)}>
            {loading && <CircularProgress size={16} sx={{ color: 'common.white ' }} />} Save changes
          </Button>
        </>
      }
    >
      <Box>
        <strong>{item.result.alias}</strong>
        <ListItem sx={{ pl: 0 }} disabled>
          <ListItemIcon sx={{ minWidth: 50 }}>
            <Checkbox onChange={handleChange} name="globalDb" checked={checked.globalDb} disabled />
          </ListItemIcon>
          <ListItemText
            primary={
              <>
                3rd party databases
                {globalDbSubscription && (
                  <Chip
                    label="Currently active"
                    sx={{
                      ml: 1,
                      mb: 0.4,
                      color: 'secondary.main',
                      backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.07),
                      '&:focus, &:hover': {
                        color: 'secondary.main',
                        backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.07),
                      },
                    }}
                  />
                )}
              </>
            }
            secondary="Monitor the alias in 3rd party databases for sustainability related data like certificates."
          />
        </ListItem>
        <ListItem sx={{ pl: 0 }}>
          <ListItemIcon sx={{ minWidth: 50 }}>
            <Checkbox onChange={handleChange} name="newsAndMedia" checked={checked.newsAndMedia} />
          </ListItemIcon>
          <ListItemText
            primary={
              <>
                Adverse News & Media
                {newsAndMediaSubscription && (
                  <Chip
                    label="Currently active"
                    sx={{
                      ml: 1,
                      mb: 0.4,
                      color: 'secondary.main',
                      backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.07),
                      '&:focus, &:hover': {
                        color: 'secondary.main',
                        backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.07),
                      },
                    }}
                  />
                )}
              </>
            }
            secondary="Monitor the alias for adverse news events across 199.999.000 news sources."
          />
        </ListItem>
        <ListItem sx={{ pl: 0 }}>
          <ListItemIcon sx={{ minWidth: 50 }}>
            <Checkbox onChange={handleChange} name="website" checked={checked.website} />
          </ListItemIcon>
          <ListItemText
            primary={
              <>
                Website Discovery
                {websiteSubscription && (
                  <Chip
                    label="Currently active"
                    sx={{
                      ml: 1,
                      mb: 0.4,
                      color: 'secondary.main',
                      backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.07),
                      '&:focus, &:hover': {
                        color: 'secondary.main',
                        backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.07),
                      },
                    }}
                  />
                )}
              </>
            }
            secondary="Our AI will use the alias to try to discovery the website of the supplier. Websites can then be monitored for sustainability related data like certificates."
          />
        </ListItem>
      </Box>
    </Dialog>
  )
}

export default AliasSettings
