import { SupplierFilterProps } from 'hooks/suppliers/queryKeys'
import { ConditionType, MatchType } from 'views/buyer/Scorecards/helpers'
import {
  RiskFilterOperatorApiDto,
  RiskFilterProperty,
  RiskFilterPropertyNumeric,
  SupplierFilterLatestSpendAmountTypeEnum,
  SupplierFilterPolicyParameterScoreTypeEnum,
  SupplierFilterProperty,
  SupplierFilterPropertyList,
  SupplierStatus,
} from 'typescript-axios'

export interface FilterProps {
  pageSize?: number
  segmentId?: string
  supplierName: string
  supplierCategories: string[]
  supplierCountries: string[]
  supplierIndustries: string[]
  supplierStatus?: SupplierStatus
  parameterRiskSeverity?: {
    severity?: ('low' | 'medium' | 'high')[]
    parameters?: string[]
  }
  spend?: {
    condition?: ConditionType.LESS_THAN_OR_EQUAL | ConditionType.GREATER_THAN_OR_EQUAL
    value?: number
  }
  parameterScore?: {
    condition?: ConditionType.LESS_THAN_OR_EQUAL | ConditionType.GREATER_THAN_OR_EQUAL
    value?: -3 | -2 | -1 | 0 | 1 | 2 | 3
    parameters?: string[]
  }
  percentageManagedRiskRange?: {
    from: number
    to: number
  }
}

export const defaultFilters: FilterProps = {
  pageSize: 15,
  supplierName: '',
  supplierCategories: [],
  supplierCountries: [],
  supplierIndustries: [],
  supplierStatus: undefined,
  parameterRiskSeverity: undefined,
  spend: undefined,
  parameterScore: undefined,
  percentageManagedRiskRange: undefined,
}

export const transformSupplierFilters = (filters: FilterProps): SupplierFilterProps => {
  const searchFilter =
    filters.supplierName.length > 0
      ? [
          {
            condition: ConditionType.MATCHES,
            match: MatchType.AND,
            property: SupplierFilterProperty.Name,
            value: [filters.supplierName],
          },
        ]
      : []
  const countryFilter =
    filters.supplierCountries.length > 0
      ? [
          {
            condition: ConditionType.IS_ONE_OF,
            match: MatchType.AND,
            property: SupplierFilterProperty.CountryIso3Code,
            value: [...filters.supplierCountries],
          },
        ]
      : []
  const industryFilter =
    filters.supplierIndustries.length > 0
      ? [
          {
            condition: ConditionType.CONTAINS_ONE_OF,
            match: MatchType.AND,
            property: SupplierFilterPropertyList.IndustryCodes,
            value: [...filters.supplierIndustries],
          },
        ]
      : []
  const categoryFilter =
    filters.supplierCategories.length > 0
      ? [
          {
            condition: ConditionType.CONTAINS_ONE_OF,
            match: MatchType.AND,
            property: SupplierFilterPropertyList.CategoryIds,
            value: [...filters.supplierCategories],
          },
        ]
      : []
  const statusFilter = filters.supplierStatus
    ? [
        {
          condition: ConditionType.MATCHES,
          match: MatchType.AND,
          property: SupplierFilterProperty.Status,
          value: [filters.supplierStatus],
        },
      ]
    : []

  const parameterScoreFilter =
    filters.parameterScore?.condition !== undefined &&
    filters.parameterScore.parameters &&
    filters.parameterScore.value !== undefined
      ? [
          {
            condition: filters.parameterScore?.condition as ConditionType,
            match: MatchType.AND,
            property: SupplierFilterPolicyParameterScoreTypeEnum.SupplierFilterPolicyParameterScore,
            value: [filters.parameterScore?.value] as number[],
            policyParameterIds: filters.parameterScore.parameters,
          },
        ]
      : []
  const segmentFilter =
    filters.segmentId !== undefined
      ? [
          {
            condition: ConditionType.CONTAINS_ONE_OF,
            match: MatchType.AND,
            property: SupplierFilterPropertyList.SegmentIds,
            value: [filters.segmentId],
          },
        ]
      : []

  const spendFilter =
    filters.spend?.condition !== undefined && filters.spend.value !== undefined
      ? [
          {
            condition: filters.spend?.condition as ConditionType,
            match: MatchType.AND,
            property: SupplierFilterLatestSpendAmountTypeEnum.SupplierFilterLatestSpendAmount,
            value: [filters.spend?.value] as number[],
          },
        ]
      : []

  return {
    pageSize: 15,
    filters: [
      ...searchFilter,
      ...countryFilter,
      ...categoryFilter,
      ...industryFilter,
      ...statusFilter,
      ...parameterScoreFilter,
      ...segmentFilter,
      ...spendFilter,
    ],
  }
}

const getRiskSeverityFilters = (
  riskSeverity: ('low' | 'medium' | 'high')[]
): RiskFilterOperatorApiDto => {
  switch (riskSeverity.length) {
    case 1:
      if (riskSeverity[0] === 'low') {
        return {
          property: RiskFilterPropertyNumeric.FilterScore,
          value: 40,
          type: 'RiskFilterLteOperatorApiDto',
        }
      }
      if (riskSeverity[0] === 'medium') {
        return {
          leftOp: {
            property: RiskFilterPropertyNumeric.FilterScore,
            value: 40,
            type: 'RiskFilterGtOperatorApiDto',
          },
          rightOp: {
            property: RiskFilterPropertyNumeric.FilterScore,
            value: 60,
            type: 'RiskFilterLteOperatorApiDto',
          },
          type: 'RiskFilterAndOperatorApiDto',
        }
      }
      if (riskSeverity[0] === 'high') {
        return {
          property: RiskFilterPropertyNumeric.FilterScore,
          value: 60,
          type: 'RiskFilterGtOperatorApiDto',
        }
      }
      break
    case 2:
      if (riskSeverity.includes('low') && riskSeverity.includes('high')) {
        return {
          leftOp: {
            property: RiskFilterPropertyNumeric.FilterScore,
            value: 40,
            type: 'RiskFilterLteOperatorApiDto',
          },
          rightOp: {
            property: RiskFilterPropertyNumeric.FilterScore,
            value: 60,
            type: 'RiskFilterGtOperatorApiDto',
          },
          type: 'RiskFilterOrOperatorApiDto',
        }
      }
      if (riskSeverity.includes('low') && riskSeverity.includes('medium')) {
        return {
          property: RiskFilterPropertyNumeric.FilterScore,
          value: 60,
          type: 'RiskFilterLteOperatorApiDto',
        }
      }
      if (riskSeverity.includes('medium') && riskSeverity.includes('high')) {
        return {
          property: RiskFilterPropertyNumeric.FilterScore,
          value: 40,
          type: 'RiskFilterGtOperatorApiDto',
        }
      }
      break
    case 3:
      return {
        property: RiskFilterPropertyNumeric.FilterScore,
        value: 0,
        type: 'RiskFilterGteOperatorApiDto',
      }
    default:
      return {
        property: RiskFilterPropertyNumeric.FilterScore,
        value: 0,
        type: 'RiskFilterGteOperatorApiDto',
      }
  }
  return {
    property: RiskFilterPropertyNumeric.FilterScore,
    value: 0,
    type: 'RiskFilterGteOperatorApiDto',
  }
}

export const createRiskFilters = ({
  riskSeverity,
  parameterIds,
}: {
  riskSeverity: ('low' | 'medium' | 'high')[]
  parameterIds: string[]
}): RiskFilterOperatorApiDto | undefined => {
  if (parameterIds.length === 0 || riskSeverity.length === 0) {
    return undefined
  }
  if (riskSeverity.length === 0 || riskSeverity.length === 3) {
    return {
      property: RiskFilterProperty.PolicyParameterId,
      values: parameterIds,
      type: 'RiskFilterIsAnyOfOperatorApiDto',
    }
  }
  return {
    leftOp: {
      property: RiskFilterProperty.PolicyParameterId,
      values: parameterIds,
      type: 'RiskFilterIsAnyOfOperatorApiDto',
    },
    rightOp: getRiskSeverityFilters(riskSeverity),
    type: 'RiskFilterAndOperatorApiDto',
  }
}
