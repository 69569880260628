import {
  Box,
  IconButton,
  CircularProgress,
  alpha,
  Button,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import {
  ClearRounded as ClearIcon,
  CheckRounded as CheckIcon,
  SettingsOutlined as SettingsIcon,
} from '@mui/icons-material'
import { DataFeedItem, isAccepted } from '../../helpers'

interface Props {
  loading: DataFeedItem['status'] | null
  status: DataFeedItem['status']
  handleAccept: () => void
  handleReject: () => void
  handleSettings?: () => void
}

const ActionCell = ({ loading, status, handleAccept, handleReject, handleSettings }: Props) => {
  const accepted = isAccepted(status)

  // const AcceptButton = (
  //   <Button
  //     startIcon={!loading && <CheckIcon sx={{ width: 16, height: 16, mr: -0.5 }} />}
  //     variant="contained"
  //     color="success"
  //     size="small"
  //     onClick={(e) => {
  //       e.stopPropagation()
  //       handleAccept()
  //     }}
  //     disabled={Boolean(loading) || approved}
  //     sx={{
  //       width: 80,
  //       height: 30,
  //       backgroundColor: (theme) => alpha(theme.palette.success.main, 0.2),
  //       color: 'common.black',
  //       '&:hover': {
  //         backgroundColor: (theme) => alpha(theme.palette.success.main, 0.3),
  //       },
  //     }}
  //   >
  //     <Box component="span" sx={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
  //       {loading === 'approved' ? (
  //         <CircularProgress size={14} sx={{ color: 'success.main' }} />
  //       ) : (
  //         'Accept'
  //       )}
  //     </Box>
  //   </Button>
  // )

  // const RejectButton = (
  //   <Button
  //     startIcon={!loading && <ClearIcon sx={{ width: 16, height: 16, mr: -0.5 }} />}
  //     variant="contained"
  //     color="error"
  //     size="small"
  //     onClick={(e) => {
  //       e.stopPropagation()
  //       handleReject()
  //     }}
  //     disabled={Boolean(loading) || rejected}
  //     sx={{
  //       width: 80,
  //       height: 30,
  //       mr: 0.5,
  //       backgroundColor: (theme) => alpha(theme.palette.warning.main, 0.2),
  //       color: 'common.black',
  //       '&:hover': {
  //         backgroundColor: (theme) => alpha(theme.palette.warning.main, 0.3),
  //       },
  //     }}
  //   >
  //     <Box component="span" sx={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
  //       {loading === 'declined' ? (
  //         <CircularProgress size={14} sx={{ color: 'error.main' }} />
  //       ) : (
  //         'Reject'
  //       )}
  //     </Box>
  //   </Button>
  // )

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <ToggleButtonGroup
        size="small"
        color="primary"
        value={accepted?.toString()}
        exclusive
        onChange={(e, value) => {
          e.stopPropagation()
          if (value === 'true') handleAccept()
          else handleReject()
        }}
        aria-label="Acceptance status"
      >
        <ToggleButton
          value="false"
          sx={{
            textTransform: 'none',
            fontWeight: 600,
            fontSize: (theme) => theme.typography.body2.fontSize,
            px: 2,
            py: 0.65,
            borderColor: '#eaeaea',
            // border: 'none',
            // backgroundColor:
            //   accepted === false
            //     ? (theme) => alpha(theme.palette.warning.main, 0.1)
            //     : (theme) => alpha(theme.palette.common.black, 0.03),
            // color: accepted === false ? 'warning.main' : 'common.black',
            // '&.Mui-selected': {
            //   backgroundColor: (theme) => alpha(theme.palette.warning.main, 1),
            //   color: 'common.black',
            // },
          }}
        >
          <Box component="span" sx={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
            {loading === 'declined' ? (
              <CircularProgress size={14} sx={{ color: 'error.main' }} />
            ) : (
              'Reject'
            )}
          </Box>
        </ToggleButton>
        <ToggleButton
          value="true"
          sx={{
            textTransform: 'none',
            fontWeight: 600,
            fontSize: (theme) => theme.typography.body2.fontSize,
            px: 1.5,
            py: 0.65,
            borderColor: '#eaeaea',
            // border: 'none',
            // backgroundColor: accepted
            //   ? (theme) => alpha(theme.palette.success.main, 0.1)
            //   : (theme) => alpha(theme.palette.common.black, 0.03),
            // color: accepted ? 'success.main' : 'common.black',
            // '&:hover': {
            //   backgroundColor: (theme) => alpha(theme.palette.success.main, 0.2),
            // },
            // '&.Mui-selected': {
            //   backgroundColor: (theme) => alpha(theme.palette.success.main, 1),
            //   color: 'common.black',
            // },
          }}
        >
          <Box component="span" sx={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
            {loading === 'approved' ? (
              <CircularProgress size={14} sx={{ color: 'success.main' }} />
            ) : (
              'Accept'
            )}
          </Box>
        </ToggleButton>
      </ToggleButtonGroup>
      {/* {status === 'in_review' && (
        <>
          {RejectButton}
          {AcceptButton}
        </>
      )}
      {rejected && AcceptButton}
      {approved && RejectButton} */}
      {/* <IconButton
        size="small"
        disabled={Boolean(loading) || status === 'declined'}
        sx={{
          backgroundColor: (theme) =>
            status === 'in_review'
              ? alpha(theme.palette.common.black, 0.04)
              : alpha(theme.palette.primary.main, 0.2),
          color: (theme) => `${theme.palette.primary.main} !important`,
          mr: 1,
          border: (theme) =>
            status === 'declined' ? `2px solid ${theme.palette.primary.main}` : 'none',
          opacity: approved ? 0.5 : 1,
          '&:hover': {
            backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.3),
          },
        }}
        onClick={(event) => {
          event.stopPropagation()
          handleReject()
        }}
      >
        {loading === 'declined' ? (
          <CircularProgress sx={{ color: 'primary.main' }} size={21} />
        ) : (
          <ClearIcon />
        )}
      </IconButton>
      <IconButton
        size="small"
        disabled={Boolean(loading) || approved}
        sx={{
          backgroundColor: (theme) =>
            status === 'in_review'
              ? alpha(theme.palette.common.black, 0.04)
              : (approved && `${alpha(theme.palette.success.main, 0.1)} !important`) ||
                alpha(theme.palette.success.main, 0.2),
          color: (theme) => `${theme.palette.success.main} !important`,
          mr: 1,
          border: (theme) => (approved ? `2px solid ${theme.palette.success.main}` : 'none'),
          opacity: !approved && status === 'declined' ? 0.5 : 1,
          '&:hover': {
            backgroundColor: (theme) => alpha(theme.palette.success.main, 0.3),
          },
        }}
        onClick={(event) => {
          event.stopPropagation()
          handleAccept()
        }}
      >
        {loading === 'approved' ? (
          <CircularProgress sx={{ color: 'success.main' }} size={21} />
        ) : (
          <CheckIcon />
        )}
      </IconButton> */}
      {handleSettings && (
        <IconButton size="small" onClick={handleSettings} sx={{ ml: 1.5 }}>
          <SettingsIcon />
        </IconButton>
      )}
    </Box>
  )
}

export default ActionCell
