import { useState, useEffect } from 'react'
import { useMixpanel } from 'services/mixpanel'
import {
  Grid,
  Button,
  buttonClasses,
  Typography,
  Chip,
  chipClasses,
  outlinedInputClasses,
  Box,
  InputAdornment,
  Fade,
  IconButton,
} from '@mui/material'
import { TextField, SupplierFilterDrawer } from 'components'
import { useGetInfiniteCategories, useGetCountries, useGetIndustries, useGetSettings } from 'hooks'
import { useDebounce } from 'utils'
import {
  FilterListRounded as FilterIcon,
  CategoryRounded as CategoryIcon,
  ClearRounded as ClearIcon,
  SearchRounded as SearchIcon,
  ArrowDropDownRounded as DropdownIcon,
} from '@mui/icons-material'
import { FilterProps } from 'components/Inputs/SupplierFilterDrawer/helpers'
import { ConditionType } from 'views/buyer/Scorecards/helpers'
import { SupplierSegmentApiDto } from 'typescript-axios'

interface Props {
  totalCount?: number
  filters: FilterProps
  handleChangeFilters: (newFilters: FilterProps) => void
  handleSaveSuppliersToList?: () => void
  segments?: SupplierSegmentApiDto[]
  handleViewListDialog?: () => void
  searchWidth?: number
  withFilterBar?: boolean
  children?: React.ReactNode
}

const Filterbar = ({
  totalCount,
  filters,
  handleChangeFilters,
  handleSaveSuppliersToList,
  segments,
  handleViewListDialog,
  searchWidth = 350,
  withFilterBar = true,
  children,
}: Props) => {
  const mixpanel = useMixpanel()

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [innerValue, setInnerValue] = useState(filters.supplierName)
  const [debouncedSearchValue] = useDebounce(innerValue, 500)

  const { data: categoriesData, hasNextPage, fetchNextPage } = useGetInfiniteCategories()
  const flattenedCategories = categoriesData?.pages.map((x) => x.data || []).flat()
  const { data: countriesData } = useGetCountries()
  const { data: industriesData } = useGetIndustries(undefined, undefined, true, true)
  const { data: settingsData } = useGetSettings()

  useEffect(() => {
    if (hasNextPage) fetchNextPage()
  }, [categoriesData])

  useEffect(() => {
    handleChangeFilters({
      ...filters,
      supplierName: innerValue,
    })
    mixpanel?.track('Search suppliers', {
      'Page Title': document.title,
      'Event parameters': {
        search: innerValue,
      },
      'Event category': 'Supplier',
    })
  }, [debouncedSearchValue])

  const handleSearchChange = (_name: string, value: string) => {
    setInnerValue(value)
  }

  const handleOpenFiltersMenu = (event: React.MouseEvent<HTMLElement>) => {
    mixpanel?.track('Open filter dropdown', {
      'Page Title': document.title,
      'Event category': 'Supplier',
    })
    setDrawerOpen(true)
  }

  const handleClearFilters = () => {
    mixpanel?.track('Clear filters', {
      'Page Title': document.title,
      'Event parameters': {
        search: filters.supplierName,
        countries: filters.supplierCountries,
        categories: filters.supplierCategories,
      },
      'Event category': 'Supplier',
    })
    handleChangeFilters({
      ...filters,
      supplierName: '',
      supplierCountries: [],
      supplierCategories: [],
      supplierIndustries: [],
      supplierStatus: undefined,
      parameterRiskSeverity: undefined,
      spend: undefined,
      parameterScore: undefined,
      percentageManagedRiskRange: undefined,
      segmentId: undefined,
    })
    setInnerValue('')
  }

  const handleRemoveSingleFilter = (
    type:
      | 'category'
      | 'country'
      | 'scorecard'
      | 'industry'
      | 'status'
      | 'riskSeverity'
      | 'spend'
      | 'parameterScore'
      | 'segmentId',
    id: string
  ) => {
    let updatedFilters: FilterProps

    switch (type) {
      case 'segmentId':
        updatedFilters = {
          ...filters,
          segmentId: undefined,
        }
        break
      case 'riskSeverity':
        updatedFilters = {
          ...filters,
          parameterRiskSeverity: undefined,
        }
        break
      case 'spend':
        updatedFilters = {
          ...filters,
          spend: undefined,
        }
        break
      case 'parameterScore':
        updatedFilters = {
          ...filters,
          parameterScore: undefined,
        }
        break
      case 'category':
        updatedFilters = {
          ...filters,
          supplierCategories: filters.supplierCategories.filter((x) => x !== id),
        }
        break
      case 'industry':
        updatedFilters = {
          ...filters,
          supplierIndustries: filters.supplierIndustries.filter((x) => x !== id),
        }
        break
      case 'status':
        updatedFilters = {
          ...filters,
          supplierStatus: undefined,
        }
        break
      case 'country':
      default:
        updatedFilters = {
          ...filters,
          supplierCountries: filters.supplierCountries.filter((x) => x !== id),
        }
    }

    handleChangeFilters(updatedFilters)
  }

  const filterCount = [
    ...filters.supplierCategories,
    ...filters.supplierCountries,
    ...filters.supplierIndustries,
    ...(filters.supplierStatus ? [filters.supplierStatus] : []),
    ...(filters.parameterScore?.parameters ? ['Score filter'] : []),
    ...(filters.parameterRiskSeverity ? ['Risk filter'] : []),
    ...(filters.segmentId ? ['List filter'] : []),
    ...(filters.spend ? ['Spend filter'] : []),
  ].length

  const handleChangeFiltersWithTracking = (newFilters?: FilterProps) => {
    mixpanel?.track('Filter suppliers', {
      'Page Title': document.title,
      'Event parameters': {
        search: newFilters?.supplierName,
        countries: newFilters?.supplierCountries.map((x) => {
          return {
            name: countriesData?.data?.find((y) => y.iso3Code === x)?.commonName,
            id: x,
          }
        }),
        categories: newFilters?.supplierCategories.map((x) => {
          return {
            name: flattenedCategories?.find((y) => y.id === x)?.name,
            id: x,
          }
        }),
        industries: newFilters?.supplierIndustries.map((x) => {
          return {
            name: industriesData?.data?.find((y) => y.code === x)?.name,
            id: x,
          }
        }),
      },
      'Event category': 'Supplier',
    })
    if (newFilters) {
      handleChangeFilters(newFilters)
    }
    setDrawerOpen(false)
  }

  return (
    <>
      <Grid container alignItems="center" justifyContent="space-between" spacing={1}>
        <Grid item>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <TextField
              size="small"
              onChange={handleSearchChange}
              value={innerValue}
              placeholder="Search suppliers"
              name="search"
              withMargin={false}
              sx={{
                width: 'fit-content',
                [`& .${outlinedInputClasses.root}`]: {
                  width: 'fit-content',
                  borderRadius: (theme) => `${theme.shape.borderRadius}px !important`,
                  border: (theme) => `1px solid ${theme.palette.backgroundDark}`,
                  maxWidth: searchWidth,
                  minWidth: searchWidth,
                  marginRight: 1,
                },
              }}
              withClear
              withSearchIcon
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ ml: -0.5 }} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    {segments && segments.length > 0 && (
                      <Button
                        variant="text"
                        size="small"
                        onClick={handleViewListDialog}
                        startIcon={<DropdownIcon sx={{ mr: -0.5 }} />}
                        sx={{
                          mr: innerValue && innerValue.length > 0 ? 0 : -3,
                          color: 'text.primary',
                        }}
                      >
                        View list
                      </Button>
                    )}
                    <Fade in={Boolean(innerValue && innerValue.length > 0)}>
                      <IconButton
                        size="small"
                        edge="end"
                        sx={{ mr: -0.85, padding: '2px' }}
                        onClick={() => {
                          setInnerValue('')
                          handleRemoveSingleFilter('segmentId', 'yay')
                        }}
                      >
                        <ClearIcon sx={{ width: 20, height: 20 }} />
                      </IconButton>
                    </Fade>
                  </InputAdornment>
                ),
              }}
            />
            {children}
            {withFilterBar && (
              <Button
                startIcon={<FilterIcon />}
                endIcon={
                  filterCount > 0 ? (
                    <Box
                      component="span"
                      sx={{
                        fontSize: '11px !important',
                        fontWeight: 600,
                        minWidth: 18,
                        height: 18,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: 'primary.main',
                        borderRadius: '50%',
                        color: 'common.white',
                        marginLeft: 1,
                        marginRight: '2px',
                        width: 'fit-content',
                        paddingLeft: '2px',
                        paddingRight: '2px',
                      }}
                    >
                      {filterCount}
                    </Box>
                  ) : undefined
                }
                variant="text"
                sx={{
                  color: filterCount === 0 ? 'text.primary' : 'primary.main',
                  marginLeft: filterCount === 0 ? 1 : 1,
                  [`& .${buttonClasses.startIcon}`]:
                    filterCount > 0
                      ? {
                          marginLeft: 0,
                        }
                      : {},
                }}
                onClick={handleOpenFiltersMenu}
              >
                Filters
              </Button>
            )}
            {filterCount > 0 && (
              <Button variant="text" color="primary" onClick={handleClearFilters}>
                Clear
              </Button>
            )}
            {Boolean(filterCount > 0 && totalCount && !filters.segmentId) && (
              <Button variant="text" color="primary" onClick={handleSaveSuppliersToList}>
                Save results to list
              </Button>
            )}
          </Box>
        </Grid>
        {totalCount !== undefined && (
          <Grid item>
            <Typography variant="body1">
              <strong>
                Showing {totalCount} supplier{totalCount === 1 ? '' : 's'}
              </strong>
            </Typography>
          </Grid>
        )}
      </Grid>

      {filterCount > 0 && (
        <Grid container sx={{ marginTop: 1 }}>
          {filters.supplierCountries.map((x) => {
            const findCountry = countriesData?.data?.find((y) => y.iso3Code === x)
            return (
              <Chip
                label={findCountry?.commonName || x}
                size="small"
                key={x}
                icon={
                  <img
                    src={`https://app.responsibly.tech/images/flags/${findCountry?.iso2Code}.svg`}
                    alt={x}
                    style={{ width: 12, height: 12 }}
                  />
                }
                style={{ marginRight: 4 }}
                onDelete={() => handleRemoveSingleFilter('country', x)}
                sx={{
                  [`& .${chipClasses.root}`]: {
                    height: 20,
                    backgroundColor: 'backgroundDark',
                    color: 'text.primary',
                    '&:focus': {
                      backgroundColor: 'backgroundDark',
                    },
                  },
                  [`& .${chipClasses.deleteIcon}`]: {
                    color: 'text.primary',
                  },
                  [`& .${chipClasses.label}`]: {
                    paddingRight: '10px',
                  },
                }}
              />
            )
          })}
          {filters.supplierCategories.map((x) => (
            <Chip
              label={flattenedCategories?.find((y) => y.id === x)?.name || x}
              size="small"
              key={x}
              icon={<CategoryIcon style={{ width: 14, height: 14 }} />}
              style={{ marginRight: 4 }}
              onDelete={() => handleRemoveSingleFilter('category', x)}
              sx={{
                [`& .${chipClasses.root}`]: {
                  height: 20,
                  backgroundColor: 'backgroundDark',
                  color: 'text.primary',
                  '&:focus': {
                    backgroundColor: 'backgroundDark',
                  },
                },
                [`& .${chipClasses.deleteIcon}`]: {
                  color: 'text.primary',
                },
                [`& .${chipClasses.label}`]: {
                  paddingRight: '10px',
                },
              }}
            />
          ))}
          {filters.supplierIndustries.map((x) => (
            <Chip
              label={industriesData?.data?.find((y) => y.code === x)?.name || x}
              size="small"
              key={x}
              style={{ marginRight: 4 }}
              onDelete={() => handleRemoveSingleFilter('industry', x)}
              sx={{
                [`& .${chipClasses.root}`]: {
                  height: 20,
                  backgroundColor: 'backgroundDark',
                  color: 'text.primary',
                  '&:focus': {
                    backgroundColor: 'backgroundDark',
                  },
                },
                [`& .${chipClasses.deleteIcon}`]: {
                  color: 'text.primary',
                },
                [`& .${chipClasses.label}`]: {
                  paddingRight: '10px',
                },
              }}
            />
          ))}
          {filters.supplierStatus && (
            <Chip
              label={filters.supplierStatus}
              size="small"
              style={{ marginRight: 4 }}
              onDelete={() => handleRemoveSingleFilter('status', 'yay')}
              sx={{
                [`& .${chipClasses.root}`]: {
                  height: 20,
                  backgroundColor: 'backgroundDark',
                  color: 'text.primary',
                  '&:focus': {
                    backgroundColor: 'backgroundDark',
                  },
                },
                [`& .${chipClasses.deleteIcon}`]: {
                  color: 'text.primary',
                },
                [`& .${chipClasses.label}`]: {
                  paddingRight: '10px',
                },
              }}
            />
          )}
          {filters.parameterRiskSeverity && (
            <Chip
              label={`${filters.parameterRiskSeverity.severity
                ?.map((x, index) => (index === 0 ? x.charAt(0).toUpperCase() + x.slice(1) : x))
                ?.join(' + ')} risk (${filters.parameterRiskSeverity.parameters?.length} parameter${
                filters.parameterRiskSeverity?.parameters &&
                filters.parameterRiskSeverity?.parameters?.length > 1
                  ? 's'
                  : ''
              })`}
              size="small"
              style={{ marginRight: 4 }}
              onDelete={() => handleRemoveSingleFilter('riskSeverity', 'yay')}
              sx={{
                [`& .${chipClasses.root}`]: {
                  height: 20,
                  backgroundColor: 'backgroundDark',
                  color: 'text.primary',
                  '&:focus': {
                    backgroundColor: 'backgroundDark',
                  },
                },
                [`& .${chipClasses.deleteIcon}`]: {
                  color: 'text.primary',
                },
                [`& .${chipClasses.label}`]: {
                  paddingRight: '10px',
                },
              }}
            />
          )}
          {filters.parameterScore && (
            <Chip
              label={`Score ${
                filters.parameterScore.condition === ConditionType.GREATER_THAN_OR_EQUAL
                  ? '>='
                  : '<='
              } ${filters.parameterScore.value} (${
                filters.parameterScore.parameters?.length
              } parameter${
                filters.parameterScore?.parameters && filters.parameterScore?.parameters?.length > 1
                  ? 's'
                  : ''
              })`}
              size="small"
              style={{ marginRight: 4 }}
              onDelete={() => handleRemoveSingleFilter('parameterScore', 'yay')}
              sx={{
                [`& .${chipClasses.root}`]: {
                  height: 20,
                  backgroundColor: 'backgroundDark',
                  color: 'text.primary',
                  '&:focus': {
                    backgroundColor: 'backgroundDark',
                  },
                },
                [`& .${chipClasses.deleteIcon}`]: {
                  color: 'text.primary',
                },
                [`& .${chipClasses.label}`]: {
                  paddingRight: '10px',
                },
              }}
            />
          )}
          {filters.spend && (
            <Chip
              label={`Spend ${
                filters.spend.condition === ConditionType.GREATER_THAN_OR_EQUAL ? '>=' : '<='
              } ${filters.spend.value} ${settingsData?.data?.defaultCurrencyIso3Code}`}
              size="small"
              style={{ marginRight: 4 }}
              onDelete={() => handleRemoveSingleFilter('spend', 'yay')}
              sx={{
                [`& .${chipClasses.root}`]: {
                  height: 20,
                  backgroundColor: 'backgroundDark',
                  color: 'text.primary',
                  '&:focus': {
                    backgroundColor: 'backgroundDark',
                  },
                },
                [`& .${chipClasses.deleteIcon}`]: {
                  color: 'text.primary',
                },
                [`& .${chipClasses.label}`]: {
                  paddingRight: '10px',
                },
              }}
            />
          )}
          {filters.segmentId && (
            <Chip
              label={segments?.find((y) => y.id === filters.segmentId)?.name || 'List filter'}
              size="small"
              style={{ marginRight: 4 }}
              onDelete={() => handleRemoveSingleFilter('segmentId', 'yay')}
              sx={{
                [`& .${chipClasses.root}`]: {
                  height: 20,
                  backgroundColor: 'backgroundDark',
                  color: 'text.primary',
                  '&:focus': {
                    backgroundColor: 'backgroundDark',
                  },
                },
                [`& .${chipClasses.deleteIcon}`]: {
                  color: 'text.primary',
                },
                [`& .${chipClasses.label}`]: {
                  paddingRight: '10px',
                },
              }}
            />
          )}
        </Grid>
      )}

      {drawerOpen && (
        <SupplierFilterDrawer
          open={drawerOpen}
          handleClose={handleChangeFiltersWithTracking}
          initialFilters={filters}
          defaultCurrencyIso3Code={settingsData?.data?.defaultCurrencyIso3Code}
        />
      )}
    </>
  )
}

export default Filterbar
