import { useState } from 'react'
import { Dialog } from 'components'
import {
  Box,
  Button,
  ListItem,
  Checkbox,
  ListItemIcon,
  ListItemText,
  Link,
  Chip,
  alpha,
  CircularProgress,
} from '@mui/material'
import { DataFeedItemDomainV2 } from 'services/mingle/__generated__/Api'
import { DataSubscription } from 'hooks/dataFeed/api'

interface Props {
  item: DataFeedItemDomainV2
  itemSubscriptions?: DataSubscription[]
  handleClose: () => void
  handleSaveChanges: ({
    dataDiscovery,
    industryDiscovery,
  }: {
    dataDiscovery: boolean
    industryDiscovery: boolean
  }) => void
  loading: 'approved' | 'declined' | null
}

const DomainSettings = ({
  item,
  itemSubscriptions,
  handleClose,
  handleSaveChanges,
  loading,
}: Props) => {
  const dataDiscoverySubscription = itemSubscriptions?.find(
    (sub) => sub.subscriptionType === 'DATA'
  )
  const industryDiscoverySubscription = itemSubscriptions?.find(
    (sub) => sub.subscriptionType === 'INDUSTRY'
  )

  const [checked, setChecked] = useState({
    dataDiscovery: Boolean(dataDiscoverySubscription),
    industryDiscovery: Boolean(industryDiscoverySubscription),
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked({
      ...checked,
      [event.target.name]: event.target.checked,
    })
  }

  return (
    <Dialog
      open
      title="Domain settings"
      description="Select features to enable for this domain."
      onClose={handleClose}
      withForm={false}
      withClose
      scroll="paper"
      maxWidth="xs"
      buttons={
        <>
          <Button variant="text" onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button onClick={() => handleSaveChanges(checked)} disabled={Boolean(loading)}>
            {loading && <CircularProgress size={16} sx={{ color: 'common.white ' }} />} Save changes
          </Button>
        </>
      }
    >
      <Box>
        <Link
          href={
            item.result.result.includes('http')
              ? item.result.result
              : `https://${item.result.result}`
          }
          target="_blank"
          variant="body2"
        >
          <strong>{item.result.result}</strong>
        </Link>
        <ListItem sx={{ pl: 0 }}>
          <ListItemIcon sx={{ minWidth: 50 }}>
            <Checkbox
              onChange={handleChange}
              name="dataDiscovery"
              checked={checked.dataDiscovery}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <>
                Data Discovery
                {dataDiscoverySubscription && (
                  <Chip
                    label="Currently active"
                    sx={{
                      ml: 1,
                      mb: 0.4,
                      color: 'secondary.main',
                      backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.07),
                      '&:focus, &:hover': {
                        color: 'secondary.main',
                        backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.07),
                      },
                    }}
                  />
                )}
              </>
            }
            secondary="Monitor the domain for sustainability related data like certificates & policy statements."
          />
        </ListItem>
        <ListItem sx={{ pl: 0 }}>
          <ListItemIcon sx={{ minWidth: 50 }}>
            <Checkbox
              onChange={handleChange}
              name="industryDiscovery"
              checked={checked.industryDiscovery}
            />
          </ListItemIcon>
          <ListItemText
            primary={
              <>
                Industry Discovery
                {industryDiscoverySubscription && (
                  <Chip
                    label="Currently active"
                    sx={{
                      ml: 1,
                      mb: 0.4,
                      color: 'secondary.main',
                      backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.07),
                      '&:focus, &:hover': {
                        color: 'secondary.main',
                        backgroundColor: (theme) => alpha(theme.palette.secondary.main, 0.07),
                      },
                    }}
                  />
                )}
              </>
            }
            secondary="Based on the content of the domain, our AI will try to determine GICS industries for the supplier."
          />
        </ListItem>
      </Box>
    </Dialog>
  )
}

export default DomainSettings
