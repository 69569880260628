import { useEffect } from 'react'
import {
  SupplierFilterProperty,
  SupplierFilterOperatorApiDto,
  ScorecardItemV3ApiDto,
  SupplierFilterPropertyList,
  ScorecardItemIn,
  SupplierFilterAndOperatorApiDtoTypeEnum,
  SupplierFilterContainsAllOfOperatorApiDtoTypeEnum,
  SupplierFilterContainsAnyOfOperatorApiDtoTypeEnum,
  SupplierFilterHasAnyValuesOperatorApiDtoTypeEnum,
  SupplierFilterIsAnyOfOperatorApiDtoTypeEnum,
  SupplierFilterIsOperatorApiDtoTypeEnum,
  SupplierFilterMatchesOperatorApiDtoTypeEnum,
  SupplierFilterNotOperatorApiDtoTypeEnum,
  SupplierFilterOrOperatorApiDtoTypeEnum,
  SupplierFilterLessThanOperatorApiDtoTypeEnum,
  SupplierFilterGreaterThanOperatorApiDtoTypeEnum,
  SupplierFilterLessThanOrEqualToOperatorApiDtoTypeEnum,
  SupplierFilterGreaterThanOrEqualToOperatorApiDtoTypeEnum,
  SupplierFilterScorecardScoreTypeEnum,
  SupplierFilterPolicyParameterScoreTypeEnum,
  SupplierFilterLatestSpendAmountTypeEnum,
} from 'typescript-axios'
import { v4 as uuid } from 'uuid'
import { ParameterWithTopLevelCategoryProps } from 'hooks/parameters/queries'
import { ExtendedDataSourceProps } from 'hooks/dataSources/queries'
import config from 'constants/config'
import { getParameterCategory } from 'utils'
import { useGetInfiniteCategories } from 'hooks/categories/queries'
import { useGetCountries } from 'hooks/countries/queries'
import { useGetIndustries } from 'hooks'

// eslint-disable-next-line
export enum TypeKey {
  PARAMETER = 'parameter',
  DATA_SOURCE = 'dataSource',
  GROUP = 'group',
}

// eslint-disable-next-line
export enum LocationTypeKey {
  AVAILABLE_ITEM = 'availableItem',
  SCORECARD_ITEM = 'scorecardItem',
}

// eslint-disable-next-line
export enum DropLocations {
  AVAILABLE_ITEMS = 'availableItems',
  SCORECARD_ITEMS = 'scorecardItems',
  TRASH = 'trash',
}

export interface ScorecardItem {
  primary: string
  secondary: string
  id: string
  avatar?: string
  type: TypeKey
  parentId?: string
}

export const formatAvailableScorecardItems = (
  parameters: ParameterWithTopLevelCategoryProps[],
  dataSources: ExtendedDataSourceProps[],
  scorecardItemIds?: string[]
): ScorecardItem[] =>
  [
    ...parameters.map((x) => {
      const category = x.topLevelCategory?.name || x.policyCategory.name
      return {
        primary: x.name,
        secondary: category,
        id: x.id,
        avatar: category,
        type: TypeKey.PARAMETER,
      }
    }),
    ...dataSources.map((x) => {
      return {
        primary: x.name,
        secondary: x.dataSourceType,
        id: x.id,
        avatar: x.imgUrl ? `${config.largeMediaUrl}${x.imgUrl}` : '',
        type: TypeKey.DATA_SOURCE,
      }
    }),
  ].filter((x) => !scorecardItemIds?.includes(x.id))

export const mapScorecardItems = (
  scorecardItems: ScorecardItemV3ApiDto[],
  availableItems: ScorecardItem[]
): ScorecardItem[] => {
  const flattenedItems = scorecardItems
    .map((x) => {
      const groupId = uuid()
      return 'items' in x && x.items
        ? [
            { ...x, id: groupId },
            ...x.items.map((y) => {
              return {
                ...y,
                parentId: groupId,
              }
            }),
          ]
        : { ...x, parentId: undefined }
    })
    .flat()
  return flattenedItems.map((x) => {
    if ('policyParameter' in x && x.policyParameter) {
      const findParameter = availableItems.find((y) => y.id === x.policyParameter.id)
      return {
        primary: findParameter?.primary || '',
        secondary: findParameter?.secondary || '',
        id: x.policyParameter.id,
        avatar: findParameter?.avatar,
        type: TypeKey.PARAMETER,
        parentId: x.parentId,
      }
    }
    if ('dataSource' in x) {
      const findDataSource = availableItems.find((y) => y.id === x.dataSource.id)
      return {
        primary: findDataSource?.primary || '',
        secondary: findDataSource?.secondary || '',
        id: x.dataSource.id,
        avatar: findDataSource?.avatar,
        type: TypeKey.DATA_SOURCE,
        parentId: x.parentId,
      }
    }
    return {
      primary: ('items' in x && x.name) || '',
      secondary: `${('items' in x && x.items && x.items.length) || 0} items`,
      id: 'id' in x ? x.id : '',
      type: TypeKey.GROUP,
    }
  })
}

export const formatItemsForApi = (items: ScorecardItem[]) => {
  const newItems = items.map((x, _index) => {
    if (x.type === TypeKey.GROUP) {
      return {
        name: x.primary,
        type: 'ScorecardGroupItemIn',
        items: items
          .filter((y) => y.parentId === x.id)
          .map((y) => {
            return y.type === TypeKey.DATA_SOURCE
              ? {
                  dataSourceId: y.id,
                  type: 'ScorecardDataSourceItemIn',
                }
              : {
                  policyParameterId: y.id,
                  type: 'ScorecardPolicyParameterItemIn',
                }
          }),
      }
    }
    if (x.type === TypeKey.DATA_SOURCE) {
      return {
        dataSourceId: x.id,
        type: 'ScorecardDataSourceItemIn',
      }
    }
    return {
      policyParameterId: x.id,
      type: 'ScorecardPolicyParameterItemIn',
    }
  })
  const flattenedItems = newItems // TODO fix this mess
    .map((x) => x.items)
    .flat()
    .map((x) => x?.dataSourceId || x?.policyParameterId)
  return newItems
    .filter((x) => {
      const id = x.dataSourceId || x.policyParameterId || ''
      return !flattenedItems.includes(id)
    })
    .map((x) => x as ScorecardItemIn)
}

/** Useful for editing in cases where you just wanna keep the items you have already */
export const formatScorecardItemsForApi = (items: ScorecardItemV3ApiDto[]): ScorecardItemIn[] => {
  return items.map((x) => {
    if ('policyParameter' in x && x.policyParameter) {
      return {
        policyParameterId: x.policyParameter.id,
        type: 'ScorecardPolicyParameterItemIn',
      }
    }
    if ('dataSource' in x) {
      return {
        dataSourceId: x.dataSource.id,
        type: 'ScorecardDataSourceItemIn',
      }
    }
    return {
      name: ('items' in x && x.name) || '',
      type: 'ScorecardGroupItemIn',
      items:
        ('items' in x &&
          x.items?.map((y) => {
            if ('policyParameter' in y && y.policyParameter) {
              return {
                policyParameterId: y.policyParameter.id,
                type: 'ScorecardPolicyParameterItemIn',
              }
            }
            return {
              dataSourceId: 'dataSource' in y ? y.dataSource.id : '',
              type: 'ScorecardDataSourceItemIn',
            }
          })) ||
        [],
    }
  })
}

// eslint-disable-next-line
export enum ItemType {
  ENVIRONMENTAL_IMPACT = 'environmentalImpact',
  SOCIAL_IMPACT = 'socialImpact',
  OPERATIONS = 'operations',
  SUPPLIER_MANAGEMENT = 'supplierManagement',
  QUALITY = 'quality',
  DATA_SOURCE = 'dataSource',
  UNKNOWN = 'unknown',
}

export interface ItemOccurrencesProps {
  type: ItemType
  name: string
  count: number
}

export const countItemOccurrences = (items: ScorecardItemV3ApiDto[]) => {
  const flattenedItems = items.map((x) => ('items' in x && x.items ? x.items : x)).flat()
  const withoutGroups = flattenedItems.filter(
    (x) => !('type' in x) || x.type !== 'ScorecardGroupItemV3ApiDto'
  )
  return withoutGroups.reduce((previousValue, currentValue) => {
    const findParameterCategory =
      'policyParameter' in currentValue
        ? getParameterCategory(currentValue.policyParameter)
        : undefined
    const currentValueType = ('dataSource' in currentValue && {
      type: ItemType.DATA_SOURCE,
      name: 'Data source',
    }) ||
      (findParameterCategory &&
        findParameterCategory.id === '01fb6qwy3kmbta7aayk8axcrv1' && {
          type: ItemType.ENVIRONMENTAL_IMPACT,
          name: 'Environmental impact',
        }) ||
      (findParameterCategory &&
        findParameterCategory.id === '01fb6qwy3kmbta7aayk8axcrtr' && {
          type: ItemType.SOCIAL_IMPACT,
          name: 'Social impact',
        }) ||
      (findParameterCategory &&
        findParameterCategory.id === '01fkq95x45pwwasdx15v1rttx3' && {
          type: ItemType.SUPPLIER_MANAGEMENT,
          name: 'Supplier management',
        }) ||
      (findParameterCategory &&
        findParameterCategory.id === '01fzffh9rmfym3zaynkq8fmpvd' && {
          type: ItemType.QUALITY,
          name: 'Quality',
        }) || { type: ItemType.UNKNOWN, name: 'unknown' }

    return previousValue.find((x) => x.type === currentValueType.type)
      ? previousValue.map((x) =>
          x.type === currentValueType.type ? { ...x, count: x.count + 1 } : x
        )
      : [
          ...previousValue,
          {
            ...currentValueType,
            count: 1,
          },
        ]
  }, [] as ItemOccurrencesProps[])
}

export enum ConditionType {
  IS_ONE_OF = 'is one of',
  IS_NOT_ONE_OF = 'is not one of',
  MATCHES = 'matches',
  DOES_NOT_MATCH = 'does not match',
  CONTAINS_ONE_OF = 'contain one of',
  CONTAINS_ALL_OF = 'contain all of',
  DOES_NOT_CONTAIN_ONE_OF = 'does not contain one of',
  DOES_NOT_CONTAIN_ALL_OF = 'does not contain all of',
  HAS_ANY_VALUE = 'has any value',
  HAS_NO_VALUE = 'has no value',
  LESS_THAN = 'less than',
  GREATER_THAN = 'greater than',
  LESS_THAN_OR_EQUAL = 'less than or equal to',
  GREATER_THAN_OR_EQUAL = 'greater than or equal to',
}

export const SupplierFilterType = {
  SupplierFilterAndOperatorApiDto:
    SupplierFilterAndOperatorApiDtoTypeEnum.SupplierFilterAndOperatorApiDto,
  SupplierFilterContainsAllOfOperatorApiDto:
    SupplierFilterContainsAllOfOperatorApiDtoTypeEnum.SupplierFilterContainsAllOfOperatorApiDto,
  SupplierFilterContainsAnyOfOperatorApiDto:
    SupplierFilterContainsAnyOfOperatorApiDtoTypeEnum.SupplierFilterContainsAnyOfOperatorApiDto,
  SupplierFilterHasAnyValuesOperatorApiDto:
    SupplierFilterHasAnyValuesOperatorApiDtoTypeEnum.SupplierFilterHasAnyValuesOperatorApiDto,
  SupplierFilterIsAnyOfOperatorApiDto:
    SupplierFilterIsAnyOfOperatorApiDtoTypeEnum.SupplierFilterIsAnyOfOperatorApiDto,
  SupplierFilterIsOperatorApiDto:
    SupplierFilterIsOperatorApiDtoTypeEnum.SupplierFilterIsOperatorApiDto,
  SupplierFilterMatchesOperatorApiDto:
    SupplierFilterMatchesOperatorApiDtoTypeEnum.SupplierFilterMatchesOperatorApiDto,
  SupplierFilterNotOperatorApiDto:
    SupplierFilterNotOperatorApiDtoTypeEnum.SupplierFilterNotOperatorApiDto,
  SupplierFilterOrOperatorApiDto:
    SupplierFilterOrOperatorApiDtoTypeEnum.SupplierFilterOrOperatorApiDto,
  SupplierFilterLessThanOperatorApiDto:
    SupplierFilterLessThanOperatorApiDtoTypeEnum.SupplierFilterLessThanOperatorApiDto,
  SupplierFilterGreaterThanOperatorApiDto:
    SupplierFilterGreaterThanOperatorApiDtoTypeEnum.SupplierFilterGreaterThanOperatorApiDto,
  SupplierFilterLessThanOrEqualToOperatorApiDto:
    // eslint-disable-next-line
    SupplierFilterLessThanOrEqualToOperatorApiDtoTypeEnum.SupplierFilterLessThanOrEqualToOperatorApiDto,
  SupplierFilterGreaterThanOrEqualToOperatorApiDto:
    // eslint-disable-next-line
    SupplierFilterGreaterThanOrEqualToOperatorApiDtoTypeEnum.SupplierFilterGreaterThanOrEqualToOperatorApiDto,
  // SupplierFilterScoreOperatorApiDto:
  //   SupplierFilterScoreOperatorApiDtoTypeEnum.SupplierFilterScoreOperatorApiDto,
}

// eslint-disable-next-line
export enum MatchType {
  AND = 'and',
  OR = 'or',
}

export const filterSettings = {
  [SupplierFilterProperty.Name]: [
    {
      condition: ConditionType.MATCHES,
      supplierFilterOperator: SupplierFilterType.SupplierFilterMatchesOperatorApiDto,
      isNot: false,
      isArray: false,
      noValue: false,
    },
    {
      condition: ConditionType.DOES_NOT_MATCH,
      supplierFilterOperator: SupplierFilterType.SupplierFilterMatchesOperatorApiDto,
      isNot: true,
      isArray: false,
      noValue: false,
    },
  ],
  [SupplierFilterProperty.CountryIso3Code]: [
    {
      condition: ConditionType.IS_ONE_OF,
      supplierFilterOperator: SupplierFilterType.SupplierFilterIsAnyOfOperatorApiDto,
      isNot: false,
      isArray: true,
      noValue: false,
    },
    {
      condition: ConditionType.IS_NOT_ONE_OF,
      supplierFilterOperator: SupplierFilterType.SupplierFilterIsAnyOfOperatorApiDto,
      isNot: true,
      isArray: true,
      noValue: false,
    },
    /** deprecated */
    {
      condition: ConditionType.IS_ONE_OF,
      supplierFilterOperator: SupplierFilterType.SupplierFilterIsOperatorApiDto,
      isNot: false,
      isArray: true,
      noValue: false,
    },
    {
      condition: ConditionType.IS_NOT_ONE_OF,
      supplierFilterOperator: SupplierFilterType.SupplierFilterIsOperatorApiDto,
      isNot: true,
      isArray: true,
      noValue: false,
    },
  ],
  [SupplierFilterProperty.Id]: [
    {
      condition: ConditionType.IS_ONE_OF,
      supplierFilterOperator: SupplierFilterType.SupplierFilterIsAnyOfOperatorApiDto,
      isNot: false,
      isArray: true,
      noValue: false,
    },
    {
      condition: ConditionType.IS_NOT_ONE_OF,
      supplierFilterOperator: SupplierFilterType.SupplierFilterIsAnyOfOperatorApiDto,
      isNot: true,
      isArray: true,
      noValue: false,
    },
  ],
  [SupplierFilterPropertyList.CategoryIds]: [
    {
      condition: ConditionType.CONTAINS_ONE_OF,
      supplierFilterOperator: SupplierFilterType.SupplierFilterContainsAnyOfOperatorApiDto,
      isNot: false,
      isArray: true,
      noValue: false,
    },
    {
      condition: ConditionType.CONTAINS_ALL_OF,
      supplierFilterOperator: SupplierFilterType.SupplierFilterContainsAllOfOperatorApiDto,
      isNot: false,
      isArray: true,
      noValue: false,
    },
    {
      condition: ConditionType.DOES_NOT_CONTAIN_ONE_OF,
      supplierFilterOperator: SupplierFilterType.SupplierFilterContainsAnyOfOperatorApiDto,
      isNot: true,
      isArray: true,
      noValue: false,
    },
    {
      condition: ConditionType.DOES_NOT_CONTAIN_ALL_OF,
      supplierFilterOperator: SupplierFilterType.SupplierFilterContainsAllOfOperatorApiDto,
      isNot: true,
      isArray: true,
      noValue: false,
    },
    {
      condition: ConditionType.HAS_ANY_VALUE,
      supplierFilterOperator: SupplierFilterType.SupplierFilterHasAnyValuesOperatorApiDto,
      isNot: false,
      isArray: true,
      noValue: true,
    },
    {
      condition: ConditionType.HAS_NO_VALUE,
      supplierFilterOperator: SupplierFilterType.SupplierFilterHasAnyValuesOperatorApiDto,
      isNot: true,
      isArray: true,
      noValue: true,
    },
  ],
  [SupplierFilterPropertyList.IndustryCodes]: [
    {
      condition: ConditionType.CONTAINS_ONE_OF,
      supplierFilterOperator: SupplierFilterType.SupplierFilterContainsAnyOfOperatorApiDto,
      isNot: false,
      isArray: true,
      noValue: false,
    },
    {
      condition: ConditionType.CONTAINS_ALL_OF,
      supplierFilterOperator: SupplierFilterType.SupplierFilterContainsAllOfOperatorApiDto,
      isNot: false,
      isArray: true,
      noValue: false,
    },
    {
      condition: ConditionType.DOES_NOT_CONTAIN_ONE_OF,
      supplierFilterOperator: SupplierFilterType.SupplierFilterContainsAnyOfOperatorApiDto,
      isNot: true,
      isArray: true,
      noValue: false,
    },
    {
      condition: ConditionType.DOES_NOT_CONTAIN_ALL_OF,
      supplierFilterOperator: SupplierFilterType.SupplierFilterContainsAllOfOperatorApiDto,
      isNot: true,
      isArray: true,
      noValue: false,
    },
    {
      condition: ConditionType.HAS_ANY_VALUE,
      supplierFilterOperator: SupplierFilterType.SupplierFilterHasAnyValuesOperatorApiDto,
      isNot: false,
      isArray: true,
      noValue: true,
    },
    {
      condition: ConditionType.HAS_NO_VALUE,
      supplierFilterOperator: SupplierFilterType.SupplierFilterHasAnyValuesOperatorApiDto,
      isNot: true,
      isArray: true,
      noValue: true,
    },
  ],
  [SupplierFilterPropertyList.ContactIds]: [
    {
      condition: ConditionType.HAS_ANY_VALUE,
      supplierFilterOperator: SupplierFilterType.SupplierFilterHasAnyValuesOperatorApiDto,
      isNot: false,
      isArray: false,
      noValue: true,
    },
    {
      condition: ConditionType.HAS_NO_VALUE,
      supplierFilterOperator: SupplierFilterType.SupplierFilterHasAnyValuesOperatorApiDto,
      isNot: true,
      isArray: false,
      noValue: true,
    },
  ],
  [SupplierFilterPropertyList.DataRequestIds]: [
    {
      condition: ConditionType.HAS_ANY_VALUE,
      supplierFilterOperator: SupplierFilterType.SupplierFilterHasAnyValuesOperatorApiDto,
      isNot: false,
      isArray: false,
      noValue: true,
    },
    {
      condition: ConditionType.HAS_NO_VALUE,
      supplierFilterOperator: SupplierFilterType.SupplierFilterHasAnyValuesOperatorApiDto,
      isNot: true,
      isArray: false,
      noValue: true,
    },
  ],
  [SupplierFilterProperty.Status]: [
    {
      condition: ConditionType.MATCHES,
      supplierFilterOperator: SupplierFilterType.SupplierFilterMatchesOperatorApiDto,
      isNot: false,
      isArray: false,
      noValue: false,
    },
    {
      condition: ConditionType.DOES_NOT_MATCH,
      supplierFilterOperator: SupplierFilterType.SupplierFilterMatchesOperatorApiDto,
      isNot: true,
      isArray: false,
      noValue: false,
    },
  ],
  [SupplierFilterScorecardScoreTypeEnum.SupplierFilterScorecardScore]: [
    {
      condition: ConditionType.LESS_THAN,
      supplierFilterOperator: SupplierFilterType.SupplierFilterLessThanOperatorApiDto,
      isNot: false,
      isArray: false,
      noValue: false,
    },
    {
      condition: ConditionType.GREATER_THAN,
      supplierFilterOperator: SupplierFilterType.SupplierFilterGreaterThanOperatorApiDto,
      isNot: false,
      isArray: false,
      noValue: false,
    },
    {
      condition: ConditionType.HAS_NO_VALUE,
      supplierFilterOperator: SupplierFilterType.SupplierFilterHasAnyValuesOperatorApiDto,
      isNot: false,
      isArray: false,
      noValue: false,
    },
  ],
  [SupplierFilterPolicyParameterScoreTypeEnum.SupplierFilterPolicyParameterScore]: [
    {
      condition: ConditionType.GREATER_THAN_OR_EQUAL,
      supplierFilterOperator: SupplierFilterType.SupplierFilterGreaterThanOrEqualToOperatorApiDto,
      isNot: false,
      isArray: false,
      noValue: false,
    },
    {
      condition: ConditionType.LESS_THAN_OR_EQUAL,
      supplierFilterOperator: SupplierFilterType.SupplierFilterLessThanOrEqualToOperatorApiDto,
      isNot: false,
      isArray: false,
      noValue: false,
    },
  ],
  [SupplierFilterLatestSpendAmountTypeEnum.SupplierFilterLatestSpendAmount]: [
    {
      condition: ConditionType.GREATER_THAN_OR_EQUAL,
      supplierFilterOperator: SupplierFilterType.SupplierFilterGreaterThanOrEqualToOperatorApiDto,
      isNot: false,
      isArray: false,
      noValue: false,
    },
    {
      condition: ConditionType.LESS_THAN_OR_EQUAL,
      supplierFilterOperator: SupplierFilterType.SupplierFilterLessThanOrEqualToOperatorApiDto,
      isNot: false,
      isArray: false,
      noValue: false,
    },
  ],
  [SupplierFilterProperty.ScoreNotAvailable]: [],
  [SupplierFilterPropertyList.SegmentIds]: [
    {
      condition: ConditionType.CONTAINS_ONE_OF,
      supplierFilterOperator: SupplierFilterType.SupplierFilterContainsAnyOfOperatorApiDto,
      isNot: false,
      isArray: true,
      noValue: false,
    },
    {
      condition: ConditionType.CONTAINS_ALL_OF,
      supplierFilterOperator: SupplierFilterType.SupplierFilterContainsAllOfOperatorApiDto,
      isNot: false,
      isArray: true,
      noValue: false,
    },
    {
      condition: ConditionType.DOES_NOT_CONTAIN_ONE_OF,
      supplierFilterOperator: SupplierFilterType.SupplierFilterContainsAnyOfOperatorApiDto,
      isNot: true,
      isArray: true,
      noValue: false,
    },
    {
      condition: ConditionType.DOES_NOT_CONTAIN_ALL_OF,
      supplierFilterOperator: SupplierFilterType.SupplierFilterContainsAllOfOperatorApiDto,
      isNot: true,
      isArray: true,
      noValue: false,
    },
    {
      condition: ConditionType.HAS_ANY_VALUE,
      supplierFilterOperator: SupplierFilterType.SupplierFilterHasAnyValuesOperatorApiDto,
      isNot: false,
      isArray: true,
      noValue: true,
    },
    {
      condition: ConditionType.HAS_NO_VALUE,
      supplierFilterOperator: SupplierFilterType.SupplierFilterHasAnyValuesOperatorApiDto,
      isNot: true,
      isArray: true,
      noValue: true,
    },
  ],
}

export const getValidFilters = (filters: FilterCriteriaProps[]) => {
  return filters.filter((x) => {
    if (!x.property || !x.condition) return false

    const findPropertySettings = x.property in filterSettings ? filterSettings[x.property] : null
    if (!findPropertySettings) return false

    const findConditionSettings = findPropertySettings.find((y) => y.condition === x.condition)
    if (!findConditionSettings) return false

    if (
      x.property === SupplierFilterPolicyParameterScoreTypeEnum.SupplierFilterPolicyParameterScore
    ) {
      return x.value !== null && x.policyParameterIds && x.policyParameterIds.length > 0
    }

    return findConditionSettings.noValue ? true : x.value
  })
}

export interface FilterCriteriaProps {
  match: MatchType
  property:
    | SupplierFilterProperty
    | SupplierFilterPropertyList
    | typeof SupplierFilterScorecardScoreTypeEnum.SupplierFilterScorecardScore
    | typeof SupplierFilterPolicyParameterScoreTypeEnum.SupplierFilterPolicyParameterScore
    | typeof SupplierFilterLatestSpendAmountTypeEnum.SupplierFilterLatestSpendAmount
    | null
  condition: ConditionType | null
  value: (string | number)[] | null
  id?: string
  policyParameterIds?: string[]
}

export const defaultFilterCriteria = {
  property: null,
  condition: null,
  value: null,
  id: uuid(),
  match: MatchType.AND,
}

export const formatFiltersForApi = (
  filters: FilterCriteriaProps[]
): SupplierFilterOperatorApiDto => {
  const reverse = [...filters].reverse()

  return reverse.reduce<SupplierFilterOperatorApiDto>(
    (previousValue, currentValue, currentIndex): SupplierFilterOperatorApiDto => {
      const isFirstItem = currentIndex === 0

      const type =
        currentValue.match === MatchType.AND
          ? SupplierFilterType.SupplierFilterAndOperatorApiDto
          : SupplierFilterType.SupplierFilterOrOperatorApiDto

      // if a prop somehow wasn't set for an item, throw away
      if (!currentValue.condition || !currentValue.property) {
        return { ...previousValue }
      }

      const findPropertySettings =
        currentValue.property && currentValue.property in filterSettings
          ? filterSettings[currentValue.property]
          : null

      if (!findPropertySettings) return { ...previousValue }

      const findConditionSettings = findPropertySettings.find(
        (x) => x.condition === currentValue.condition
      )

      if (!findConditionSettings) return { ...previousValue }

      let leftOp: SupplierFilterOperatorApiDto & { value?: string | number } & {
        values?: (string | number)[]
      } = {
        property: currentValue.property as any,
        type: findConditionSettings.supplierFilterOperator as any,
      }

      if (!findConditionSettings.noValue && currentValue.value) {
        leftOp = findConditionSettings.isArray
          ? {
              property: currentValue.property as SupplierFilterPropertyList,
              values: currentValue.value as any,
              type: findConditionSettings.supplierFilterOperator as any,
            }
          : {
              property:
                // Quick fix
                (currentValue.property === 'SupplierFilterScorecardScore' &&
                  ({
                    type: 'SupplierFilterScorecardScore',
                  } as any)) ||
                (currentValue.property === 'SupplierFilterPolicyParameterScore' &&
                  'policyParameterIds' in currentValue && {
                    type: 'SupplierFilterPolicyParameterScore',
                    policyParameterIds: currentValue.policyParameterIds,
                  }) ||
                (currentValue.property === 'SupplierFilterLatestSpendAmount' && {
                  type: 'SupplierFilterLatestSpendAmount',
                }) ||
                (currentValue.property as SupplierFilterProperty),
              value: currentValue.value[0],
              type: findConditionSettings.supplierFilterOperator as any,
            }
      }

      const rightOp = { ...previousValue }

      if (findConditionSettings.isNot) {
        return isFirstItem
          ? {
              op: leftOp,
              type: SupplierFilterType.SupplierFilterNotOperatorApiDto,
            }
          : {
              leftOp: {
                op: leftOp,
                type: SupplierFilterType.SupplierFilterNotOperatorApiDto,
              },
              rightOp,
              type,
            }
      }

      if (isFirstItem) {
        return leftOp
      }

      return {
        leftOp,
        rightOp,
        type,
      }
    },
    {} as SupplierFilterOperatorApiDto
  )
}

type ValidPropertyType =
  | SupplierFilterProperty
  | SupplierFilterPropertyList
  | typeof SupplierFilterScorecardScoreTypeEnum.SupplierFilterScorecardScore
  | typeof SupplierFilterPolicyParameterScoreTypeEnum.SupplierFilterPolicyParameterScore
  | typeof SupplierFilterLatestSpendAmountTypeEnum.SupplierFilterLatestSpendAmount

const getFilterListItem = ({
  property,
  type,
  value,
  isNot,
}: {
  property: ValidPropertyType | { type: string; policyParameterIds?: string[] } | null
  type: string
  value: (string | number)[] | null
  isNot: boolean
}): {
  property: FilterCriteriaProps['property']
  condition: FilterCriteriaProps['condition']
  value: FilterCriteriaProps['value']
  policyParameterIds?: string[]
} => {
  let actualProperty: ValidPropertyType | null = null
  let policyParameterIds: string[] | undefined

  if (property === null) {
    actualProperty = null
  } else if (typeof property === 'object' && property !== null && 'type' in property) {
    const propertyObj = property as { type: string; policyParameterIds?: string[] }

    if (
      propertyObj.type === 'SupplierFilterPolicyParameterScore' &&
      propertyObj.policyParameterIds
    ) {
      policyParameterIds = propertyObj.policyParameterIds
    }

    switch (propertyObj.type) {
      case 'SupplierFilterScorecardScore':
        actualProperty = SupplierFilterScorecardScoreTypeEnum.SupplierFilterScorecardScore
        break
      case 'SupplierFilterPolicyParameterScore':
        actualProperty =
          SupplierFilterPolicyParameterScoreTypeEnum.SupplierFilterPolicyParameterScore
        break
      case 'SupplierFilterLatestSpendAmount':
        actualProperty = SupplierFilterLatestSpendAmountTypeEnum.SupplierFilterLatestSpendAmount
        break
      default:
        actualProperty = null
    }
  } else {
    actualProperty = property as ValidPropertyType
  }

  const findPropertySettings =
    actualProperty && actualProperty in filterSettings
      ? filterSettings[actualProperty as keyof typeof filterSettings]
      : null

  const findTypeSettings = findPropertySettings?.find(
    (x) => x.supplierFilterOperator === type && x.isNot === isNot
  )

  return findTypeSettings
    ? {
        property: actualProperty,
        condition: findTypeSettings.condition,
        value: findTypeSettings.noValue ? null : value,
        policyParameterIds,
      }
    : {
        property: null,
        condition: null,
        value: null,
      }
}

// this is stupid, optimize
export const formatApiFiltersToList = (
  filters: SupplierFilterOperatorApiDto,
  /** Avoid rendering with ID for requests that needs caching */
  withId = true
): FilterCriteriaProps[] => {
  const flattenedItems: FilterCriteriaProps[] = []

  // encountering a "left" is considered an end while we keep traversing over rights until we find a { property, type, value(s) }
  const traverse = (obj: SupplierFilterOperatorApiDto, previousType: string | number) => {
    const convertToAndOr =
      previousType === SupplierFilterType.SupplierFilterOrOperatorApiDto
        ? MatchType.OR
        : MatchType.AND

    // must be an end
    if ('property' in obj && 'type' in obj) {
      flattenedItems.push({
        ...getFilterListItem({
          property: obj.property as any,
          type: obj.type,
          value:
            'values' in obj && obj.values ? [...obj.values] : ('value' in obj && [obj.value]) || [],
          isNot: false,
        }),
        match: convertToAndOr,
      })
    }
    // must be an end
    if ('leftOp' in obj && 'property' in obj.leftOp && 'type' in obj.leftOp) {
      flattenedItems.push({
        ...getFilterListItem({
          property: obj.leftOp.property as any,
          type: obj.leftOp.type,
          value:
            'values' in obj.leftOp && obj.leftOp.values
              ? [...obj.leftOp.values]
              : ('value' in obj.leftOp && [obj.leftOp.value]) || [],
          isNot: false,
        }),
        match: convertToAndOr,
      })
    }
    // must be a NOT end
    if (
      'leftOp' in obj &&
      'op' in obj.leftOp &&
      'property' in obj.leftOp.op &&
      obj.leftOp.type === SupplierFilterType.SupplierFilterNotOperatorApiDto
    ) {
      flattenedItems.push({
        ...getFilterListItem({
          property: obj.leftOp.op.property as any,
          type: obj.leftOp.op.type,
          value:
            'values' in obj.leftOp.op && obj.leftOp.op.values
              ? [...obj.leftOp.op.values]
              : ('value' in obj.leftOp.op && [obj.leftOp.op.value]) || [],
          isNot: true,
        }),
        match: convertToAndOr,
      })
    }
    // either end or left/right
    if ('op' in obj && obj.type === SupplierFilterType.SupplierFilterNotOperatorApiDto) {
      if ('property' in obj.op) {
        flattenedItems.push({
          ...getFilterListItem({
            property: obj.op.property as any,
            type: obj.op.type,
            value:
              'values' in obj.op && obj.op.values
                ? [...obj.op.values]
                : ('value' in obj.op && [obj.op.value]) || [],
            isNot: true,
          }),
          match: convertToAndOr,
        })
      }
    }

    if ('rightOp' in obj) traverse(obj.rightOp, obj.type)
  }
  traverse(filters, filters.type)

  const matches = [...flattenedItems.map((x) => x.match), MatchType.AND]
  matches.shift()

  const setCorrectMatches = flattenedItems.map((x, index) => {
    return {
      ...x,
      match: matches[index] || MatchType.AND,
    }
  })

  return withId
    ? setCorrectMatches.map((x) => {
        return {
          ...x,
          id: uuid(),
        }
      })
    : setCorrectMatches
}

export const useReadableFilters = (filters: FilterCriteriaProps[]) => {
  const { data: countriesData } = useGetCountries()
  const { data: categoriesData, hasNextPage, fetchNextPage } = useGetInfiniteCategories()
  const { data: industriesData } = useGetIndustries()

  const flattenedCategories = categoriesData?.pages.map((x) => x.data || []).flat() || []

  useEffect(() => {
    if (hasNextPage) fetchNextPage()
  }, [categoriesData])

  const getFilterValue = (filter: (typeof filters)[0]) => {
    const fv = filter.value || []

    if (filter.property === SupplierFilterPropertyList.CategoryIds) {
      return fv.map((x) => `${flattenedCategories.find((y) => y.id === x)?.name || x}`) || []
    }

    if (filter.property === SupplierFilterPropertyList.IndustryCodes) {
      return fv.map((x) => `${industriesData?.data?.find((y) => y.code === x)?.name || x}`)
    }

    if (
      filter.property === SupplierFilterLatestSpendAmountTypeEnum.SupplierFilterLatestSpendAmount
    ) {
      return fv.map((x) => `${x.toLocaleString()}`)
    }

    return fv.map((x) => `${countriesData?.data?.find((y) => y.iso3Code === x)?.commonName || x}`)
  }

  return filters.map((filter) => {
    const baseFilter = {
      ...filter,
      property:
        (filter.property === SupplierFilterPropertyList.CategoryIds && 'Categories') ||
        (filter.property === SupplierFilterPropertyList.IndustryCodes && 'Industries') ||
        (filter.property === SupplierFilterProperty.CountryIso3Code && 'Country') ||
        (filter.property === SupplierFilterProperty.Name && 'Name') ||
        (filter.property === SupplierFilterScorecardScoreTypeEnum.SupplierFilterScorecardScore &&
          'Performance') ||
        (filter.property ===
          SupplierFilterPolicyParameterScoreTypeEnum.SupplierFilterPolicyParameterScore &&
          'Performance score') ||
        (filter.property === SupplierFilterProperty.Status && 'Status') ||
        (filter.property ===
          SupplierFilterLatestSpendAmountTypeEnum.SupplierFilterLatestSpendAmount &&
          'Spend amount') ||
        'Unknown',
      value: getFilterValue(filter),
    }

    if (filter.policyParameterIds && filter.policyParameterIds.length > 0) {
      return {
        ...baseFilter,
        value: [
          `${baseFilter.value.join(' ')} (${filter.policyParameterIds.length} parameter${
            filter.policyParameterIds.length > 1 ? 's' : ''
          })`,
        ],
      }
    }

    return baseFilter
  })
}
