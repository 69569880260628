export interface DataSourceStaticMetadata {
  imgUrl?: string
  website?: string
  linkedin?: string
  tableColumns?: {
    /** title of the table header */
    name: string
    /** keys to look for in the raw data. Works by "first match", so you can fill out several and alter the content() function as you wish */
    keys: string[]
    /** how to assemble the values found by above keys and output in table cell */
    content: (strings: string[]) => string
  }[]
}
// British Retail Consortium Global Standards (BRCGS)
const brcgs = {
  imgUrl: '/images/brcgs-logo.png',
  website: 'https://www.brcgs.com/',
  linkedin: 'https://www.linkedin.com/company/brcgs/',
  tableColumns: [
    {
      name: 'Grade',
      keys: ['grade'],
      content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
    },
    {
      name: 'Last Updated',
      keys: ['date_extracted', 'upload_timestamp'],
      content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
    },
    {
      name: 'Location',
      keys: ['company_city', 'company_address'],
      content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
    },
  ],
}

const staticMetaData = {
  /** B Corporations */
  '01fpt63n3x8vv20y2q20zj6qhv': {
    imgUrl: '/images/b_corporations_logo.png',
    website: 'https://bcorporation.net/',
    tableColumns: [
      {
        name: 'Certified since',
        keys: ['Certified_Since', 'Certified Since', 'certified_since', 'date_valid_from'],
        content: (values: string[]) => (values.length > 0 ? `${values[0]}` : ''),
      },
      {
        name: 'B Corp score',
        keys: ['Qualifies_for_B_Corp_certfication', 'b_corp_overall_score', 'B Corp Overall Score'],
        content: (values: string[]) => (values.length > 0 ? `${values[0]}` : ''),
      },
      {
        name: 'Last Updated',
        keys: ['extraction_date', 'date_extracted'],
        content: (values: string[]) => (values.length > 0 ? `${values[0]}` : ''),
      },
    ],
  },
  /** bonsucro */
  '01fpt661qgsgkfdd4kswce6tfa': {
    imgUrl: '/images/bonsucro.png',
    tableColumns: [],
  },
  /** Bloomberg - Gender Equality Index (GEI) */
  '01fwrd863cfw6rcd203evtet8w': {
    imgUrl: '/images/bloomberg.png',
    website: 'https://www.bloomberg.com/gei/',
  },
  /** CE marking */
  '01fxmw06ka223md4z5n4b90qfe': {
    imgUrl: '/images/CE_Marking.png',
    website: 'https://ec.europa.eu/growth/single-market/ce-marking_en',
  },
  /** CCOF */
  '01fs9b6fwnmyn82gctgr994zyn': {
    imgUrl: '/images/ccof_logo.png',
    website: 'https://www.ccof.org/',
    linkedin: 'https://www.linkedin.com/company/ccof/',
    tableColumns: [
      {
        name: 'Location',
        keys: ['local'],
        content: (values: string[]) => (values.length > 0 ? `${values[0]}` : ''),
      },
      {
        name: 'Certification status',
        keys: ['Status'],
        content: (values: string[]) => (values.length > 0 ? `${values[0]}` : ''),
      },
    ],
  },
  /** CDP Climate */
  '01fq6jch4ckddp9ne1mze5bx0s': {
    imgUrl: '/images/cdp-logo.png',
    website: 'https://www.cdp.net/en',
    linkedin: 'https://www.linkedin.com/company/cdp-worldwide/',
    tableColumns: [
      {
        name: 'Survey year',
        keys: ['Year'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Status',
        keys: ['status'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'CDP score',
        keys: ['Score', 'score_climate_change'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** CDP Forests */
  '01fq6jace7b8c284byrz3vnfq0': {
    imgUrl: '/images/cdp-logo.png',
    website: 'https://www.cdp.net/en',
    linkedin: 'https://www.linkedin.com/company/cdp-worldwide/',
    tableColumns: [
      {
        name: 'Survey year',
        keys: ['Year'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Timber',
        keys: ['score_forests_timber', 'Score: Forests Timber'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Soy',
        keys: ['score_forests_soy', 'Score: Forests Soy'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Palm Oil',
        keys: ['score_forests_palm_oil', 'Score: Forests Palm'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Cattle Products',
        keys: ['score_forests_cattle_products', 'Score: Forests Cattle Products'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** CDP Water */
  '01fq4fkgq9yrsd58x0a5jyrtjc': {
    imgUrl: '/images/cdp-logo.png',
    website: 'https://www.cdp.net/en',
    linkedin: 'https://www.linkedin.com/company/cdp-worldwide/',
    tableColumns: [
      {
        name: 'Survey year',
        keys: ['Year'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Status',
        keys: ['status'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'CDP score',
        keys: ['Score', 'score_water_security'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** Carbon Disclosure Project (CDP) - Supplier Engagement Rating */
  '01fx818kj8b75g02jgfgtgng8v': {
    imgUrl: '/images/cdp-logo.png',
    website: 'https://www.cdp.net/en/articles/supply-chain/supplier-engagement-leaderboard-2019',
  },
  /** Donau Soja */
  '01fs4rv9x90bpy5jww05j8fjqq': {
    imgUrl: '/images/donauSoja.jpg',
    website: 'https://www.donausoja.org/en/',
    linkedin: 'https://www.linkedin.com/company/danube-soya/',
    tableColumns: [
      {
        name: 'Location',
        keys: ['Postal Code'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** Fair for Life */
  '01fpt7b7tzf8sjx1ahyhs7gqcq': {
    imgUrl: '/images/fairforlife_logo.png',
    website:
      'https://www.fairforlife.org/pmws/indexDOM.php?client_id=fairforlife&page_id=ffl&lang_iso639=en',
    linkedin: 'https://www.linkedin.com/company/fair-for-life/',
    tableColumns: [
      {
        name: 'Overall Rating (out of 5)',
        keys: ['overall_rating_out_of_5'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Last Updated',
        keys: ['date_extracted'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** Fair Trade Certified */
  '01fpt7g9dfhmqe2b58dayrycrp': {
    imgUrl: '/images/fair_trade_certified.png',
    website: 'https://www.fairtradecertified.org/',
    linkedin: 'https://www.linkedin.com/company/fair-trade-usa/',
    tableColumns: [
      {
        name: 'Product line',
        keys: ['Product Line', 'product_line'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Last updated',
        keys: ['date_extracted'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** Fair Trade Certified - Trade Standard */
  '01fy6jjczywv6e87c6nb3p26cg': {
    imgUrl: '/images/fair_trade_certified.png',
    website: 'https://www.fairtradecertified.org/',
    linkedin: 'https://www.linkedin.com/company/fair-trade-usa/',
    tableColumns: [
      {
        name: 'Product line',
        keys: ['Product Line'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** Fair Trade Certified - Agricultural Production Standard */
  '01fy6jeerfadsp7jsy8g94maxx': {
    imgUrl: '/images/fair_trade_certified.png',
    website: 'https://www.fairtradecertified.org/',
    linkedin: 'https://www.linkedin.com/company/fair-trade-usa/',
    tableColumns: [
      {
        name: 'Product line',
        keys: ['Product Line'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** Fairtrade International */
  '01fpt7pp5ecxngh1zf69sg7cg2': {
    imgUrl: '/images/fairtrade.png',
    website: 'https://www.fairtrade.net/',
    linkedin: 'https://www.linkedin.com/company/fairtrade-international-flo-/',
    tableColumns: [
      {
        name: 'Products',
        keys: ['Products', 'certified_products'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Last Updated',
        keys: ['date_extracted'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** Fairtrade International - Trader Standard */
  '01fy4kb9stfa8d38aepbe544bp': {
    imgUrl: '/images/fairtrade.png',
    website: 'https://www.fairtrade.net/',
    linkedin: 'https://www.linkedin.com/company/fairtrade-international-flo-/',
    tableColumns: [
      {
        name: 'Products',
        keys: ['Products', 'certified_products'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Last Updated',
        keys: ['date_extracted'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** FAMI QS */
  '01fqc9gmqv8rmdprya5kjyjc0n': {
    imgUrl: '/images/fami_qs_logo_2.png',
    website: 'https://www.fami-qs.org/',
    linkedin: 'https://www.linkedin.com/company/fami-qs/',
    tableColumns: [
      {
        name: 'Validity period',
        keys: ['Certified from', 'Expiry date'],
        content: (values: string[]) => (values.length >= 1 ? `${values[0]} - ${values[1]}` : ''),
      },
      {
        name: 'Location',
        keys: ['City', 'company_city'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** Farm Sustainability Assessment (FSA) by SAI Platform */
  '01fw195a9qj7e8qmrj47sa9ywr': {
    imgUrl: '/images/fsa_by_sai.png',
    website: 'https://saiplatform.org/fsa/',
  },

  /** FSC */
  '01fqe4bdmtz49604k9j16dp1sh': {
    imgUrl: '/images/fsc.png',
    website: 'https://fsc.org/',
    linkedin: 'https://www.linkedin.com/company/fsc-international-bonn/',
    tableColumns: [
      {
        name: 'Location',
        keys: ['Site City'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** For Life */
  '01fpt7dgfwxnwba4qkzr4mpa22': {
    imgUrl: '/images/for_life_logo.png',
    website:
      'https://www.fairforlife.org/pmws/indexDOM.php?client_id=fairforlife&page_id=fl&lang_iso639=en',
    linkedin: 'https://www.linkedin.com/company/fair-for-life/',
    tableColumns: [
      {
        name: 'Date of certification',
        keys: ['Date of Certification'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Products',
        keys: ['Certified Products'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Overall rating (1-5)',
        keys: ['Overall Rating (out of 5)'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** Global Coffee Platform */
  '01fs4ttdf6fvr7h4j9e2jsgea4': {
    imgUrl: '/images/globalcoffeeplatform.png',
    website: 'https://www.globalcoffeeplatform.org/',
    linkedin: 'https://www.linkedin.com/company/global-coffee-platform/',
    tableColumns: [],
  },
  /** Good Manufactoring Practice */
  '01fss66jb4phkwfvren3wkwhd1': {
    imgUrl: '/images/gmp.png',
    website:
      'https://www.ema.europa.eu/en/human-regulatory/research-development/compliance/good-manufacturing-practice',
    linkedin: 'https://www.linkedin.com/company/european-medicines-agency/',
    tableColumns: [],
  },
  /** Hazard Analysis Critical Control Point (HACCP) */
  '01fsvbf4zf13ae73szh7wcqtgs': {
    imgUrl: '/images/haccp.jpg',
    website:
      'https://www.fda.gov/food/guidance-regulation-food-and-dietary-supplements/hazard-analysis-critical-control-point-haccp#:~:text=HACCP%20is%20a%20management%20system,consumption%20of%20the%20finished%20product.',
    linkedin: 'https://www.linkedin.com/company/fda/',
    tableColumns: [],
  },
  /** ISO 22000 - Food Safety Systems Certification (FSSC) */
  '01fqbdr5jmj6jcw7bd4a9zyhq8': {
    imgUrl: '/images/fssc_22000_logo.png',
    website: 'https://www.fssc22000.com/',
    linkedin: 'https://www.linkedin.com/company/fssc-22000/',
    tableColumns: [
      {
        name: 'Products',
        keys: ['FCC'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Location',
        keys: ['City'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  // ISO 9001
  '01fssc9mr1pane60qvp77aregf': {
    imgUrl: '/images/iso.png',
    website: 'https://www.iso.org/iso-9001-quality-management.html',
    tableColumns: [],
  },
  // ISO 14001 - Environmental Management Systems (EMS)
  '01fx2eph9t8thfcv9fqg4n4f9s': {
    imgUrl: '/images/iso.png',
    website: 'https://www.iso.org/iso-14001-environmental-management.html',
    tableColumns: [],
  },
  // ISO 14040/44 - Life cycle assessment
  '01g7esvgv8trhndxs7aaa050j2': {
    imgUrl: '/images/iso.png',
    website: 'https://www.iso.org/standard/37456.html',
    tableColumns: [],
  },
  // ISO 14064-3
  '01fw62870wfjghzmphbvat0hnd': {
    imgUrl: '/images/iso.png',
    website: 'https://www.iso.org/standard/66455.html',
    tableColumns: [],
  },
  // ISO 26000 - Social Responsibility
  '01fx0ghq9hm2r6y6zyw093nvam': {
    imgUrl: '/images/iso.png',
    website: 'https://www.iso.org/iso-26000-social-responsibility.html',
    tableColumns: [],
  },
  // ISO 45001 - Occupational Health and Safety Management
  '01fxmtwc9vh2nqm7chfrxxy9ft': {
    imgUrl: '/images/iso.png',
    website: 'https://www.iso.org/iso-45001-occupational-health-and-safety.html',
    tableColumns: [],
  },
  // ISO 13485:2016 - Medical devices - Quality management systems - Requirements for regulatory purposes
  '01fxwgyjkhsqmwnhb16m1xk9wd': {
    imgUrl: '/images/iso.png',
    website: 'https://www.iso.org/standard/59752.html',
    tableColumns: [],
  },
  // ISO 37001 - Anti-Bribery Management System
  '01fzwnzxe920xefqzx23j7x5jp': {
    imgUrl: '/images/iso.png',
    website: 'https://www.iso.org/iso-37001-anti-bribery-management.html',
    tableColumns: [],
  },
  // ISO 14065 - Biocontamination control
  '01g87z7h04rjcwdenq2tc019ah': {
    imgUrl: '/images/iso.png',
    website:
      'https://www.en-standard.eu/din-en-14065-textiles-laundry-processed-textiles-biocontamination-control-system/',
    tableColumns: [],
  },
  /** Programme for Endorsement of Forest Certification (PEFC) */
  '01fsp7f55bx7em1dbazr6akd9a': {
    imgUrl: '/images/pefc.png',
    website: 'https://www.pefc.org/',
    linkedin: 'https://www.linkedin.com/company/pefc-international/',
    tableColumns: [
      {
        name: 'Certification type',
        keys: ['Type'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** Programme for Endorsement of Forest Certification (PEFC) - Chain of Custody of Forest and Tree Based Products */
  '01fxma1trj6n51km924s2dat6v': {
    imgUrl: '/images/pefc.png',
    website: 'https://www.pefc.org/',
    linkedin: 'https://www.linkedin.com/company/pefc-international/',
    tableColumns: [
      {
        name: 'Certification type',
        keys: ['Type'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** Public Benefit Corporation */
  '01frt1j44162wpeyfjjqrwghec': {
    imgUrl: '/images/b_corporations_logo.png',
    website: 'https://benefitcorp.net/',
    tableColumns: [
      {
        name: 'Location',
        keys: ['City'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** Quality Assurance International (QAI) */
  '01fs6x4fyep9w0afv5gkr7bb44': {
    imgUrl: '/images/qai.jpg',
    website: 'https://www.qai-inc.com/',
    linkedin: 'https://www.linkedin.com/company/qai-inc/',
    tableColumns: [
      {
        name: 'Certification type',
        keys: ['Certification Type'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** Rainforest Alliance - Farms */
  '01fq4435an6evfgc6nnwnrsnfz': {
    imgUrl: '/images/rainforest_alliance.png',
    website: 'https://www.rainforest-alliance.org/',
    linkedin: 'https://www.linkedin.com/company/rainforest-alliance/',
    tableColumns: [
      {
        name: 'Crop',
        keys: ['Crop'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** Rainforest Alliance - CoC */
  '01fq4f9xv2ssdbzagt2qse89ba': {
    imgUrl: '/images/rainforest_alliance.png',
    website: 'https://www.rainforest-alliance.org/',
    linkedin: 'https://www.linkedin.com/company/rainforest-alliance/',
    tableColumns: [
      {
        name: 'Last Updated',
        keys: ['date_extracted'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** Roundtable on Sustainable Palm Oil (RSPO) - Supply Chain */
  '01fqc37cbr6mkcsn5hv0qs8s9y': {
    imgUrl: '/images/rspo_logo.png',
    website: 'https://rspo.org/',
    linkedin: 'https://www.linkedin.com/company/roundtable-on-sustainable-palm-oil-rspo/',
    tableColumns: [
      {
        name: 'Validity period',
        keys: ['license start date', 'license expiry date'],
        content: (values: string[]) => (values.length >= 1 ? `${values[0]} - ${values[1]}` : ''),
      },
      {
        name: 'Location',
        keys: ['Certified Unit / Facility', 'company_address'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** Roundtable on Sustainable Palm Oil (RSPO) - Principles and criteria */
  '01fq6jhs97h8b424vrdf61nqph': {
    imgUrl: '/images/rspo_logo.png',
    website: 'https://rspo.org/',
    linkedin: 'https://www.linkedin.com/company/roundtable-on-sustainable-palm-oil-rspo/',
    tableColumns: [
      {
        name: 'Location',
        keys: ['Certified Unit / Facility'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** Roundtable on Sustainable Palm Oil (RSPO) - Members */
  '01fw3y53492fnm2xp6k86nk5sy': {
    imgUrl: '/images/rspo_logo.png',
    website: 'https://rspo.org/',
    linkedin: 'https://www.linkedin.com/company/roundtable-on-sustainable-palm-oil-rspo/',
    tableColumns: [
      {
        name: 'Member Since',
        keys: ['Member since'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** Supplier Ethical Data Exchange (SEDEX) */
  '01fwrftyesgqty1xqzka341pr6': {
    imgUrl: '/images/sedex.png',
    website: 'https://www.sedex.com/',
    linkedin: 'https://www.linkedin.com/company/sedex/',
  },
  /** Supplier Ethical Data Exchange (SEDEX) Smeta (4-Pillar) */
  '01g7d4qv3c7nkbtazm29cm3v1s': {
    imgUrl: '/images/sedex.png',
    website: 'https://www.sedex.com/',
    linkedin: 'https://www.linkedin.com/company/sedex/',
  },
  /** Sustainalytics - ESG Risk Ratings */
  '01fs27rpgknhddy9j0ghgw4xme': {
    imgUrl: '/images/sustainalytics.png',
    website: 'https://www.sustainalytics.com/',
    linkedin: 'https://www.linkedin.com/company/sustainalytics/',
    tableColumns: [
      {
        name: 'Sector',
        keys: ['Industry'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'ESG Risk',
        keys: ['ESG Risk Rating (Threshold)'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** United Nations Global Compact (UNGC) */
  '01fqe0zyban3rtchtxb66nys8q': {
    imgUrl: '/images/unglobalcompact.png',
    linkedin: 'https://www.linkedin.com/company/united-nations-global-compact/',
    website: 'https://www.unglobalcompact.org/',
    tableColumns: [
      {
        name: 'Validity period',
        keys: ['Join on', 'next cop due on'],
        content: (values: string[]) => (values.length >= 1 ? `${values[0]} - ${values[1]}` : ''),
      },
      {
        name: 'Sector',
        keys: ['Sector', 'company_sector'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** UTZ - Supply Chain */
  '01fqettfzrptn9agfdrzjbjgm3': {
    imgUrl: '/images/utz.png',
    website: 'https://www.rainforest-alliance.org/utz/',
    linkedin: 'https://www.linkedin.com/company/utz-certified/',
    tableColumns: [
      {
        name: 'Certificate expiration date',
        keys: ['Certificate expiration date'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** UTZ - Producers */
  '01fqe0pddqefmf0rq95hp1jkky': {
    imgUrl: '/images/utz.png',
    website: 'https://www.rainforest-alliance.org/utz/',
    linkedin: 'https://www.linkedin.com/company/utz-certified/',
    tableColumns: [
      {
        name: 'Certificate expiration date',
        keys: ['Certificate expiration date'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  /** Vermont Organic Farmers */
  '01fs1sqja8yx7phc86k8p4z0r1': {
    imgUrl: '/images/vof.png',
    website: 'https://nofavt.org/vof',
    linkedin: 'https://www.linkedin.com/company/nofavt/',
    tableColumns: [
      {
        name: 'Products',
        keys: ['Products'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Location',
        keys: ['State'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  // British Retail Consortium Global Standards (BRCGS)
  '01fpt6nxjzy9m6v7qfa3zse60x': brcgs,
  '01ftdbx7ety6ksyak59mntg2sz': brcgs,
  '01ft8f2ynmxqjbz5ksjq7x9acr': brcgs,
  '01ft8fe04qkex4efbcj9j8jg78': brcgs,
  '01g79z3t7r99xxv3m4q8qf1xm2': brcgs,
  '01g79wye72g8kq9dbhddgp523v': brcgs,
  '01g9h7yqk7jvdnbtpax8366d7k': brcgs,
  // Corporate Equality Index (CEI)
  '01ftg7yaj5r33gz24b58h9by6f': {
    imgUrl: '/images/corporate-equity-index-logo.png',
    website: 'https://www.hrc.org/resources/corporate-equality-index',
    linkedin: 'https://www.linkedin.com/company/human-rights-campaign/',
    tableColumns: [
      {
        name: 'Location',
        keys: ['Company Headquartes'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Score',
        keys: ['Company Score'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  // Non-GMO Project
  '01fqbw8g33jzy4pd4h8r1v76be': {
    imgUrl: '/images/non_gmo_project_logo.png',
    website: 'https://www.nongmoproject.org/',
    linkedin: 'https://www.linkedin.com/company/nongmoproject/',
    tableColumns: [],
  },
  // Principles for Responsible Investment (PRI)
  '01fttrtpvr78dz65m59x69503s': {
    imgUrl: '/images/principles_for_responsible_investments.png',
    website: 'https://www.unpri.org/',
    linkedin: 'https://www.linkedin.com/company/the-pri/',
    tableColumns: [
      {
        name: 'Signatory Category',
        keys: ['Signatory Category'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Signature Date',
        keys: ['Signature Date'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  // Safe Quality Food
  '01ftdh2rxz6wwk027yqgvytbhz': {
    imgUrl: '/images/sqf-logo.png',
    website: 'https://www.sqfi.com/',
    linkedin: 'https://www.linkedin.com/company/sqf-institute',
    tableColumns: [
      {
        name: 'Products',
        keys: ['Products'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Sector',
        keys: ['Food Sector Categories'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  // Science Based Targets Initiative (SBTi)
  '01fv2fgw5612s911g3m2f1hydv': {
    imgUrl: '/images/sciencebasedinitiative.jpg',
    website: 'https://sciencebasedtargets.org/',
    linkedin: 'https://www.linkedin.com/company/science-based-targets/',
    tableColumns: [],
  },
  // The Common Code for the Coffee Community Association (4C)
  '01fpt5pvkjpynnn6297rxffs0q': {
    imgUrl: '/images/4c_logo.png',
    website: 'https://www.4c-services.org/',
    linkedin: 'https://www.linkedin.com/company/4c-services/',
    tableColumns: [],
  },
  // The Organic Council of Ontario (OCO)
  '01fqc1vgxjvkaqw00xy05ep8x3': {
    imgUrl: '/images/oco_logo.png',
    website: 'https://www.organiccouncil.ca/',
    linkedin: 'https://www.linkedin.com/company/organic-council-of-ontario/',
    tableColumns: [],
  },
  // USDA Organic
  '01frj95tja9xj0ff63c1m7ytsa': {
    imgUrl: '/images/usda_organic.png',
    website: 'https://www.usda.gov/topics/organic',
    linkedin: 'https://www.linkedin.com/company/usda-ams-nop/',
    tableColumns: [],
  },
  // WEP
  '01ftr7a74gcqbxja5670pv2hzg': {
    imgUrl: '/images/wep.png',
    website: 'https://www.weps.org/',
    linkedin: 'https://www.linkedin.com/showcase/weprinciples/',
    tableColumns: [
      {
        name: 'Sector',
        keys: ['Industry'],
        content: (values: string[]) => (values.length > 0 ? `${values[0]} - ...` : ''),
      },
    ],
  },
  // S&P Dow Jones Sustainability Index
  '01fvhfkt077vkx1kbfmxshj37e': {
    imgUrl: '/images/Dow_Jones_Sustainability_Index.png',
    website: 'https://www.spglobal.com/esg/solutions/data-intelligence-esg-scores',
    linkedin: 'https://www.linkedin.com/company/s&p-dow-jones-indices/',
    tableColumns: [
      {
        name: 'ESG Score',
        keys: ['ESG Score', 'esg_score'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  // Great Place to Work Certificate
  '01fvyh7nthm5jc8zg8pqn08awc': {
    imgUrl: '/images/Great_Place_to_Work.png',
    website: 'https://www.greatplacetowork.com/solutions/certification',
    linkedin: 'https://www.linkedin.com/company/great-place-to-work-us/',
    tableColumns: [
      {
        name: 'Score',
        keys: ['Employees say this is a great place to work'],
        content: (values: string[]) => (values.length > 0 ? `${values[0]}` : ''),
      },
    ],
  },
  // Green-e Certified - Energy
  '01fxqkxkypedjes09h6rjzthcp': {
    imgUrl: '/images/Green-e_Certified.png',
    website: 'https://www.green-e.org/',
    linkedin: 'https://www.linkedin.com/company/center-for-resource-solutions/',
  },
  // Global Reporting Initiative (GRI)
  '01fwk3192npff3rpb7pbp73rn9': {
    imgUrl: '/images/GRI.png',
    website: 'https://www.globalreporting.org/',
    linkedin: 'https://www.linkedin.com/company/global-reporting-initiative-gri/',
    tableColumns: [],
  },
  // IATF 16949: Established quality management in the automotive industry (previously ISO 16949)
  '01fxqkyz8k9jjpbz4gdqd10msj': {
    imgUrl: '/images/IATF_16949_2016.png',
    website: 'https://www.iatfglobaloversight.org/iatf-169492016/about/',
    tableColumns: [],
  },
  // Just Capital Ranking
  '01fxqc370r09zv6c0grgk5sknz': {
    imgUrl: '/images/Just_Capital_Rankings.png',
    website: 'https://justcapital.com/',
    linkedin: 'https://www.linkedin.com/company/just-capital/',
    tableColumns: [
      {
        name: 'Sector',
        keys: ['Industry'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Score',
        keys: ['Overall Score'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  // Morningstar - Sustainability Rating
  '01fxqp300w6dsrmp5frn837g3a': {
    imgUrl: '/images/Morningstar.png',
    website:
      'https://www.morningstar.com/articles/957266/the-morningstar-sustainability-rating-explained',
    linkedin: 'https://www.linkedin.com/company/morningstar/',
  },
  // MSCI ESG Rating
  '01fwrk8nfvdeqrc5nwegzss0ja': {
    imgUrl: '/images/MSCI_ESG_Rating.png',
    website: 'https://www.msci.com/our-solutions/esg-investing/esg-ratings',
    linkedin: 'https://www.linkedin.com/company/msci-inc/',
    tableColumns: [
      {
        name: 'MSCI ESG rating',
        keys: ['Rating', 'MSCI ESG rating'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  // Responsible Business Alliance (RBA)
  '01fxqebcaz68v6v67qv7tvcn9d': {
    imgUrl: '/images/Responsible_Business_Alliance.png',
    website: 'https://www.responsiblebusiness.org/',
    linkedin: 'https://www.linkedin.com/company/responsible-business-alliance/',
  },
  // Top 100 Women-Led Businesses in Massachusetts
  '01fxsyg90gh8rnbf6yf712srxd': {
    imgUrl: '/images/Top_100_Women-Led_Businesses.png',
    website:
      'https://commonwealthinstitute.org/programs-events/top-100-women-led-businesses-in-massachusetts/',
    linkedin: 'https://www.linkedin.com/company/the-commonwealth-institute/',
    tableColumns: [
      {
        name: 'Business Type',
        keys: ['Business type'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Location',
        keys: ['Location of Massachusetts headquarters'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Ranking',
        keys: ['Rank'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  // We Mean Business Coalition
  '01fxqmz2akccej74q62me2t4vc': {
    imgUrl: '/images/We_Mean_Business_Coalition.png',
    website: 'https://www.wemeanbusinesscoalition.org/',
    linkedin: 'https://www.linkedin.com/company/wemeanbusiness/',
  },
  // Comparably - Best Company for Women
  '01fxq2fppbq7myztbdrvyk93c0': {
    imgUrl: '/images/Comparably_Best_Company_for_Women.png',
    website: 'https://www.comparably.com/news/best-companies-for-women-2021/',
    linkedin: 'https://www.linkedin.com/company/comparably/about/',
    tableColumns: [
      {
        name: 'Ranking Category',
        keys: ['Ranking Category'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Ranking',
        keys: ['Ranking'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  // CSRHub
  '01fwnvw99c86az4762tqa000t1': {
    imgUrl: '/images/CSRHub.png',
    website: 'https://www.csrhub.com/',
    linkedin: 'https://www.linkedin.com/company/csrhub-llc/',
    tableColumns: [
      {
        name: 'Score',
        keys: ['Overall Rating'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  // Institutional Shareholder (ISS) Governance Quality Scores
  '01fxw5db1kmjnfp8jftymyf76k': {
    imgUrl: '/images/ISS.png',
    website: 'https://www.issgovernance.com/esg/ratings/governance-qualityscore/',
    linkedin: 'https://www.linkedin.com/company/iss-institutional-shareholder-services/',
  },
  // Restriction of Hazardous Substances Directive (RoHS)
  '01fxmvfh4qx0et3a3qrhwbwgcb': {
    imgUrl: '/images/RoHS_2.png',
    website: 'https://ec.europa.eu/environment/topics/waste-and-recycling/rohs-directive_en',
  },
  // Stonewall’s Diversity Champions Programme
  '01fxt4g77tafjty6mvvx7d65mf': {
    imgUrl: '/images/Stonewall_Diversity_Champion.png',
    website: 'https://www.stonewall.org.uk/diversity-champions-programme',
    linkedin: 'https://www.linkedin.com/company/stonewall_2/about/',
  },
  // Trace Certified
  '01fxqnf3y0kh0gbg1cj1sdh3hm': {
    imgUrl: '/images/Trace_Certified.png',
    website: 'https://www.traceinternational.org/',
    linkedin: 'https://www.linkedin.com/company/trace-international-inc-/',
  },
  // TCarbon Footprint - CO2e Assessed
  '01fxwf4ctd1snskvkyv45fecx2': {
    imgUrl: '/images/carbon_footprint.png',
    website: 'https://www.carbonfootprint.com',
    linkedin: 'https://www.linkedin.com/company/carbon-footprint-ltd/',
  },
  // amfori
  '01gjd83j382m38k2esqf7ef88b': {
    imgUrl: '/images/amfori.png',
    website: 'https://www.amfori.org',
    linkedin: 'https://www.linkedin.com/company/amfori-trade-with-purpose/',
  },
  // amfori Business Environmental Performance Initiative (BEPI)
  '01fxq37s4n2n6yjwyqtbz6n4x0': {
    imgUrl: '/images/amfori.png',
    website: 'https://www.amfori.org/content/amfori-bepi',
    linkedin: 'https://www.linkedin.com/company/amfori-trade-with-purpose/',
  },
  // amfori Business Social Compliance Initiative (BSCI)
  '01fxj9241kzmtqy3nxf200yjqx': {
    imgUrl: '/images/amfori.png',
    website: 'https://www.amfori.org/content/amfori-bsci',
    linkedin: 'https://www.linkedin.com/company/amfori-trade-with-purpose/',
    tableColumns: [
      {
        name: 'Submission Date',
        keys: ['Submission Date', 'submission_date'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Overall rating',
        keys: ['Overall rating', 'overall_rating'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  // Better Cotton Initiative - Members
  '01fxa6x4apr5zhnxnwn07ekt2m': {
    imgUrl: '/images/Better_Cotton_Initiative.png',
    website: 'https://bettercotton.org/',
    linkedin: 'https://www.linkedin.com/company/better-cotton-initiative/about/',
  },
  // Better Cotton Initiative - Suppliers
  '01fxafa4srkg076z2w8gryx0mv': {
    imgUrl: '/images/Better_Cotton_Initiative.png',
    website: 'https://bettercotton.org/',
    linkedin: 'https://www.linkedin.com/company/better-cotton-initiative/about/',
  },
  // Customs Trade Partnership Against Terrorism (CTPAT)
  '01fxq0ejthb65jgw3m2rz6zdhy': {
    imgUrl: '/images/CTPAT.png',
    website: 'https://www.cbp.gov/border-security/ports-entry/cargo-security/ctpat',
  },
  // Global Organic Textile Standard (GOTS)
  '01fxhwzz97j2ybbm3fssm6tt6m': {
    imgUrl: '/images/Global_Organic_Textile_Standard.png',
    website: 'https://global-standard.org/',
    linkedin: 'https://www.linkedin.com/company/global-organic-textile-standard/',
  },
  // Global Recycled Standard (GRS)
  '01fvyapcafgn19ebrkedb2h6ey': {
    imgUrl: '/images/Global_Recycled_Standard.png',
    website:
      'https://textileexchange.org/standards/recycled-claim-standard-global-recycled-standard/',
    linkedin: 'https://www.linkedin.com/company/textile-exchange/',
  },
  // Recycled Claim Standard (RCS)
  '01fxq1hgj82sacsa2585t11ch8': {
    imgUrl: '/images/Recycled_Claim_Standard.png',
    website:
      'https://textileexchange.org/standards/recycled-claim-standard-global-recycled-standard/',
    linkedin: 'https://www.linkedin.com/company/textile-exchange/',
  },
  // Oeko-Tex Standard 100
  '01fxmrenzqgnjdfvhm7r7378jb': {
    imgUrl: '/images/Oeko_Tex_Standard_100.png',
    website: 'https://www.oeko-tex.com/en/our-standards/standard-100-by-oeko-tex',
    linkedin: 'https://www.linkedin.com/company/oeko-tex/',
  },
  // Walmart Audit
  '01fxq3wjghea9k2r03a1p4cay2': {
    imgUrl: '/images/Walmart.png',
    website: 'https://corporate.walmart.com/suppliers/requirements/audits-and-certifications',
  },
  // Bee Friendly
  '01fwzsy8q7kqkgm07whr2681wy': {
    imgUrl: '/images/beefriendly.png',
    website: 'https://www.certifiedbeefriendly.org/en/',
    linkedin: 'https://www.linkedin.com/company/label-bee-friendly/',
  },
  // Cocoa Horizons
  '01fwgrb1qx4dj96cvhfy009ymd': {
    imgUrl: '/images/Cocoa_Horizons_2.png',
    website: 'https://www.cocoahorizons.org/',
  },
  // Ecocert Cosmos
  '01fwgxbaghms2njh9yemyfqr5z': {
    imgUrl: '/images/ecocert_cosmos.png',
    website: 'https://www.ecocert.com/en/certification-detail/natural-and-organic-cosmetics-cosmos',
    linkedin: 'https://www.linkedin.com/company/groupeecocert/',
  },
  // ENERGY STAR Certified
  '01fyrty4f9j1r6em5gk4r2zh7p': {
    imgUrl: '/images/Energy_Star_Certified.png',
    website: 'https://www.energystar.gov/about',
    linkedin: 'https://www.linkedin.com/company/groupeecocert/',
  },
  // The Food and Land Use Coalition (FOLU)
  '01fwh0hcgcp5nq2sqjwte2ecf4': {
    imgUrl: '/images/FOLU.png',
    website: 'https://www.foodandlandusecoalition.org/',
    linkedin: 'https://www.linkedin.com/company/foodandlandusecoalition/',
  },
  // International Cocoa Initiative
  '01fwp19d1pr2adyyk75ckvy2ya': {
    imgUrl: '/images/International_Cocoa_initiative.png',
    website: 'https://www.cocoainitiative.org/',
    linkedin: 'https://www.linkedin.com/company/international-cocoa-initiative/',
  },
  // LandScale
  '01fwh1mak08tdx3eddy2tr6mh7': {
    imgUrl: '/images/Landscale.png',
    website: 'https://www.landscale.org/',
    linkedin: 'https://www.linkedin.com/company/landscale/',
  },
  // National Minority Supplier Development Council (NMSDC)
  '01fwzyx26e6pgqgv1ch3xey4yn': {
    imgUrl: '/images/NMSDC_2.png',
    website: 'https://nmsdc.org/',
    linkedin: 'https://www.linkedin.com/company/national-minority-supplier-development-council/',
  },
  // GreenCircle Certified - Certified Environmental Facts (CEF)
  '01fvcxdr14g4z4evpmmz1gescx': {
    imgUrl: '/images/GreenCircle_Certified.png',
    website: 'https://www.greencirclecertified.com/',
    linkedin: 'https://www.linkedin.com/company/greencircle-certified-llc-/',
  },
  // Palm Done Right
  '01fwnfhremzaawv14je85b8m6b': {
    imgUrl: '/images/palm_done_right.png',
    website: 'https://palmdoneright.com/',
    linkedin: 'https://www.linkedin.com/showcase/palm-done-right-pdr/',
  },
  // Principles for Sustainable Insurance (PSI)
  '01fxqae9wkk2s0wzg7m4dy2p4m': {
    imgUrl: '/images/PSI.png',
    website: 'https://www.unepfi.org/psi/',
    linkedin:
      'https://www.linkedin.com/company/united-nations-environment-programme-finance-initiative/',
  },
  // Skal Bio Controle Organic Certification
  '01fx0fpye6txf379cwtcfryq44': {
    imgUrl: '/images/skal.png',
    website: 'https://www.skal.nl/',
    linkedin: 'https://www.linkedin.com/company/skal-biocontrole/',
  },
  // Top Workplaces USA
  '01fyy0h4b223w8bg0mbcwcv4sk': {
    imgUrl: '/images/Top_Workplaces.png',
    website: 'https://topworkplaces.com/',
    linkedin: 'https://www.linkedin.com/company/top-workplaces/',
    tableColumns: [
      {
        name: 'Year',
        keys: ['Award Year'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Location',
        keys: ['Location'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  // United Plant Savers (UPS) - Herbal Business Members
  '01fw6n1knkt95wy6z488ey0bfr': {
    imgUrl: '/images/UPS.png',
    website: 'https://unitedplantsavers.org/become-an-herbal-business-member/',
    linkedin: 'https://www.linkedin.com/company/united-plant-savers/about/',
  },
  // WEConnect International - Buyer Membership
  '01fwh04hpz9zw0mvrdq2q3pcmy': {
    imgUrl: '/images/weconnect.png',
    website: 'https://weconnectinternational.org/',
    linkedin: 'https://www.linkedin.com/company/weconnectinternational/',
  },
  // Women-Owned Logo
  '01fxa5fk0sgw9w51fzayf61m01': {
    imgUrl: '/images/women_owned.png',
    website: 'https://www.womenownedlogo.com/',
  },
  // World Cocoa Foundation (WCF)
  '01fwp2tbvem1b4p0cj360ehm98': {
    imgUrl: '/images/PSI.png',
    website: 'https://www.worldcocoafoundation.org/',
    linkedin: 'https://www.linkedin.com/company/world-cocoa-foundation/',
  },
  // Zero Waste to Landfill
  '01fx2vsc03wrp5tvjgm8zrsap7': {
    imgUrl: '/images/zero_waste.png',
  },
  // bioC
  '01fx038ehh446tk5htj19t26zn': {
    imgUrl: '/images/bioc.png',
    website: 'https://www.bioc.info/',
  },
  // Champions 12.3
  '01fwp6t6tb0136dwrme4gzb65s': {
    imgUrl: '/images/champions_123.png',
    website: 'https://champions123.org/',
  },
  // Cool Farm Alliance
  '01fw19q14eqda7yhtw4ym9e10y': {
    imgUrl: '/images/Cool_Farm_Alliance.png',
    website: 'https://coolfarmtool.org/cool-farm-alliance/',
    linkedin: 'https://www.linkedin.com/company/cool-farm-alliance/',
  },
  // California Water Action Collaborative (CWAC)
  '01fwr2wvrjm7x8jhkdntkfmg19': {
    imgUrl: '/images/CWAC.png',
    website: 'https://cawateraction.org/',
  },
  // Innovation Center for U.S. Dairy - Dairy Sustainability Alliance
  '01fwgptnbr3e5cqt2416ggk41j': {
    imgUrl: '/images/innovation_center_for_US.jpg',
    website: 'https://www.usdairy.com/about-us/innovation-center/sustainability-alliance',
  },
  // Sustainable Coconut and Coconut Oil Roundtable
  '01fwnexter565y1c1n3wfwzk65': {
    imgUrl: '/images/sustainable_coconut.png',
    website: 'https://www.sustainablecoconutcharter.com/',
  },
  '01fwzvtt2xc46xbst85npyn6an': {
    imgUrl: '/images/wbenc.png',
    website: 'https://www.wbenc.org/certification/',
    linkedin: 'https://www.linkedin.com/company/wbenc/',
  },
  // The Women's Business Enterprise National Council (WBENC) - Membership
  '01j5d7460m6e1f14fa52y4x038': {
    imgUrl: '/images/wbenc.png',
    website: 'https://www.wbenc.org/',
    linkedin: 'https://www.linkedin.com/company/wbenc/',
  },
  // Agriculture Biologique (AB)
  '01g2vn636wjrgb47t3n21pxdat': {
    imgUrl: '/images/Agriculture_Biologique_(AB).png',
  },
  // Alliance for Water Stewardship (AWS)
  '01g2w0wzyhtq24nzrssw0cpxcz': {
    imgUrl: '/images/Alliance_for_Water_Stewardship_(AWS).png',
    website: 'https://a4ws.org/',
    linkedin: 'https://www.linkedin.com/company/a4ws/',
  },
  // Carbon Trust - Standard for Supply Chain
  '01g2w671ttczv018v2tbs3tkr0': {
    imgUrl: '/images/Carbon_Trust.png',
    website:
      'https://www.carbontrust.com/what-we-do/assurance-and-certification/the-carbon-trust-standard',
    linkedin: 'https://www.linkedin.com/company/the-carbon-trust/',
  },
  // Carbon Trust - Triple Standard
  '01g2w6se2zwfbt8tqxbs1gf6gt': {
    imgUrl: '/images/Carbon_Trust.png',
    website:
      'https://www.carbontrust.com/what-we-do/assurance-and-certification/the-carbon-trust-standard',
    linkedin: 'https://www.linkedin.com/company/the-carbon-trust/',
  },
  // CarbonNeutral (Natural Capital Partners)
  '01g2sq148ngzz0pfwrkgn8vpdx': {
    imgUrl: '/images/carbon_neutral.png',
    website: 'https://www.carbonneutral.com/',
    linkedin: 'https://www.linkedin.com/company/natural-capital-partners/',
  },
  // European Water Stewardship (EWS)
  '01g2vt2nr5rk6b1mhp2vxfzvm7': {
    imgUrl: '/images/European_Water_Stewardship_(EWS).png',
    website: 'http://www.ewp.eu/',
    linkedin: 'https://www.linkedin.com/company/european-water-stewardship/about/',
  },
  // Foodsteps
  '01g2vv0q2whjzf7q3qphcjdwef': {
    imgUrl: '/images/foodsteps.png',
    website: 'https://foodsteps.earth/',
    linkedin: 'https://www.linkedin.com/company/foodsteps-sustainability/',
  },
  // Friend of the Sea - Sustainable Aquaculture Certification
  '01g36dh407jyvxfrksavc8yv7r': {
    imgUrl: '/images/friend_of_the_sea.png',
    website: 'https://friendofthesea.org/',
    linkedin: 'https://www.linkedin.com/company/friend-of-the-sea/',
  },
  // Friend of the Sea - Sustainable Fisheries and Fleets Certification
  '01g2vneman4761vkr8dpsaakn7': {
    imgUrl: '/images/friend_of_the_sea.png',
    website: 'https://friendofthesea.org/',
    linkedin: 'https://www.linkedin.com/company/friend-of-the-sea/',
  },
  // International Featured Standards (IFS) - Food 7
  '01g2m4ys5y2mpbyp1x5b61w1en': {
    imgUrl: '/images/ifs.png',
    website: 'https://www.ifs-certification.com/index.php/en/standards/4128-ifs-food-standard-en',
    linkedin: 'https://www.linkedin.com/company/ifs-international-featured-standards/',
  },
  // International Featured Standards (IFS) - Logistics
  '01ga92b4snbsbf8eh49qwzn8z9': {
    imgUrl: '/images/ifs.png',
    website: 'https://www.ifs-certification.com/index.php/en/standards/265-ifs-logistics-en',
    linkedin: 'https://www.linkedin.com/company/ifs-international-featured-standards/',
  },
  // KZR INiG System
  '01g2sfjzfctmav5gkztcxcsm6m': {
    imgUrl: '/images/KZR_INiG_System.png',
    website: 'http://www.kzr.inig.eu/en/',
    linkedin: 'https://www.linkedin.com/company/kzr-inig-system/about/',
  },
  // Label Rouge (Red Label)
  '01g2s90xv2kb1bd2xbm6ee4be6': {
    imgUrl: '/images/Label_Rouge.png',
    website:
      'https://www.inao.gouv.fr/eng/Official-signs-identifying-quality-and-origin/Label-Rouge-Red-Label',
  },
  // Marine Stewardship Council (MSC)
  '01g7yha3pv25wjbzvd4vgax197': {
    imgUrl: '/images/MSC.png',
    website: 'https://www.msc.org/',
    linkedin: 'https://www.linkedin.com/company/marine-stewardship-council/',
  },
  // Origin Green
  '01g2w20605fmf6763b1rgx1wh8': {
    imgUrl: '/images/origin_green.png',
    website: 'https://www.origingreen.ie/',
    linkedin:
      'https://www.linkedin.com/showcase/origin-green-ireland-a-world-leader-in-sustainable-food-&-drink/',
  },
  // QS certification mark
  '01g2smfj5rp92446gjb42f9k07': {
    imgUrl: '/images/QS_Scheme.png',
    website: 'https://www.q-s.de/en/',
    linkedin: 'https://www.linkedin.com/showcase/the-qs-scheme/',
  },
  // QS certification mark - Feed material producers
  '01g7d5qh6m20j1whz009pgb2yz': {
    imgUrl: '/images/QS_Scheme.png',
    website: 'https://www.q-s.de/en/',
    linkedin: 'https://www.linkedin.com/showcase/the-qs-scheme/',
  },
  // Red Tractor
  '01g2stm9r47p6zdcnmnqmvzzcc': {
    imgUrl: '/images/red_tractor.png',
    website: 'https://redtractor.org.uk/',
    linkedin: 'https://www.linkedin.com/company/red-tractor-assurance/',
  },
  // Red Tractor - Assurance for Meat and Poultry Processing
  '01g7cgpgb1pkj160d4mfgk4r3y': {
    imgUrl: '/images/red_tractor.png',
    website: 'https://redtractor.org.uk/',
    linkedin: 'https://www.linkedin.com/company/red-tractor-assurance/',
  },
  // Red Tractor - Chicken Standards
  '01g7ckz68xxy2wm25hh2a0pv9h': {
    imgUrl: '/images/red_tractor.png',
    website: 'https://redtractor.org.uk/',
    linkedin: 'https://www.linkedin.com/company/red-tractor-assurance/',
  },
  // Red Tractor - Dairy Standard
  '01g7cm0m2vs5gqn8qjyk5fsws1': {
    imgUrl: '/images/red_tractor.png',
    website: 'https://redtractor.org.uk/',
    linkedin: 'https://www.linkedin.com/company/red-tractor-assurance/',
  },
  // Red Tractor - Pork / Beef & Lamb Standard
  '01g7cfdrfm7vxxy3d7bwz3cq6t': {
    imgUrl: '/images/red_tractor.png',
    website: 'https://redtractor.org.uk/',
    linkedin: 'https://www.linkedin.com/company/red-tractor-assurance/',
  },
  // Safe and Local Supplier Approval (SALSA)
  '01g2w2m08w9h79m5w11qfv97es': {
    imgUrl: '/images/salsa.png',
    website: 'https://www.salsafood.co.uk/',
    linkedin: 'https://www.linkedin.com/company/salsa-technologies-ltd/',
  },
  // Soil Association Certification
  '01g2srk0jrfhkmh4qs3c0vw36w': {
    imgUrl: '/images/Soil_Association_Certification.png',
    website: 'https://www.soilassociation.org/certification/forestry/',
    linkedin: 'https://www.linkedin.com/showcase/soil-association-certification-forestry/',
  },
  // UK Gender Pay Gap
  '01g2ph9d42kaefp3h6fbfxsdej': {
    imgUrl: '/images/UK_Gender_Pay_Gap.png',
    website:
      'https://www.ons.gov.uk/employmentandlabourmarket/peopleinwork/earningsandworkinghours/bulletins/genderpaygapintheuk/2021',
    tableColumns: [
      {
        name: 'Median Pay Gap %',
        keys: ['DiffMedianHourlyPercent'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Submission Date',
        keys: ['DateSubmitted'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  // UK Modern Slavery Act
  '01g7ev1ekwkr9eevpprg131zy1': {
    imgUrl: '/images/msa15.png',
    website: 'https://www.legislation.gov.uk/ukpga/2015/30/contents/enacted',
  },
  // UK Bribery Act
  '01fyrwdks2ad07ras0pgybqaxv': {
    imgUrl: '/images/msa15.png',
    website: 'https://www.legislation.gov.uk/ukpga/2010/23/contents',
  },
  // UK Roundtable on Sustainable Soya
  '01g2wagh0rkfzew5p25v9dv9et': {
    imgUrl: '/images/UK_RTRS.png',
    website: 'https://www.efeca.com/the-uk-roundtable-on-sustainable-soya/',
  },
  // Verband Lebensmittel ohne Gentechnik (VLOG)
  '01g2s5z4y4rc4jakkmv6dbd7qk': {
    imgUrl: '/images/vlog.png',
    website: 'https://www.ohnegentechnik.org/en/',
    linkedin: 'https://www.linkedin.com/company/vlog---verband-lebensmittel-ohne-gentechnik/',
  },
  // Baltic Sea Action Group (BSAG) Commitment
  '01g29myf8d8qc1qkxf9059d591': {
    imgUrl: '/images/bsag.png',
    website: 'https://www.bsag.fi/en/',
    linkedin: 'https://www.linkedin.com/company/baltic-sea-action-group/',
  },
  // Blue Angel (Blauer Engel) - Products made from Recycled Plastics (DE-UZ 30a)
  '01g29sg9wd5rnqng173xprgqss': {
    imgUrl: '/images/blue-angel.png',
    website: 'https://www.blauer-engel.de/en',
    linkedin: 'https://www.linkedin.com/company/blauer-engel-uz/',
  },
  // EcoVadis
  '01fx2gxy6t5bvc8f91rb2wp8mv': {
    imgUrl: '/images/ecovadis.png',
    website: 'https://ecovadis.com/',
    linkedin: 'https://www.linkedin.com/company/ecovadis/',
    tableColumns: [
      {
        name: 'Score',
        keys: ['grade'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  // EuCertPlast
  '01g2pg3gmrwq9qdsjjx6b5esgq': {
    imgUrl: '/images/eucertplast.png',
    website: 'https://www.eucertplast.eu/',
  },
  // International Sustainability & Carbon Certification (ISCC)
  '01fvvvh71j54q5ev8b6yvwnktb': {
    imgUrl: '/images/iscc.png',
    website: 'https://www.iscc-system.org/',
    linkedin:
      'https://www.linkedin.com/company/international-sustainability-and-carbon-certification-iscc-system-gmbh/',
  },
  // ISO 50001 - Energy Management
  '01fye8zqjdywgmhc9h4gaks4am': {
    imgUrl: '/images/iso.png',
    website: 'https://www.iso.org/iso-50001-energy-management.html',
    tableColumns: [],
  },
  // AAALAC International
  '01fy9jkqjm1c8g79am26chameb': {
    imgUrl: '/images/aaalac.png',
    website: 'https://www.aaalac.org/accreditation-program/what-is-aaalac-accreditation/',
    linkedin: 'https://www.linkedin.com/company/aaalac-international/',
  },
  // Responsibly Data Bot 🤖
  '01g27bp4hffd95tkgc5hyasarq': {
    imgUrl: '/images/responsibly.png',
    tableColumns: [
      {
        name: 'Reporting Year',
        keys: ['Reporting Year'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  // Responsible Labor Initiative (RLI)
  '01g4bs3ycqwvh1h2ezpd0cgcmc': {
    imgUrl: '/images/RMI.png',
    website: 'https://www.responsiblelabor.org/',
    linkedin: 'https://www.linkedin.com/company/responsible-business-alliance/',
  },
  // Better Mining
  '01g4bqymdfnbbhsfg4zvmxj782': {
    imgUrl: '/images/bettermining.png',
    website: 'https://www.rcsglobal.com/bettermining/',
    linkedin: 'https://www.linkedin.com/company/rcsglobalgroup/',
  },
  // ISO 14064-1 - GHG Inventory
  '01g4bd6mwg3bkxp7e6w0bwrkhm': {
    imgUrl: '/images/iso.png',
    website: 'https://www.iso.org/standard/66453.html',
    tableColumns: [],
  },
  // Workplace Conditions Assessment (WCA)
  '01g3936fg2qcbm79gkczwtx155': {
    imgUrl: '/images/WCA.png',
    website: 'https://www.intertek.com/assurance/wca/',
  },
  // Responsible Wool Standard (RWS)
  '01g3b97w5w848qx5gks32nkpmm': {
    imgUrl: '/images/RWS.png',
    website: 'https://textileexchange.org/standards/responsible-wool/',
  },
  // Responsible Down Standard (RDS)
  '01g3b5tg89ck257bm0870p421w': {
    imgUrl: '/images/RDS.png',
    website: 'https://textileexchange.org/standards/responsible-down/',
  },
  // Organic Content Standard (OCS)
  '01g396kv45qjrmn8sr38dg048s': {
    imgUrl: '/images/OCS.png',
    website: 'https://textileexchange.org/standards/organic-content-standard/',
  },
  // Responsible Minerals Initiative (RMI)
  '01fyc7mghc6zk9jfxh3g1jv527': {
    imgUrl: '/images/RMI.png',
    website: 'https://www.responsiblemineralsinitiative.org/',
    linkedin: 'https://www.linkedin.com/company/responsible-business-alliance/',
  },
  // Registration, Evaluation, Authorization and Restriction of Chemicals (REACH)
  '01fye7mms3jmf0cczjskx0zv6f': {
    imgUrl: '/images/reach.png',
    website: 'https://echa.europa.eu/regulations/reach/legislation',
  },
  // LFGB Certification
  '01g4bpz4q5h93jr0ff7vrfy6r8': {
    imgUrl: '/images/lfgb.png',
    website: 'http://www.gesetze-im-internet.de/lfgb/',
  },
  // ISO 22716 - Cosmetics - Good Manufacturing Practices (GMP)
  '01g3twa3h32jpcd09dthq6a7aw': {
    imgUrl: '/images/iso.png',
    website: 'https://www.iso.org/standard/36437.html',
    tableColumns: [],
  },
  // ISO 46001 - Water efficiency management systems
  '01g3ty0rhnncxmr0hgk9vqg79k': {
    imgUrl: '/images/iso.png',
    website: 'https://www.iso.org/standard/68286.html',
    tableColumns: [],
  },
  // ISO 28000 - Security and resilience - Security management systems - Requirements
  '01g38pekmceg2s0e83q9fnwpyr': {
    imgUrl: '/images/iso.png',
    website: 'https://www.iso.org/standard/79612.html',
    tableColumns: [],
  },
  // ISO 14067 - Greenhouse gases - Carbon footprint of products
  '01gc8r099p7bpv77nrwz11byh6': {
    imgUrl: '/images/iso.png',
    website: 'https://www.iso.org/standard/71206.html',
    tableColumns: [],
  },
  // AöL - Organic Food Processors - Membership
  '01g3x7q37dsdfxvgvg40jja2d3': {
    imgUrl: '/images/AOEL.png',
    website: 'https://www.aoel.org/?lang=en',
  },
  // Climate Neutral Certified
  '01g48cajcgvc9mhsdkpsqbzyjv': {
    imgUrl: '/images/climate-neutral.png',
    website: 'https://www.climateneutral.org/',
    linkedin: 'https://www.linkedin.com/company/climate-neutral/',
  },
  // CO2-Neutral® label
  '01g48bg5qn79em1r1d5jmf659e': {
    imgUrl: '/images/co2neutral.png',
    website: 'https://www.co2-neutral-label.org/',
  },
  // Cradle to Cradle Certified
  '01g3vaad2g6g6x0a480grxq99q': {
    imgUrl: '/images/C2CCert.png',
    website: 'https://www.c2ccertified.org/',
    linkedin: 'https://www.linkedin.com/company/cradle-to-cradle-products-innovation-institute/',
  },
  // Demeter Certification
  '01g3x1fky8y3scx7axc4363wwm': {
    imgUrl: '/images/demeter.png',
    website: 'https://www.demeter.net/certification/',
    linkedin: 'https://www.linkedin.com/company/demeter-e-v/',
  },
  // ESG Finance Award Japan
  '01g3vbt23y6y9dxbq40skyy7j0': {
    imgUrl: '/images/esg_award_japan.png',
    website: 'https://www.env.go.jp/policy/award.kigyobumon.html',
  },
  // NATRUE - Standard
  '01g48fz0ppzkpnbwetrym73vsg': {
    imgUrl: '/images/natrue.png',
    website: 'https://www.natrue.org/our-standard/',
    linkedin: 'https://www.linkedin.com/company/natrue/',
  },
  // NATRUE - Membership
  '01g48fv3q0k4phkwe01ybdhjp9': {
    imgUrl: '/images/natrue.png',
    website: 'https://www.natrue.org/our-members/',
    linkedin: 'https://www.linkedin.com/company/natrue/',
  },
  // Nordic Swan Ecolabel
  '01g4chpb5jdg0gcg3dp5xrpxee': {
    imgUrl: '/images/nordic-swan.png',
    website: 'https://www.nordic-ecolabel.org/nordic-swan-ecolabel/',
    linkedin: '',
  },
  // PRS - Green Label
  '01g42ad9z4km45k9yyrcyjnse7': {
    imgUrl: '/images/PRS.png',
    website: 'https://www.prs-pooling.com/en',
    linkedin: 'https://www.linkedin.com/company/prs-pooling/',
  },
  // Responsible Care®
  '01g47qbejwy1ea61jar6y7rhr0': {
    imgUrl: '/images/Responsible-Care.png',
    website: 'https://cefic.org/responsible-care/',
    linkedin: 'https://www.linkedin.com/company/cefic-european-chemical-industry-council/',
  },
  // Singapore Green Labelling Scheme (SGLS)
  '01g49w3kk4f8hax92kwvhegebt': {
    imgUrl: '/images/singapore-green-label.png',
    website: 'https://sgls.sec.org.sg/cms.php?cms_id=3',
  },
  // Social Accountability International (SAI) - SA8000
  '01g03zasg2704w5rqgcm1ecyfc': {
    imgUrl: '/images/SAI_SA8000.png',
    website: 'https://sa-intl.org/programs/sa8000/',
    linkedin: 'https://www.linkedin.com/company/social-accountability-international/',
  },
  // Supplier Ethical Data Exchange (SEDEX) - Membership
  '01g2pqyk6fn10x7dnn1pk2v639': {
    imgUrl: '/images/Sedex_Member.png',
    website: 'https://www.sedex.com/',
    linkedin: 'https://www.linkedin.com/company/sedex/',
  },
  // Sustainable Forestry Initiative (SFI) - SFI Chain of Custody
  '01g3v6s14crz3ks1sgcynqrx8r': {
    imgUrl: '/images/SFI_2.png',
    website: 'https://forests.org/chainofcustodystandard/',
    linkedin: 'https://www.linkedin.com/company/sustainable-forestry-initiative/',
  },
  // The Climate Pledge
  '01g4me4w2290m9a7me2f6xszjz': {
    imgUrl: '/images/climatepledge.png',
    website: 'https://www.theclimatepledge.com/',
    linkedin: 'https://www.linkedin.com/company/theclimatepledge/',
  },
  // The Union for Ethical BioTrade (UEBT) - Standard
  '01g4mcgecv856jrshp82ja3tpr': {
    imgUrl: '/images/UEBT.png',
    website: 'https://uebt.org/setting-the-standard',
    linkedin: 'https://www.linkedin.com/company/uebt/',
  },
  // The Union for Ethical BioTrade (UEBT) - Membership
  '01g4md0vzc42sbcnxv2fprxb6x': {
    imgUrl: '/images/UEBT.png',
    website: 'https://uebt.org/membership',
    linkedin: 'https://www.linkedin.com/company/uebt/',
  },
  // BREEAM® España (Building Research Establishment Environmental Assessment Methodology)
  '01g3bp3ycg4c80zabcdhq22skg': {
    imgUrl: '/images/breeam_2.png',
    website: 'https://breeam.es/',
    linkedin: 'https://www.linkedin.com/company/breeam-espa%C3%B1a/',
  },
  // Empresa Familiarmente Responsable (EFR) - efr certification
  '01g38w3dxx3kjv3ztnshfsg1bm': {
    imgUrl: '/images/efr.png',
    website:
      'https://www.masfamilia.org/certificacion-efr/#:~:text=%C2%BFqu%C3%A9%20es%20la%20certificaci%C3%B3n%20efr,vida%20personal%2C%20familiar%20y%20laboral',
    linkedin: 'https://www.linkedin.com/company/fundacionmasfamilia/',
  },
  // European Flax Standard
  '01g38ybv04ecz3aevr37288xb9': {
    imgUrl: '/images/europeanflax.png',
    website: 'http://news.europeanflax.com/celc/4-european-flax/',
  },
  // Global ESG Benchmark for Real Assets (GRESB)
  '01g3e3sjbjzwwjnn20bjffrx9j': {
    imgUrl: '/images/GRESB.png',
    website: 'https://gresb.com/nl-en/about-us/',
    linkedin: 'https://www.linkedin.com/company/gresb/',
  },
  // Good distribution practice (GDP)
  '01g3e5qmdc4sa2sp7tcmg0a3t8': {
    imgUrl: '/images/gdp.png',
    website:
      'https://www.ema.europa.eu/en/human-regulatory/post-authorisation/compliance/good-distribution-practice',
  },
  // Initiative for Compliance and Sustainability (ICS)
  '01g3rfx7stgfck59zhqg61ajv7': {
    imgUrl: '/images/ics.png',
    website: 'https://ics-asso.org/',
    linkedin: 'https://www.linkedin.com/company/initiative-clause-sociale-ics-/',
  },
  // Institutional Shareholder Services (ISS) - Corporate ESG Rating
  '01g3r7xm7aq63dver9g1jefhzc': {
    imgUrl: '/images/ISS.png',
    website: 'https://www.issgovernance.com/esg/ratings/',
    linkedin: 'https://www.linkedin.com/company/iss-institutional-shareholder-services/',
  },
  // Safety and Quality Assessment for Sustainability - (SQAS)
  '01g38kqngrhn255prf0bvqpjv4': {
    imgUrl: '/images/sqas.png',
    website: 'https://www.sqas.org/index.php',
  },
  // Sustainable Apparel Coalition - Member
  '01g3e19be4d3q8535t6576hh1j': {
    imgUrl: '/images/sust_apparel.png',
    website: 'https://apparelcoalition.org/the-sac/',
    linkedin: '',
  },
  // Sustainable Apparel Coalition - The Higg Index - Higg Facility Environmental Module (Higg FEM)
  '01g3e16vef6ztczc45fz55g3en': {
    imgUrl: '/images/Higg-Index.png',
    website: 'https://apparelcoalition.org/the-higg-index/',
    linkedin: 'https://www.linkedin.com/company/sustainable-apparel-coalition/',
  },
  // Sustainable Apparel Coalition - The Higg Index - Higg Brand & Retail Module (Higg BRM)
  '01gjz4d1gc10rsbrmfht9fb4mx': {
    imgUrl: '/images/Higg-Index.png',
    website: 'https://apparelcoalition.org/the-higg-index/',
    linkedin: 'https://www.linkedin.com/company/sustainable-apparel-coalition/',
  },
  // Sustainable Apparel Coalition - The Higg Index - Higg Facility Social & Labor Module (Higg FSLM)
  '01gjz41176exmtrqtbt3vc4rtj': {
    imgUrl: '/images/Higg-Index.png',
    website: 'https://apparelcoalition.org/the-higg-index/',
    linkedin: 'https://www.linkedin.com/company/sustainable-apparel-coalition/',
  },
  // Sustainable Apparel Coalition - The Higg Index - Higg Materials Sustainability Index (Higg MSI)
  '01gjz4fvbya694w9frhhzt9dd1': {
    imgUrl: '/images/Higg-Index.png',
    website: 'https://apparelcoalition.org/the-higg-index/',
    linkedin: 'https://www.linkedin.com/company/sustainable-apparel-coalition/',
  },
  // Sustainable Apparel Coalition - The Higg Index - Higg Product Module (Higg PM)
  '01gjz4eg32c287nr16t30m22pn': {
    imgUrl: '/images/Higg-Index.png',
    website: 'https://apparelcoalition.org/the-higg-index/',
    linkedin: 'https://www.linkedin.com/company/sustainable-apparel-coalition/',
  },
  // Sustainable Fiber Alliance (SFA) - Membership
  '01g3rgccsxmxnw22f836ggftej': {
    imgUrl: '/images/sfa.png',
    website: '',
    linkedin: 'https://www.linkedin.com/company/sustainable-apparel-coalition/',
  },
  // The Good Cashmere Standard
  '01g3ecyax863yvghsn57szh0c3': {
    imgUrl: '/images/sfa.png',
    website: 'https://thegoodcashmerestandard.org/about-us/',
    linkedin:
      'https://www.linkedin.com/showcase/sustainable-cashmere-standard-by-aid-by-trade-foundation/',
  },
  // Think Green Initiative (TGI)
  '01g394pkn926cth1w7761sd97b': {
    imgUrl: '/images/TGI.png',
    website: 'https://www.intertek.com/assurance/tgi/',
  },
  // Verra - Verified Carbon Standard (VCS)
  '01g3rpck1xazr8ft28veg99y6c': {
    imgUrl: '/images/vcs.png',
    website: 'https://verra.org/verra-standards-and-programs/',
    linkedin: 'https://www.linkedin.com/company/verrastandards/',
  },
  // Worldwide Responsible Accredited Production (WRAP)
  '01g3rkn4z5gf7mmccx5w7ak300': {
    imgUrl: '/images/WRAP.png',
    website: 'https://wrapcompliance.org/',
    linkedin: 'https://www.linkedin.com/company/wrapcompliance/',
  },
  // Cotton USA
  '01g4jc9qx7k556r9hs7q3hqzdh': {
    imgUrl: '/images/CottonUSA.png',
    website: 'https://cottonusa.org/',
    linkedin: 'https://www.linkedin.com/company/cotton-council-international/',
  },
  // Cotton USA - U.S. Cotton Trust Protocol
  '01g4wrg5wyb4w7erb0x7s121pb': {
    imgUrl: '/images/USCottonTrustProtocol.png',
    website: 'https://cottonusa.org/trust-protocol',
    linkedin: 'https://www.linkedin.com/company/trustuscotton/',
  },
  // World Business Council for Sustainable Development (WBCSD) -Membership
  '01g5pdyfw0r5x3dsc19qqf0j1y': {
    imgUrl: '/images/wbcsd.png',
    website: 'https://www.wbcsd.org/',
    linkedin: 'https://www.linkedin.com/company/wbcsd/',
  },
  // UK Plastics Pact
  '01g5kbyz1wncd4ky2dp8zs5nbj': {
    imgUrl: '/images/ukplasticspact.png',
    website: 'https://wrap.org.uk/',
    linkedin: 'https://www.linkedin.com/showcase/the-uk-plastics-pact/',
  },
  // Transition Pathway Initiative (TPI)
  '01g5ka5fdrhga2yf574mwnnmtc': {
    imgUrl: '/images/tpi.png',
    website: 'https://www.transitionpathwayinitiative.org/',
    linkedin: 'https://www.linkedin.com/company/transition-pathway-initiative-tpi/',
  },
  // STOXX Global ESG Leaders
  '01g5ka540yf06ghmat1t67msma': {
    imgUrl: '/images/stoxxesgleaders.png',
    website: 'https://www.stoxx.com/index-details?symbol=SXWESGEP',
  },
  // Responsible Care® Global Charter
  '01g5rrhh7m9w4m90da455chwc0': {
    imgUrl: '/images/RCGC.png',
    website: 'https://cefic.org/responsible-care/',
    linkedin: 'https://www.linkedin.com/company/cefic-european-chemical-industry-council/',
  },
  // OK Compost
  '01g609djcasht2wjk4fa8wjkny': {
    imgUrl: '/images/OKcompost.png',
    website: 'https://earthstarch.com/',
  },
  // LEAF Coalition
  '01g5kjqfgf7v3fvatpnrfbsppr': {
    imgUrl: '/images/leafcoallition.png',
    website: 'https://leafcoalition.org/',
    linkedin: 'https://www.linkedin.com/company/emergent-forest-finance-accelerator/',
  },
  // Industrial Compostable
  '01g5kr992702pph9v2w6fzk54n': {
    imgUrl: '/images/industrialcompostable.png',
    website:
      'https://www.dincertco.de/din-certco/en/main-navigation/products-and-services/certification-of-products/overview-product-certification/',
  },
  // India Plastics Pact
  '01g5kqp8wf574xgekgwmdq7fn4': {
    imgUrl: '/images/indiaplasticspact.png',
    website: 'https://www.indiaplasticspact.org/',
    linkedin: 'https://www.linkedin.com/company/india-plastics-pact/',
  },
  // Home/Garden Compostable
  '01g5krgwcpgjgx7jhr6ekf3aya': {
    imgUrl: '/images/homecompostable.png',
    website:
      'https://www.dincertco.de/din-certco/en/main-navigation/products-and-services/certification-of-products/overview-product-certification/',
  },
  // FTSE4Good Index
  '01g602zr5bnzmsrpsvswyres7t': {
    imgUrl: '/images/ftse4good.png',
    website: 'https://www.ftserussell.com/products/indices/ftse4good',
    linkedin: 'https://www.linkedin.com/company/ftse/',
  },
  // Flustix - Recyclable packaging
  '01g5kps7623mpny4vbxytb101d': {
    imgUrl: '/images/flustixrecyclable.png',
    website: 'https://flustix.com/en/',
    linkedin: 'https://www.linkedin.com/company/flustix/',
  },
  // Flustix - Plastic free product
  '01g5kpwn8thq34qswsagzh0pb3': {
    imgUrl: '/images/flustixnoplasticsproduct.png',
    website: 'https://flustix.com/en/',
    linkedin: 'https://www.linkedin.com/company/flustix/',
  },
  // Flustix - completely PLASTIC FREE certification
  '01g5kpjtc9f3h1yxgs3w9t7s4m': {
    imgUrl: '/images/flustixplasticfree.png',
    website: 'https://flustix.com/en/',
    linkedin: 'https://www.linkedin.com/company/flustix/',
  },
  // European Plastics Pact
  '01g5kqv6h8b31sj0vwxmd4p8dm': {
    imgUrl: '/images/europeanplasticspact.png',
    website: 'https://europeanplasticspact.org/',
  },
  // Ethibel Sustainability Index - Excellence Europe
  '01g5khe28j98fvb8p7tttjfssb': {
    imgUrl: '/images/ethibelsustainabilityindex.png',
    website: 'https://www.forumethibel.org/en/investing-in-sustainability',
    linkedin: 'https://www.linkedin.com/company/forum-ethibel/',
  },
  // Corporate Leaders Group (CLG)
  '01g5krz3m27c2y66rfj7pbwa18': {
    imgUrl: '/images/clg.png',
    website: 'https://www.corporateleadersgroup.com/',
    linkedin: 'https://www.linkedin.com/showcase/corporate-leaders-groups/',
  },
  // CEFLEX (The Circular Economy for Flexible Packaging)
  '01g5k420wvpz63t89z6zeh5bm9': {
    imgUrl: '/images/ceflex.png',
    website: 'https://ceflex.eu/',
    linkedin: 'https://www.linkedin.com/company/ceflex-initiative/',
  },
  // BPIF Cartons
  '01g608hyhsdkays79g9sqz8t35': {
    imgUrl: '/images/bpifcartons.png',
    website: 'https://www.bpifcartons.org.uk/',
    linkedin: 'https://www.linkedin.com/company/bpif-cartons/',
  },
  // BIOSUISSE ORGANIC
  '01g5khx7m0y6p34aqd0brv2vfz': {
    imgUrl: '/images/BioSuisse.png',
    website: 'https://www.bio-suisse.ch/en.html',
  },
  // Biodegradable Products lnstitute (BPI) - Compostable
  '01g5krp4ygqgdcd4r69hvgb4rn': {
    imgUrl: '/images/uscompostingcouncil.png',
    website: 'https://www.compostingcouncil.org/page/MemberJoin',
    linkedin: 'https://www.linkedin.com/company/us-composting-council/',
  },
  // turn to zero (Klimaneutralitätsbündnis 2025)
  '01g6j4wg5mxes69z255851sq12': {
    imgUrl: '/images/turntozero.png',
    website: 'https://www.turntozero.com/en/index.htm',
    linkedin: 'https://www.linkedin.com/company/turn-to-zero/',
  },
  // Fair Wear Foundation (FWF) - Brand Performance Checks
  '01g6j6b6ee8q905fxsbhady15f': {
    imgUrl: '/images/fairwear.png',
    website: 'https://www.fairwear.org/programmes/brand-performance-checks/',
    linkedin: 'https://www.linkedin.com/company/fair-wear-foundation/',
  },
  // We Forest - Making Earth Cooler
  '01g6jewhnx00jmcs81k1hp41cn': {
    imgUrl: '/images/WeForest.png',
    website: 'https://www.weforest.org/',
    linkedin: 'https://www.linkedin.com/company/1598455/?trk=tyah',
  },
  // Guangdong Province Pollutant Discharge Permit (广东省污染物排放许可证)
  '01g6n5fydg7h25wp82wq49sdqq': {
    imgUrl: '/images/Guangdong_Pollutant_Discharge_Permit.jpg',
    website: 'http://gdee.gd.gov.cn/ggtz3126/content/post_3230989.html',
  },
  // InstallQ
  '01g6n7yjm84nv8kagrs0pd2yvm': {
    imgUrl: '/images/installQ.png',
    website: 'https://installq.nl/',
  },
  // CNS Mark
  '01g6n8kqm3pqev34yvqjwx5zje': {
    imgUrl: '/images/CNSMark.png',
    website: 'https://www.cnsonline.com.tw/',
  },
  // VCA certificate (Veiligheid, Gezondheid en Milieu Checklist Aannemers)
  '01g6nbddhv3p3n0130jzken0w8': {
    imgUrl: '/images/vca.png',
    website: 'https://www.vca.nl/',
    linkedin:
      'https://www.linkedin.com/company/stichting-samenwerken-voor-veiligheid-ssvv-/?originalSubdomain=nl',
  },
  // BMWT-Keur
  '01g6nf99aryz7bq5hzn1tg7fde': {
    imgUrl: '/images/BMWT.png',
    website: 'https://bmwt.nl/thema?dossierid=2288484372&title=Nieuw.BMWT-Keur&child=True',
    linkedin: 'https://www.linkedin.com/company/bmwt/',
  },
  // BSI Kitemark
  '01g6nftn8sv9sykneddtwa05c2': {
    imgUrl: '/images/BSI-Kitemark.png',
    website: 'https://www.bsigroup.com/en-GB/kitemark/product-testing/',
  },
  // Certifire Certificate
  '01g6q534vfe7cj49ayhm3k1ymv': {
    imgUrl: '/images/certifire.png',
    website: 'https://www.warringtonfire.com/certification-services/fire-certification/certifire',
  },
  // Toxics in Packaging Clearinghouse (TPCH)
  '01g6nc6dr19wz9055esj7smyv6': {
    imgUrl: '/images/toxicsinpackaging.png',
    website: 'https://toxicsinpackaging.org/model-legislation/model/',
  },
  // Proposition 65
  '01g6ncv7f0arkhftkjqn3pcend': {
    imgUrl: '/images/Proposition65.png',
    website: 'https://www.p65warnings.ca.gov/',
  },
  // GLOBAL Good Agricultural Practices (G.A.P.) - Crops
  '01g634tqhzzq5brmrj7gss71f2': {
    imgUrl: '/images/globalgap.png',
    website: 'https://www.globalgap.org/uk_en/',
  },
  // The Forests Dialogue
  '01g5kh3nk49ah4m3kqdza3nx35': {
    imgUrl: '/images/tfd.jpeg',
    website: 'https://theforestsdialogue.org/',
    linkedin: 'https://www.linkedin.com/company/the-forests-dialogue/',
  },
  // The On-pack Recylcing Label (OPRL) - Member
  '01g6js3tmw2cr9zdyv8aq7v42p': {
    imgUrl: '/images/oprlmemb.png',
    website: 'https://www.oprl.org.uk/',
    linkedin: 'https://www.linkedin.com/company/on-pack-recycling-label-limited/',
  },
  // Foodservice Packaging Association (FPA) - Members
  '01g6jfefkmnphn5herm8968d0z': {
    imgUrl: '/images/fpa.png',
    website: 'https://www.foodservicepackaging.org.uk/',
    linkedin: 'https://www.linkedin.com/company/fpa-foodservice-packaging-association-/',
  },
  // Zero Foil 2 Landfill
  '01g5ks49fgpm781wvmsh34yba8': {
    imgUrl: '/images/zerofoil.png',
    website: 'https://www.reconomy.com/products-services/print-recycling/zero-foil-2-landfill/',
  },
  // Board Bia Quality Assurance
  '01g77dxmqmzk26avdad81c9epn': {
    imgUrl: '/images/boardbia.png',
    website: 'https://www.bordbia.ie/',
    linkedin: 'https://www.linkedin.com/company/bord-bia---the-irish-food-board/',
  },
  // Sustainable Agriculture Initiative Platform (SAI) - Membership
  '01g79b6hjgvrvgq3wza244vqrv': {
    imgUrl: '/images/sai.png',
    website: 'https://saiplatform.org/',
    linkedin: 'https://www.linkedin.com/company/sai-platform/',
  },
  // Quality Assurance for Food Products (QAFP)
  '01g79r2xsryrkt6608q23h06rh': {
    imgUrl: '/images/qafp.png',
    website: 'https://qafp.pl/en/',
    linkedin: 'https://www.linkedin.com/company/gwarancjajakosciqafp/',
  },
  // ActionZero
  '01g79c3f9j7a2annb3d1tznvdk': {
    imgUrl: '/images/actionzero.jpeg',
    website: 'https://actionzero.com/',
    linkedin: 'https://www.linkedin.com/company/actionzero/',
  },
  // Agriculture and Horticulture Development Board (AHDB)
  '01g77cxzy6znd41pg1hzpaeq68': {
    imgUrl: '/images/ahdb.jpeg',
    website: 'https://ahdb.org.uk/',
    linkedin: 'https://www.linkedin.com/company/agriculture-and-horticulture-development-board/',
  },
  // Aquaculture Stewardship Council - Farmed Responsibly asc Certified
  '01g76rk20eny77mmn1rwm59rta': {
    imgUrl: '/images/asc.png',
    website: 'https://www.asc-aqua.org/',
    linkedin:
      'https://www.linkedin.com/company/aquaculture-stewardship-council/?originalSubdomain=nl',
  },
  // Courtauld Commitment 2030
  '01g7a19kexah8cs87kk0tcah21': {
    imgUrl: '/images/courtauld.png',
    website: 'https://wrap.org.uk/taking-action/food-drink/initiatives/courtauld-commitment',
    linkedin: 'https://www.linkedin.com/company/wrap/',
  },
  // DLG Product Certificate
  '01g79ddc1kg85h20z2qg7tgcrc': {
    imgUrl: '/images/dlg.png',
    website: 'https://www.dlgtestservice.com/en/index.php',
  },
  // European Roundtable of Beef Sustainability
  '01g79ah0kbfqzytpa3ddxjsyjp': {
    imgUrl: '/images/sai.png',
    website: 'https://saiplatform.org/erbs/',
    linkedin: 'https://www.linkedin.com/company/sai-platform/',
  },
  // GLOBAL Good Agricultural Practices (G.A.P.) - Aquaculture
  '01g76s50yes54f9pxybyc4s61d': {
    imgUrl: '/images/globalgap.png',
    website: 'https://www.globalgap.org/uk_en/',
  },
  // Global Roundtable for Sustainable Beef (GRSB)
  '01g79sx2egvnwk25e6hjs12evc': {
    imgUrl: '/images/grsb.png',
    website: 'https://grsbeef.org/',
    linkedin: 'https://www.linkedin.com/company/global-roundtable-for-sustainable-beef/',
  },
  // Investors in the Environment (iiE)
  '01g76txw15stv4w3s6qq7b6z36': {
    imgUrl: '/images/iiE.png',
    website: 'https://www.iie.uk.com/',
    linkedin: 'https://www.linkedin.com/groups/Investors-in-Environment-4861733/',
  },
  // JustDiggit
  '01g79s5d6ctxn7aayb8xsrs3rn': {
    imgUrl: '/images/justdiggit.png',
    website: 'https://justdiggit.org/',
    linkedin: 'https://www.linkedin.com/company/justdiggit/',
  },
  // Meat Business Women
  '01g79mdc8g9vjsdq2nnq739fjs': {
    imgUrl: '/images/mbw.png',
    website: 'https://meatbusinesswomen.org/',
    linkedin: 'https://www.linkedin.com/company/meat-business-women/',
  },
  // Plant Based Foods Association (PBFA)
  '01g79q52zy8f846tqzxkb5qf84': {
    imgUrl: '/images/pbfa.png',
    website: 'https://www.plantbasedfoods.org/',
    linkedin: 'https://www.linkedin.com/company/plant-based-foods-association/',
  },
  // rePurpose Global
  '01g775kzt87bej4y4kz52nzmdk': {
    imgUrl: '/images/repurpose.png',
    website: 'https://repurpose.global/',
    linkedin: 'https://www.linkedin.com/company/repurposeglobal/',
  },
  // RSPCA Assured
  '01g778adz61650ywaevwnktt1j': {
    imgUrl: '/images/rspca.png',
    website: 'https://www.rspcaassured.org.uk/',
  },
  // Sustainable Fisheries Greenland (SFG)
  '01g79nt1nvt063qyvvetxd080b': {
    imgUrl: '/images/sfg.png',
    website: 'http://www.sfg.gl/en/front-page',
  },
  // Sustainable Seafood Coalition (SSC)
  '01g79hzg76z0fqd87zqyt1kmdq': {
    imgUrl: '/images/ssc.png',
    website: 'https://www.sustainableseafoodcoalition.org/',
  },
  // Tamar Water Stewardship Business Board (TWSBB)
  '01g79cza49eat1qj6tnv2t71z4': {
    imgUrl: '/images/twsbb.png',
    website: 'https://wrt.org.uk/tamar-water-stewardship-business-board/',
  },
  // The Organic Food Federation (OFF)
  '01g77by406w0wxs726753hg0y5': {
    imgUrl: '/images/off.png',
    website: 'http://www.orgfoodfed.com/',
    linkedin: 'https://www.linkedin.com/company/organic-food-federation/',
  },
  // UK Soy Manifesto
  '01g74ghhsydgsc337m4cbhg8bg': {
    imgUrl: '/images/uksoy.png',
    website: 'https://www.uksoymanifesto.uk/',
  },
  // China National Organic
  '01g7p1vmf0r9hftb4qgzgvqg29': {
    imgUrl: '/images/china_national_organic.png',
    website:
      'https://certifications.controlunion.com/en/certification-programs/certification-programs/china-national-organic-standard',
  },
  // California Transparency in Supply Chains Act
  '01g7d0ts822k0jrmg6je8gy0bw': {
    imgUrl: '/images/California_Republic.png',
  },
  // CQC Mark Certification (China Quality Certification Center)
  '01g7p6t2nhzprkdm28xhaajfz2': {
    imgUrl: '/images/cqc.png',
    website: 'https://www.china-certification.com/en/cqc-mark-certification-3/',
  },
  // EU Eco-Management and Audit Scheme (EMAS)
  '01g7nymv4x788h5g6spcjvvenq': {
    imgUrl: '/images/EMAS.png',
    website: 'https://ec.europa.eu/environment/emas/index_en.htm',
  },
  // Ecocert - Natural Detergent
  '01g7d28aegg2kbajxy4pb77xyz': {
    imgUrl: '/images/detergent_ecocert.png',
    website: 'https://www.ecocert.com/en/certification-detail/ecological-household-products-natdet',
  },
  // GMP+ Feed Safety Assurance
  '01g7p531c6rbsz52g3b883sx34': {
    imgUrl: '/images/gmp+.png',
    website: 'https://www.gmpplus.org/en/feed-certification-scheme/gmp-fsa-certification/',
    linkedin: 'https://www.linkedin.com/company/gmp--international/',
  },
  // Green Company – GC-Mark Certification
  '01g7p4tp7vpm73a9xgsshk03jp': {
    imgUrl: '/images/green-comp.png',
  },
  // Sustainable Packaging Coalition (SPC)
  '01fw1ag7jzp3ga203ebdthvwbx': {
    imgUrl: '/images/SPC.png',
    website: 'https://sustainablepackaging.org/',
  },
  // SC Certification (QS mark - 生产许可标志)
  '01g7p6yw9hxv1a0n7aj7k2znkw': {
    imgUrl: '/images/sc_quality.png',
    website: 'https://baike.baidu.com/item/QS/87279',
  },

  // Alliance to End Plastic Waste (AEPW)
  '01g9z1kr15ftjr2rs01wxjq690': {
    imgUrl: '/images/end_plastic.png',
    website: 'https://endplasticwaste.org/',
  },
  // Best Aquaculture Practice (BAP) - Farm Standard
  '01g9yq5sh05z7rsv4m0wfswp69': {
    imgUrl: '/images/BAP.png',
    website: 'https://www.bapcertification.org/',
    linkedin: 'https://www.linkedin.com/company/best-aquaculture-practices-bap-/',
  },
  // Best Aquaculture Practice (BAP) - Feed Mills
  '01g9yq1rf89jk12vd1qs1p1kkw': {
    imgUrl: '/images/BAP.png',
    website: 'https://www.bapcertification.org/',
    linkedin: 'https://www.linkedin.com/company/best-aquaculture-practices-bap-/',
  },
  // Best Aquaculture Practice (BAP) - Hatchery
  '01g9yq2ekg2vb0nq85ex0ptsxv': {
    imgUrl: '/images/BAP.png',
    website: 'https://www.bapcertification.org/',
    linkedin: 'https://www.linkedin.com/company/best-aquaculture-practices-bap-/',
  },
  // Best Aquaculture Practice (BAP) - Seafood Processing Standard
  '01g9yq7k9vcc1ceggxxrqexs0x': {
    imgUrl: '/images/BAP.png',
    website: 'https://www.bapcertification.org/',
    linkedin: 'https://www.linkedin.com/company/best-aquaculture-practices-bap-/',
  },
  // BSR - HERproject
  '01ga1fhxp8ysjbh4qzqnp56f0x': {
    imgUrl: '/images/bsr_her.png',
    website: 'https://herproject.org/',
  },
  // Canada Organic Trade Association - Canada Organic Certified
  '01g9yfgkmr7awc0kmnhw5cpd5g': {
    imgUrl: '/images/canada_organic.png',
    website: 'http://www.canada-organic.ca/en',
  },
  // Child Labour Monitoring and Remediation Systems (CLMRS)
  '01g79fexgswg0j31jcsqm071w2': {
    imgUrl: '/images/CLMRS_2.png',
    website:
      'https://www.cocoainitiative.org/our-work/operational-support/child-labour-monitoring-and-remediation-systems',
  },
  // Children’s Product Certificate (CPC)
  '01g9pvbmkh82h7wwre9m7j7svz': {
    imgUrl: '/images/us_consumer_product_safety.png',
    website: 'https://www.cpsc.gov/Testing-Certification/Childrens-Product-Certificate-CPC',
  },
  // China Restriction of Hazardous Substances (RoHS)
  '01fye842r0bqj2f2a1tfnh500g': {
    imgUrl: '/images/RoHS_2.png',
    website: 'https://www.rohsguide.com/china-rohs.htm',
  },
  // EU Organic Label
  '01g9pp0bz101zygex7b9vvhzv4': {
    imgUrl: '/images/EUorganic.png',
    website: 'https://agriculture.ec.europa.eu/farming/organic-farming/organic-logo_en',
  },
  // Danish Organic Certification
  '01gagng32g1r5pde7ese673fez': {
    imgUrl: '/images/EUorganic.png',
    website: 'https://www.foedevarestyrelsen.dk/Leksikon/Sider/Det-oekologiske-spisemaerke.aspx',
  },
  // Global Security Verification (GSV) - Supply Chain Security
  '01g9hzpvkpsv68tk9m9jwrq4ey': {
    imgUrl: '/images/GSV-Logo.png',
    website: 'https://www.intertek.com/assurance/gsv/',
  },
  // GS - Geprüfte Sicherheit (Tested Safety)
  '01g9kzy9amv98anv3w0fy1hybv': {
    imgUrl: '/images/gs_tuev.png',
    website:
      'https://www.tuev-nord.de/en/company/certification/product-certification/consumer-goods/german-safety-gs-mark/',
  },
  // HR Excellence Awards - Excellence in CSR Strategy
  '01g9yr5yb23ygbjxwr3fs1myvv': {
    imgUrl: '/images/HR_Award.png',
    website: 'https://awards.humanresourcesonline.net/hr-excellence-awards-th/',
    linkedin: 'https://www.linkedin.com/company/human-resources-online/',
  },
  // ICTI Ethical Toy Program Certification
  '01g9y8nhyssent2ttks4mensy4': {
    imgUrl: '/images/icti.png',
    website: 'https://www.ethicaltoyprogram.org/en/our-program/certification-and-audit/',
    linkedin: 'https://www.linkedin.com/company/ethical-toy-program/',
  },
  // Indonesia Business Council for Sustainable Development (IBCSD)
  '01ga44dsvz8j727t78xc0mabkp': {
    imgUrl: '/images/ibcsd.png',
    website: 'https://ibcsd.or.id/',
    linkedin:
      'https://www.linkedin.com/company/indonesia-business-council-for-sustainable-development/',
  },
  // International Marine Mammal Project - Dolphin Safe
  '01g9pz6mm0mw2ndjxp4k9eaqat': {
    imgUrl: '/images/dolphin.png',
    website: 'https://savedolphins.eii.org/',
  },
  // International Pole and Line Foundation (IPNLF) - Membership
  '01ga117p3kn8rj99nh7vsgkkqe': {
    imgUrl: '/images/ipnlf.png',
    website: 'https://ipnlf.org/about/',
    linkedin: 'https://www.linkedin.com/company/international-pole-and-line-foundation/',
  },
  // International Seafood Sustainability Foundation (ISSF)
  '01ga15ryram766c0cd2yknpz06': {
    imgUrl: '/images/issf.png',
    website: 'https://www.iss-foundation.org/',
    linkedin: 'https://www.linkedin.com/company/international-seafood-sustainability-foundation/',
  },
  // IWAY - IKEA way for responsible sourcing
  '01ga43xpf18cbpecwam31z4wyh': {
    imgUrl: '/images/IKEA.png',
    website:
      'https://about.ikea.com/en/work-with-us/for-suppliers/iway-our-supplier-code--of-conduct',
  },
  // Japanese Agricultural Standard (JAS)
  '01ga16xnpgp0y9x0ytrdjkpm2e': {
    imgUrl: '/images/jas.png',
    website: 'https://www.maff.go.jp/e/policies/standard/jas/',
  },
  // National Programme for Organic Production (NPOP)
  '01ga0ndc5ghbhxmydfs1sp7dp2': {
    imgUrl: '/images/India-NPOP.png',
    website: 'https://apeda.gov.in/apedawebsite/organic/index.htm',
    linkedin: 'https://www.linkedin.com/in/apedadoc/',
  },
  // Naturland Organic
  '01ga42149q5aey8r6jq26a9dmv': {
    imgUrl: '/images/naturland.png',
    website: 'https://www.naturland.de/en/',
    linkedin: 'https://www.linkedin.com/company/naturland-association-for-organic-agriculture/',
  },
  // SGF International - Voluntary Control System (VCS)
  '01ga0xd4tcj7ad2rvcnmwecx09': {
    imgUrl: '/images/sgf.png',
    website: 'https://www.sgf.org/voluntary-control-system',
    linkedin: 'https://www.linkedin.com/company/sgf-international/',
  },
  // SMK3 - Occupational Health and Safety Management System
  '01ga0p5xhdn3fj52zhefrp57gt': {
    imgUrl: '/images/SMK3.png',
    website: 'https://mutucertification.com/certification-smk3-en/',
  },
  // Styrenics Circular Solutions (SCS)
  '01g9z065058j7hkh3s3xgbmjq9': {
    imgUrl: '/images/scs.png',
    website: 'https://styrenics-circular-solutions.com/about-scs.html',
  },
  // The Climate Group - EP100
  '01g9ydafyz9rw94ycs37ht74hd': {
    imgUrl: '/images/climategroup.png',
    website: 'https://www.theclimategroup.org/about-ep100',
    linkedin: 'https://www.linkedin.com/company/the-climate-group/',
  },
  // My Emissions - Cradle-to-Store Assessment
  '01ga8mht9v5kmnpg9cm540twnw': {
    imgUrl: '/images/myemissions.png',
    website: 'https://myemissions.green/',
    linkedin: 'https://www.linkedin.com/company/myemissions/',
  },
  // STS Food Safety
  '01g77g3nk5mra4bx9ptmgse4h6': {
    imgUrl: '/images/STS.png',
    website: 'https://www.ukas.com/resources/resources/accreditation-in-food-safety/',
  },
  // Prix Juste Producteur
  '01g2mae1dfgsp3dfqp9k16a2x2': {
    imgUrl: '/images/logo-prix-juste-producteur-356x364-1.png',
    website: 'https://prixjuste.be/',
  },
  // EU Ecolabel - Furniture
  '01gb77knnczj7mnapde1v3yb6a': {
    imgUrl: '/images/euecolabel.png',
    website: 'https://environment.ec.europa.eu/topics/circular-economy/eu-ecolabel-home_en',
    linkedin: 'https://www.linkedin.com/company/euecolabel?originalSubdomain=be',
  },
  // Timber Legality Assurance Standard (SVLK/TLAS)
  '01gb78tprdz05xe7h3gvx8xm5p': {
    imgUrl: '/images/svlk.png',
    website: 'https://silk.menlhk.go.id/index.php',
  },
  // Clean200
  '01g80yvrfw5eeajdrfx29pkch0': {
    imgUrl: '/images/clean200.png',
    website: 'https://www.asyousow.org/report-page/2022-clean200',
  },
  // All-Ireland Pollinator Plan
  '01g79trs1kwrjcdr92752pj8tf': {
    imgUrl: '/images/aipp.png',
    website: 'https://pollinators.ie/',
  },
  // The Disney Facility and Merchandise Authorisation (FAMA)
  '01g4j9ehwf0ggcx5axbhjvpavs': {
    imgUrl: '/images/disneyfama.png',
    website: 'https://impact.disney.com/operating-responsibly/supply-chain/',
  },
  // Supplier Qualification Program (SQP)
  '01g4chq64dmvjnj09pp4z3v73y': {
    imgUrl: '/images/sqp.png',
    website: 'https://www.intertek.com/assurance/sqp/',
  },
  // Leadership in Energy and Environmental Design (LEED) - Buildings
  '01g8agfwd57apq7jrs4bbvwezb': {
    imgUrl: '/images/leed.png',
    website: 'https://www.usgbc.org/leed/v41',
    linkedin: 'https://www.linkedin.com/company/usgbc/',
    tableColumns: [
      {
        name: 'Certification Level',
        keys: ['Certification in progress'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  // Fair Factories Clearinghouse (FFC) - Membership
  '01g8aj2rg6pcn2kpntnn8d6c5a': {
    imgUrl: '/images/ffc.png',
    website: 'https://www.fairfactories.org/',
    linkedin: 'https://www.linkedin.com/company/fair-factories-clearinghouse/',
  },
  // Family Audit Certification
  '01g8c0wa854ag3zs01rmx4nyvq': {
    imgUrl: '/images/familyaudit.png',
    website: 'https://seamilano.eu/en/sustainability/family-audit',
  },
  // BS EN 16636 - European Pest Management Standard
  '01g8af05b1hsy65js9b5nrfbhb': {
    imgUrl: '/images/en16636.png',
    website:
      'https://www.en-standard.eu/bs-en-16636-2015-pest-management-services-requirements-and-competences/',
  },
  // UNI CEI 11352 - Energy Management - Companies providing energy services (ESCo)
  '01g88az9dmnjj149st8rtrm3c0': {
    imgUrl: '/images/cei11352.png',
    website:
      'https://www.renovisenergy.com/en/blog/uni-cei-11352-certification-what-is-it-partners.htm',
  },
  // AiCARR
  '01g8av40262tgr7xzhx8myv1bs': {
    imgUrl: '/images/aicarr.png',
    website: 'https://www.aicarr.org/',
    linkedin: 'https://www.linkedin.com/company/aicarr-associazione/',
  },
  // Forest Solutions Group (FSG)
  '01g5k8vk5f80b6a0hgjkm6eqq8': {
    imgUrl: '/images/fsg.png',
    website: 'https://www.wbcsd.org/Sector-Projects/Forest-Solutions-Group',
  },
  // English Label (Label Anglais)
  '01gbsbw2em8sm719yew74xhnq6': {
    imgUrl: '/images/englishlabel.png',
    website: 'https://englishlabel.co.uk/',
  },
  // Quality Trout UK
  '01gbsch06msh5dvyhsw4v0vytq': {
    imgUrl: '/images/qualitytrout.png',
    website: 'http://qualitytrout.co.uk/',
  },
  // Files excluded from sustainability assessment
  '01gb88b8bfhww9x9c7vpymaj1f': {
    imgUrl: '/images/other_certificates.png',
  },
  // Seafood Stewardship Index
  '01ga3a9t7dsj67kxymb40jyemg': {
    imgUrl: '/images/seafood_stewardship.png',
    website:
      'https://www.worldbenchmarkingalliance.org/publication/seafood-stewardship-index/rankings/',
  },
  // Aluminium Stewardship Initiative - ASI Performance Standard
  '01gc620v5zaj5tkcmc02805jr0': {
    imgUrl: '/images/asi.png',
    website: 'https://aluminium-stewardship.org/about-asi',
    linkedin: 'https://www.linkedin.com/company/aluminium-stewardship-initiative/',
  },
  // Bra Miljöval (Good Environmental Choices) - Electricity and District Heating
  '01gc68d6erwnvmnaqyxq1px0wy': {
    imgUrl: '/images/bra_miljoeval.png',
    website: 'https://www.bramiljoval.se/in-english/',
    linkedin: 'https://www.linkedin.com/company/bra-miljoval/',
  },
  // Transparency International
  '01gc6h0kthksv9qyzc2cznd1hy': {
    imgUrl: '/images/transparency-international.png',
    website: 'https://www.transparency.org/en/about',
    linkedin: 'https://www.linkedin.com/company/transparency-international/',
  },
  // Operation Clean Sweep
  '01gc6kkngahdh02y0tv7c630mw': {
    imgUrl: '/images/ocs-logo.png',
    website: 'https://www.opcleansweep.eu/',
    linkedin: 'https://www.linkedin.com/company/operation-clean-sweep-europe/',
  },
  // Bloomberg - ESG scores
  '01g10k18rb5fds6j5kc6y3hy0k': {
    imgUrl: '/images/bloomberg.png',
    website:
      'https://www.bloomberg.com/professional/dataset/global-environmental-social-governance-data/',
  },
  // Development and Climate Alliance
  '01g9hp9f7zzfc23e40h0ne84sp': {
    imgUrl: '/images/development_climate_alliance.png',
    website: 'https://allianz-entwicklung-klima.de/en/about-us/',
    linkedin: 'https://www.linkedin.com/company/stiftung-allianz-entwicklung-klima/',
  },
  // Lean & Green Program
  '01g9m3z7ce1zkwc5q1hybrakf5': {
    imgUrl: '/images/LeanGreen.png',
    website: 'https://lean-green.eu/',
  },
  // MVO Prestatieladder (CSR Performance Ladder)
  '01g9m7kdmqggm5fkmxtct9kaax': {
    imgUrl: '/images/MVOP.png',
    website: 'https://www.mvoprestatieladder.nl/en/',
    linkedin: 'https://www.linkedin.com/groups/3162098/',
  },
  // Ocean Conservancy
  '01g9hq9qt2b9z8tseba1m7q3zg': {
    imgUrl: '/images/ocean_conservancy.png',
    website: 'https://oceanconservancy.org/about/',
  },
  // Recycled Content Certification
  '01g9m26rjxx5thagxzkxta27g8': {
    imgUrl: '/images/recycled_content.png',
    website: 'https://www.scsglobalservices.com/services/recycled-content-certification',
  },
  // Swiss Energy Agency of the Economy (EnAW) - Certificate CO2 & kWh reduced
  '01g9m4wx37dr9njea5eyxv6kgz': {
    imgUrl: '/images/enaw.png',
    website: 'https://enaw.ch/',
    linkedin: 'https://www.linkedin.com/company/enaw/',
  },
  // Danish Smiley Scheme (Danske Smiley Ordningen)
  '01gb59k489n8gygz1ea821nmra': {
    imgUrl: '/images/Elitesmiley.png',
    website: 'https://www.findsmiley.dk/om_smiley/Sider/default.aspx',
    tableColumns: [
      {
        name: 'Last Updated',
        keys: ['date_extracted'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  // Danish CSR Label (CSR-Mærket)
  '01gb7bxz4hmy2nm8cpv68jm067': {
    imgUrl: '/images/Danish_CSR_label.png',
    website: 'https://csr-maerket.dk/om-csr-maerket/',
  },
  // CreditChina
  '01gcy2dves1z0rf8yjmzpd80jh': {
    imgUrl: '/images/creditchina.png',
    website: 'https://www.creditchina.gov.cn/',
    tableColumns: [
      {
        name: 'Risk warning',
        keys: ['Abnormal Operation'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Credit verdict',
        keys: ['Credit Verdict'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  // CreditChina - Zhejiang
  '01gjcgzgc0thsz418k8bgdfqw8': {
    imgUrl: '/images/creditchina.png',
    website: 'https://www.creditchina.gov.cn/',
    tableColumns: [
      {
        name: 'Risk warning',
        keys: ['Risk Warning'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Evaluation score',
        keys: ['Evaluation Score'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
      {
        name: 'Blacklisting records',
        keys: ['Dishonest Punishment'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  // Friend of the Sea - Turtle-Safe Certification
  '01gcve54jzagcw399n6v87918x': {
    imgUrl: '/images/friend_of_the_sea.png',
    website: 'https://friendofthesea.org/',
    linkedin: 'https://www.linkedin.com/company/friend-of-the-sea/',
  },
  // U.S. Soybean Sustainability Assurance Protocol
  '01gcvr2efrjzgh12en3w72y6w4': {
    imgUrl: '/images/ussec.png',
    website:
      'https://ussec.org/resources/ssap-2021/#:~:text=The%20U.S.%20Soy%20Sustainability%20Assurance,mass%20balance%20international%20verification%20available.',
  },
  // Solidaridad
  '01fzwwd522vd1h1pkd1c4kqhbb': {
    imgUrl: '/images/solidaridad.png',
    website: 'https://www.solidaridadnetwork.org/',
    linkedin: 'https://www.linkedin.com/company/solidaridad/',
  },
  // Bluesign
  '01geedhw4h2gfy8e7dgxa2czfa': {
    imgUrl: '/images/bluesign.png',
    website: 'https://www.bluesign.com/en/about',
    linkedin: 'https://www.linkedin.com/company/bluesign-technologies-ag/',
  },
  // Better Work
  '01ge7969qe01j2vk6reka9nkyc': {
    imgUrl: '/images/betterwork.png',
    website: 'https://betterwork.org/',
    linkedin: 'https://www.linkedin.com/company/better-work/',
  },
  // Ethical Trade Initiative
  '01ge7c0st197n99h9rxarpt67k': {
    imgUrl: '/images/eti.png',
    website: 'https://www.ethicaltrade.org/',
    linkedin: 'https://www.linkedin.com/company/ethical-trading-initiative/',
  },
  // Social & Labor Convergence Project
  '01ge6xq8ga5wngge298ghprahy': {
    imgUrl: '/images/SLCP.png',
    website: 'https://slconvergence.org/',
    linkedin: 'https://www.linkedin.com/company/social-labor-convergence-program/',
  },
  // Zero Discharge of Hazardous Chemicals (ZDHC)
  '01ge78qgd352e7jh16p2z3p2tm': {
    imgUrl: '/images/zdhc.png',
    website: 'https://www.roadmaptozero.com/',
    linkedin:
      'https://www.linkedin.com/company/zdhc-foundation---zero-discharge-of-hazardous-chemicals/',
  },
  // Best Places to Work for LGBTQ+ Equality
  '01ggw85wzay4ee5jy90nwk1jmj': {
    imgUrl: '/images/BPTW.png',
    website: 'https://www.hrc.org/resources/best-places-to-work-for-lgbtq-equality-2022',
    linkedin: 'https://www.linkedin.com/company/best-places-to-work-program/',
    tableColumns: [
      {
        name: 'Year',
        keys: ['year'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  // BREEAM® (Building Research Establishment Environmental Assessment Methodology)
  '01ggw2p31x45k7zpqma7qabr2x': {
    imgUrl: '/images/breeam.png',
    website: 'https://www.buildenergy.co.uk/services/breeam/',
    linkedin: 'https://www.linkedin.com/showcase/sound-testing/about/',
  },
  // Clean Energy Buyers Association (CEBA)
  '01ggs77fr66f2m49a3vssq3phf': {
    imgUrl: '/images/CEBA.png',
    website: 'https://cebuyers.org/',
  },
  // Disability Equality Index (DEI) - Best Places to Work
  '01fydxcjkj0kw3ke5q1xkwpthc': {
    imgUrl: '/images/DEI.png',
    website: 'https://disabilityin.org/what-we-do/disability-equality-index/2021companies/',
    linkedin: 'https://www.linkedin.com/company/disabilityin/',
  },
  // Living Wage Foundation UK - Supporter
  '01ggsa4hyxyvapxdp6kfb4xh06': {
    imgUrl: '/images/living_wage_foundation.png',
    website: 'https://www.livingwage.org.uk/',
    linkedin: 'https://www.linkedin.com/company/living-wage-foundation/',
  },
  // Living Wage Foundation UK - Accredited
  '01ggs9w0qa43m00tax0v14w3x3': {
    imgUrl: '/images/living_wage_foundation.png',
    website: 'https://www.livingwage.org.uk/',
    linkedin: 'https://www.linkedin.com/company/living-wage-foundation/',
  },
  // Racial Equity at Work Certification
  '01ggpqm171vad27ec9b1kx6p0h': {
    imgUrl: '/images/racial_equity.png',
    website: 'https://mlt.org/',
  },
  // Race to Zero
  '01gg75ad6fxchnpfnr2tvwztgn': {
    imgUrl: '/images/RtZ.png',
    website: 'https://unfccc.int/climate-action/race-to-zero-campaign',
    linkedin: 'https://www.linkedin.com/company/unclimatechange/',
  },
  // RE100
  '01ggw6h0jkx2wbhbnjwv5ey3bp': {
    imgUrl: '/images/RE100.png',
    website: 'https://www.there100.org/',
    linkedin: 'https://www.linkedin.com/company/the-climate-group/',
  },
  // TRACE International
  '01ggscpfgmsxtc17d34zmvq0cv': {
    imgUrl: '/images/trace.png',
    website: 'https://www.traceinternational.org/about-trace',
  },
  // Vigeo Eiris - Part of Moody’s ESG solutions - ESG Assessment
  '01g5kh99ghrmhs3x8p3wnxhnt0': {
    imgUrl: '/images/vigeo_eiris.png',
    website: 'https://esg.moodys.io/',
  },
  // The Hong Kong Green Organisation Certification (HKGOC)
  '01ggwbhjjd1gzy87rnbnecpw81': {
    imgUrl: '/images/HK_green_org.png',
    website: 'https://www.hkgoc.gov.hk/en-hk/about-us.html#collaborative-partners/',
  },
  // WELL Health-Safety Rating
  '01ggsh3q8xp4mhs0h9532vk8g8': {
    imgUrl: '/images/well_healthysafety.png',
    website: 'https://www.wellcertified.com/health-safety/',
    linkedin: 'https://www.linkedin.com/company/iwbi/',
  },
  // Blue Competence
  '01gk4by8ssk3whfxreps2em8f1': {
    imgUrl: '/images/blue-competence.png',
    website: 'https://www.vdma.org/bluecompetence',
  },
  // ChemScore
  '01gk4aqbfwv5n9p0k14ht7g57j': {
    imgUrl: '/images/chemscore.png',
    website: 'https://chemscore.chemsec.org/',
  },
  // Green Seal
  '01gk411ct9shx26b0vn390enw8': {
    imgUrl: '/images/greenseal.png',
    website: 'https://greenseal.org/home-consumers/',
    linkedin: 'https://www.linkedin.com/company/green-seal/',
  },
  // Responsible Mica Initiative (RMI)
  '01gk40nczszjpkm9bjxc8v8xqp': {
    imgUrl: '/images/responsiblemica.png',
    website: 'https://responsible-mica-initiative.com/',
    linkedin: 'https://www.linkedin.com/company/responsible-mica-initiative/',
  },
  // Together for Sustainability Initiative (TfS)
  '01gkgr34bs9vpmnm4r6zjmbdq2': {
    imgUrl: '/images/TfS.png',
    website: 'https://www.tfs-initiative.com/',
    linkedin: 'https://www.linkedin.com/company/together-for-sustainability/',
  },
  // Advance - Gender Equality in Business
  '01gg7spvgk2v2z6233fegkj5je': {
    imgUrl: '/images/advance_gender.png',
    website: 'https://weadvance.ch/',
  },
  // Act4nature international
  '01gb2dh4k8w30ejkydddgf81qq': {
    imgUrl: '/images/act4nature-international.png',
    website: 'http://www.act4nature.com/en/who-are-we/',
    linkedin: 'https://www.linkedin.com/company/act4nature-international/',
  },
  // REDcert2
  '01gerq12tqggm6x2csk7djv2bv': {
    imgUrl: '/images/REDcert2.png', // orig. file is jpeg
    website: 'https://www.redcert.org/en/',
    linkedin: 'https://www.linkedin.com/company/redcert/',
  },
  // eduQua - Swiss quality label for further education institutions
  '01gg73zbxwtqdr83ya09enzf2j': {
    imgUrl: '/images/eduqua.png',
    website: 'https://alice.ch/de/dienstleistungen/eduqua/',
  },
  // CO2 Prestatieladder (CO2 Performance Ladder)
  '01gmddkth92ppqhz7fjwz662j1': {
    imgUrl: '/images/co2_prestatieladder.png',
    website: 'https://www.co2-prestatieladder.nl/en/certificaathouders',
    linkedin:
      'https://www.linkedin.com/company/stichting-klimaatvriendelijk-aanbesteden-en-ondernemen/',
  },
  // Australian Animal Welfare Certification System (AAWCS)
  '01gp3ckzffv05wtzp2z4dwn9rh': {
    imgUrl: '/images/aawcs.png',
    website: 'https://aawcs.com.au/',
  },
  // GLOBAL Good Agricultural Practices (G.A.P.) - Compound Feed Manufacturing Standard (CFM)
  '01gp34vt4nn3g521znnh6ksqwn': {
    imgUrl: '/images/globalgap.png',
    website: 'https://www.globalgap.org/uk_en/',
  },
  // International Featured Standards (IFS) - Broker
  '01gnykzheb134vczapv1v2eq81': {
    imgUrl: '/images/ifs.png',
    website: 'https://www.ifs-certification.com/index.php/en/standards/497-ifs-broker-en',
    linkedin: 'https://www.linkedin.com/company/ifs-international-featured-standards/',
  },
  // Toitū Carbonreduce
  '01gnw13mzk1vfnrak7ndc7d72p': {
    imgUrl: '/images/toitu-carbonreduce.png',
    website: 'https://www.toitu.co.nz/what-we-offer/carbon-management',
    linkedin: 'https://www.linkedin.com/company/toituenvirocare/',
  },
  // Toitū Net Carbonzero
  '01gnyecq2aa95rqsznfpb4qef0': {
    imgUrl: '/images/toitu-netcarbonzero.png',
    website: 'https://www.toitu.co.nz/what-we-offer/carbon-management',
    linkedin: 'https://www.linkedin.com/company/toituenvirocare/',
  },
  // Toitū Enviromark
  '01gnvaq23xh856r3k8qk13wjas': {
    imgUrl: '/images/toitu_enviromark.png',
    website: 'https://www.toitu.co.nz/what-we-offer/carbon-management',
    linkedin: 'https://www.linkedin.com/company/toituenvirocare/',
  },
  // The Global Economy
  '01gpzn0c41d355agjft647985f': {
    imgUrl: '/images/theglobaleconomy.png',
    website: 'https://www.theglobaleconomy.com/',
  },
  // U.S. Department of State
  '01gpzn6k8sy2csa2g3qd65tzrp': {
    imgUrl: '/images/us_department_of_state.png',
    website: 'https://www.state.gov/',
  },
  // Freedom House
  '01gpzmc51ezv1rxagpf75xzcj0': {
    imgUrl: '/images/Freedom_House.png',
    website: 'https://freedomhouse.org/',
  },
  // The World Bank
  '01gpzn3aa6x5cgf8qg25ajrts4': {
    imgUrl: '/images/the-world-bank.png',
    website: 'https://www.worldbank.org/en/home',
    linkedin: 'https://www.linkedin.com/company/the-world-bank/',
  },
  // GlobalPropertyGuide
  '01gpzmg1wkqf43nmmd6nezt8h0': {
    imgUrl: '/images/global-property-guide.png',
    website: 'https://www.globalpropertyguide.com/',
    linkedin: 'https://www.linkedin.com/company/global-property-guide/',
  },
  // Japan Industrial Safety & Health Association (JISHA)
  '01gpzmvhhygxxq3s9vbmx9w4ck': {
    imgUrl: '/images/jisha.png',
    website: 'https://www.jisha.or.jp/english/',
  },
  // China Labour Buleting (CLB)
  '01gpzmbh8nfyfb263fr4kx9j57': {
    imgUrl: '/images/clb.png',
    website: 'https://clb.org.hk/',
  },
  // Sensoneo
  '01gpzmzqy3x5skgncb0ns115m8': {
    imgUrl: '/images/sensoneo.png',
    website: 'https://sensoneo.com/',
    linkedin: 'https://www.linkedin.com/company/sensoneo/',
  },
  // Aqueduct
  '01gpzm7xf6nvtpbr3cjpzfk39e': {
    imgUrl: '/images/aqueduct.png',
    website: 'https://www.wri.org/aqueduct',
    linkedin: 'https://www.linkedin.com/company/world-resources-institute/',
  },
  // Food and Agriculture Organization of the United Nations
  '01gpzmbhzwnrsejbw7q7fkrrxx': {
    imgUrl: '/images/FAO.png',
    website: 'https://www.fao.org/home/en/',
    linkedin: 'https://www.linkedin.com/company/fao/',
  },
  // ILOStat
  '01gpzmg8v5wbsja40ez10f9azd': {
    imgUrl: '/images/ILO.png',
    website: 'https://ilostat.ilo.org/',
  },
  // International Trade Union Confederation (Responsible Sourcing Tool)
  '01gpzmrqedwcfdwnm9qq108fp0': {
    imgUrl: '/images/ITUC.png',
    website: 'https://www.ituc-csi.org/',
    linkedin: 'https://www.linkedin.com/company/international-trade-union-confederation/',
  },
  // OECD
  '01gpzmvj8yvsxwwcna8gnd322y': {
    imgUrl: '/images/OECD.png',
    website: 'https://www.oecd.org/',
    linkedin:
      'https://www.linkedin.com/company/organisation-eco-cooperation-development-organisation-cooperation-developpement-eco/',
  },
  // Japan Business Initiative for Biodiversity (JBIB)
  '01gqhca75497vj2c22d9pdb8yb': {
    imgUrl: '/images/jbib.png',
    website: 'https://en.jbib.org/',
  },
  // The Clean Ocean Material Alliance (CLOMA)
  '01gqhcrzm3jhzcd1wc97pv5v02': {
    imgUrl: '/images/cloma.png',
    website: 'https://cloma.net/english/',
  },
  // The European Federation for Cosmetic Ingredients (EFCI) - Good Manufacturing Practices (GMP)
  '01gqhjqzpwaj70d6hnb93qm1bh': {
    imgUrl: '/images/effci.png',
    website: 'https://effci.com/',
  },
  // CERES
  '01gr1aa4qs6r11y85f3619r32e': {
    imgUrl: '/images/ceres-logo.png',
    website: 'https://ceres-risk.com/',
  },
  // ENCORE
  '01gr1ab5t82t1akb0s7zkcbaj6': {
    imgUrl: '/images/encore-logo-32x32.png',
    website: 'https://encore.naturalcapital.finance/en',
  },
  // The Climate Group - EV100
  '01gjd83jxrtmqtp3cw8zxfjyh5': {
    imgUrl: '/images/climategroup.png',
    website: 'https://www.theclimategroup.org/ev100-fifth-year-anniversary',
    linkedin: 'https://www.linkedin.com/company/the-climate-group/',
  },
  // Programme for Endorsement of Forest Certification (PEFC) - Sustainable Forest Management
  '01fxmdkth2j3ncb7b0zy19yh04': {
    imgUrl: '/images/pefc.png',
    website: 'https://www.pefc.org/',
    linkedin: 'https://www.linkedin.com/company/pefc-international/',
  },
  // Getting to Zero Coalition
  '01gjd83grmbdqnmq3rn228fx1a': {
    imgUrl: '/images/gmf.png',
    website: 'https://www.globalmaritimeforum.org/',
    linkedin: 'https://www.linkedin.com/company/globalmaritimeforum/',
  },
  // Sustainability Accounting Standards Board (SASB)
  '01gxtzfne8panwafgz2m1s58b7': {
    imgUrl: '/images/sasb.png',
    website: 'https://www.sasb.org/',
    linkedin: 'https://www.linkedin.com/company/sasb-sustainability-accounting-standards-board/',
  },
  '01gpjkmsaw6nsth38tbpypdkxw': {
    imgUrl: '/images/secr.png',
    website:
      'https://www.gov.uk/government/publications/academy-trust-financial-management-good-practice-guides/streamlined-energy-and-carbon-reporting',
    linkedin: 'https://www.linkedin.com/groups/8813347/',
  },

  '01gg7rj8wseabb1mn1qm3ynvz6': {
    imgUrl: '/images/5050women.png',
    website: 'https://5050wob.com/',
    linkedin: 'https://www.linkedin.com/company/5050-women-on-boards/',
  },

  '01gg7qpmbxmq71kwy5grzn797v': {
    imgUrl: '/images/cep.png',
    website: 'https://cep2030.org/',
    linkedin:
      'https://www.linkedin.com/company/circular-electronics-partnership/?originalSubdomain=ch',
  },

  '01gk20qmbxdcmz6fv6swcjk90d': {
    imgUrl: '/images/dsgc.png',
    website: 'https://www.dsgc.nl/en',
    linkedin: 'https://www.linkedin.com/company/dsgcoalition/',
  },

  '01h0f8yckz2xqdwr7bx177c1td': {
    imgUrl: '/images/recyclass.png',
    website: 'https://recyclass.eu/',
    linkedin: 'https://www.linkedin.com/company/recyclass/?originalSubdomain=be',
  },

  '01h0f8jjeaqyahh3mhy1gt2262': {
    imgUrl: '/images/recyclass.png',
    website: 'https://recyclass.eu/',
    linkedin: 'https://www.linkedin.com/company/recyclass/?originalSubdomain=be',
  },

  '01h0fqv8gtn2dqr15s43avnzh6': {
    imgUrl: '/images/recyclass.png',
    website: 'https://recyclass.eu/',
    linkedin: 'https://www.linkedin.com/company/recyclass/?originalSubdomain=be',
  },

  '01ghgv4eqsmsf9tnvqn6w18n00': {
    imgUrl: '/images/disabilityconfident.png',
    website: 'https://www.gov.uk/government/collections/disability-confident-campaign',
    linkedin: 'https://www.linkedin.com/showcase/disability-confident/',
  },
  /** FSC */
  '01h2r75r0fg5tzgtd3gmeja7gc': {
    imgUrl: '/images/fsc.png',
    website: 'https://fsc.org/',
    linkedin: 'https://www.linkedin.com/company/fsc-international-bonn/',
    tableColumns: [
      {
        name: 'Validity period',
        keys: ['First Issue Date', 'Expiry Date'],
        content: (values: string[]) => (values.length >= 1 ? `${values[0]} - ${values[1]}` : ''),
      },
      {
        name: 'Location',
        keys: ['Site City'],
        content: (strings: string[]) => (strings.length > 0 ? `${strings[0]}` : ''),
      },
    ],
  },
  // Carbon Footprint - Carbon Neutral
  '01ghxamkh58p4zrynnyysk0ebw': {
    imgUrl: '/images/carbon_footprint.png',
    website: 'https://www.carbonfootprint.com/',
    lincckedin: 'https://www.linkedin.com/company/carbon-footprint-ltd/',
  },
  // Construction Logistics and Community Safety (CLOCS)
  '01hdjy4gyt56dewkp4b7k2w60e': {
    imgUrl: '/images/CLOCS.png',
    website: 'https://www.clocs.org.uk/',
    lincckedin: 'https://www.linkedin.com/company/clocs/',
  },
  // Ellen MacArthur Foundation - Global commitment
  '01h0fncgcj43pavznfmxxbqvw2': {
    imgUrl: '/images/Ellen_MacArthur_Foundation.png',
    website: 'https://www.ellenmacarthurfoundation.org/',
    linkedin: 'https://www.linkedin.com/company/ellen-macarthur-foundation/',
  },
  // Pharmaceutical Supply Chain Initiative (PSCI)
  '01fydvpajqm1nsm5brn2twm969': {
    imgUrl: '/images/PSCI.png',
    website: 'https://pscinitiative.org/home',
    linkedin: 'https://www.linkedin.com/company/pscinitiative/',
  },
  // Taskforce on Climate-Related Financial Disclosures (TCFD)
  '01hdnt7b66scwp9prmz8t04caj': {
    imgUrl: '/images/TCFD.png',
    website: 'https://www.fsb-tcfd.org/',
    linkedin:
      'https://www.linkedin.com/company/task-force-on-climate-related-financial-disclosures-tcfd-/',
  },
  // Fleet Operator Recognition Scheme (FORS)
  '01hdgxstymvd7sz8bzyv5zmkpw': {
    imgUrl: '/images/FORS.png',
    website: 'https://www.fors-online.org.uk/cms/',
    linkedin: 'https://www.linkedin.com/company/fleet-operator-recognition-scheme-fors-/',
  },
  // Gestes Propre
  '01ghxf16t5edp0tgj3rrb44pgp': {
    imgUrl: '/images/gestes_propres.png',
    website: 'https://www.gestespropres.com/',
    linkedin: 'https://www.linkedin.com/company/gestes-propres/',
  },
  // Responsible Distribution
  '01hbtq6vgar1scraqs9cwehz6e': {
    imgUrl: '/images/ACD.png',
    website: 'https://www.acd-chem.com/responsible-distribution/about-responsible-distribution/',
    linkedin: 'https://www.linkedin.com/company/alliance-for-chemical-distribution-acd/',
  },
  // Equalitas
  '01hd6jfkx5827x60wyartpm8zv': {
    imgUrl: '/images/Equalitas.png',
    website: 'https://www.equalitas.it/en/equalitas-sustainable-wineries/',
    linkedin: '',
  },
  // Taskforce on Nature-related Financial Disclosures (TNFD)
  '01gpjmzn8gnbct85ysf21eaz1n': {
    imgUrl: '/images/taskforce_on_nature_related_financial_disclosures_tnfd_logo.png',
    website: 'https://tnfd.global/',
    linkedin:
      'https://www.linkedin.com/company/taskforce-on-nature-related-financial-disclosures-tnfd/',
  },
  // The Woodland Trust - Woodland Carbon Code (WCC)
  '01ghgnrwfhp1b9xbva96yyzkv5': {
    imgUrl: '/images/Woodland_trust.png',
    website: 'https://www.woodlandtrust.org.uk/',
    linkedin: 'https://www.linkedin.com/company/the-woodland-trust/',
  },
  // Processed Chlorine Free (PCF)
  '01gb4rqb6jg6x4jwkrgnah32b1': {
    imgUrl: '/images/PCF.png',
    website: 'https://www.ecolabelindex.com/ecolabel/processed-chlorine-free',
    linkedin: '',
  },
  // Blue Angel (Blauer Engel) - Printed matters (DE-UZ 195)
  '01gx5hz9ntxww3h7vv3301hejc': {
    imgUrl: '/images/blue_angel.png',
    website: 'https://www.blauer-engel.de/de',
    linkedin: 'https://www.linkedin.com/company/blauer-engel-uz/',
  },
  // Organic Trade Association (OTA)
  '01hcm6k76ktd144tab3fjq6x1s': {
    imgUrl: '/images/organic_trade_association.png',
    website: 'https://www.ota.com/',
    linkedin: 'https://www.linkedin.com/company/organic-trade-association/',
  },
  // My Green Lab Certification
  '01fy6ac67fh5gre3vafk32n5br': {
    imgUrl: '/images/my_green_lab.png',
    website: 'https://www.mygreenlab.org/',
    linkedin: 'https://www.linkedin.com/company/my-green-lab/',
  },
  // KRAV - a Label for Organic Food
  '01gb2h4hd5mdwzn3thg8mw0dzk': {
    imgUrl: '/images/krav.png',
    website: 'https://www.krav.se/',
    linkedin: 'https://www.linkedin.com/company/krav-ek-f-r/',
  },
  // Business Benchmark on Farm Animal Welfare (BBFAW)
  '01gq01fnephgh51vndxb48gc16': {
    imgUrl: '/images/BBFAW.png',
    website: 'https://www.bbfaw.com/',
    linkedin: 'https://www.linkedin.com/company/business-benchmark-on-farm-animal-welfare-bbfaw/',
  },
  // Global Food Safety Initiative (GFSI)
  '01gjd83kkg93xq0jn0khda3qzp': {
    imgUrl: '/images/gfsi.png',
    website:
      'https://mygfsi.com/?utm_source=linkedin&utm_medium=social&utm_campaign=linkedin-company-page',
    linkedin: 'https://www.linkedin.com/company/global-food-safety-initiative/',
  },
  // BRCGS START!
  '01heag5yysd75mstkcpw7adxej': {
    imgUrl: '/images/BRCGS_Start.png',
    website: 'https://www.brcgs.com/resources/service-package/',
    linkedin: 'https://www.linkedin.com/company/brcgs/',
  },
  // ACT Environmental Impact Factor Label
  '01fyrrj670n617wgpswg5rh5pk': {
    imgUrl: '/images/ACT.png',
    website: 'https://act.mygreenlab.org/',
    linkedin: 'https://www.linkedin.com/company/my-green-lab/',
  },
  // Charte de la diversité
  '01hhm8367hzwna2qz9qxzbcwnh': {
    imgUrl: '/images/charte_de_la_diversite.png',
    website: 'https://www.charte-diversite.com/',
    linkedin: 'https://www.linkedin.com/company/charte-diversite/',
  },
  // Charte Relations Fournisseurs et Achats Responsables (RFAR)
  '01hjzejmmfkmq71fm5z3mxpnn7': {
    imgUrl: '/images/Charte_Relations_Fournisseurs_et_Achats_Responsables_(RFAR).png',
    website: 'http://www.rfar.fr/charte-relations-fournisseurs-et-achats-responsables/',
    linkedin: '',
  },
  // ISO 20400:2017 - Sustainable procurement
  '01hjnnvw19qm2600sdz3vck4bp': {
    imgUrl: '/images/6.png',
    website: 'https://www.iso.org/standard/63026.html',
    linkedin: 'https://www.linkedin.com/company/isostandards/',
  },
  // L’association pour la transition Bas Carbone (ABC)
  '01hjnmsxgndn0qrc1mkn8mccjj': {
    imgUrl: '/images/ABC.png',
    website: 'https://abc-transitionbascarbone.fr/',
    linkedin: 'https://www.linkedin.com/company/abc-transition-bas-carbone/',
  },
  // Integrated Farm Assurance Control Points and Compliance Criteria v5.2 (IFA CPCC) - Poultry
  '01hj99x476j0695vp0t6bprvrc': {
    imgUrl: '/images/IFA.png',
    website: 'https://www.globalgap.org/',
    linkedin: '',
  },
  // Bundesverband Materialwirtschaft, Einkauf und Logistik (BME) - Code of Conduct
  '01hj913bt55mnp415fz65b2p3p': {
    imgUrl: '/images/250-BME_Siegel_CodeofConduct_RGB_2023.png',
    website: 'https://www.bme.de/',
    linkedin: 'https://www.linkedin.com/company/bmeev/',
  },
  // Integrated Farm Assurance Control Points and Compliance Criteria v5.2 (IFA CPCC) - Crops Base
  '01hj42a59tq5nw4mt26e7ngq00': {
    imgUrl: '/images/IFA.png',
    website: 'https://www.globalgap.org/',
    linkedin: '',
  },
  // Integrated Farm Assurance Control Points and Compliance Criteria v5.2 (IFA CPCC) - Combinable Crops
  '01hj425frtfy4hsz2s9frv6vba': {
    imgUrl: '/images/IFA.png',
    website: 'https://www.globalgap.org/',
    linkedin: '',
  },
  // Integrated Farm Assurance Control Points and Compliance Criteria v5.2 (IFA CPCC) - All Farm Base
  '01hj421tyhbwcv264bm8v9vw18': {
    imgUrl: '/images/IFA.png',
    website: 'https://www.globalgap.org/',
    linkedin: '',
  },
  // 1PacteClimat
  '01hhm8rr6rdmn7xv37pr02422j': {
    imgUrl: '/images/1PacteClimat.png',
    website: '',
    linkedin: '',
  },
  // French Gender Equality Index
  '01fye8n90853124b5qeba3h93p': {
    imgUrl: '/images/logo-index-egalite-professionnelle-e1675861524543.png',
    website: 'https://www.service-public.fr/particuliers/vosdroits/F35103?lang=en',
    linkedin: '',
  },
  // Ellen MacArthur Foundation's Circulytics
  '01ghzrb7cz2s4aghhksfekj3hz': {
    imgUrl: '/images/Circulytics-logo.png',
    website: 'https://www.ellenmacarthurfoundation.org/resources/circulytics/overview',
    linkedin: 'https://www.linkedin.com/company/ellen-macarthur-foundation/',
  },
  // Ecovadis CSR Scorecard
  '01hkvwywzgmsfw62hrr0wpch4p': {
    imgUrl: '/images/Ecovadis CSR Scorecard.png',
    website: 'https://ecovadis.com/',
    linkedin: 'https://www.linkedin.com/company/ecovadis/',
  },
  // EU Ecolabel - Printed Paper, Stationary and Paper Carrier Bags
  '01gbmke2kb7t3fq5mbzkvcyqqj': {
    imgUrl: '/images/euecolabelpaper.png',
    website:
      'https://environment.ec.europa.eu/topics/circular-economy/eu-ecolabel-home/product-groups-and-criteria/paper_en ',
    linkedin: 'https://www.linkedin.com/company/euecolabel/',
  },
  // Food and Drug Administration (FDA) Approved
  '01h582awrfvf2zv3p58c75s96m': {
    imgUrl: '/images/FDA.png',
    website: 'https://www.fda.gov/ ',
    linkedin: 'https://www.linkedin.com/company/fda/',
  },
  // ISO 22005 - Traceability in the Feed and Food Chain
  '01g882s7mgfczyzyc7xfxj00c6': {
    imgUrl: '/images/ISO22005.png',
    website: 'https://www.iso.org/home.html ',
    linkedin: 'https://www.linkedin.com/company/isostandards/',
  },
  // ISO/IEC 27001- Information Security Management
  '01hkpwywvv1em6rt0npdvw3ard': {
    imgUrl: '/images/ISO27001.png',
    website: 'https://www.iso.org/home.html ',
    linkedin: 'https://www.linkedin.com/company/isostandards/',
  },
  // Roundtable on Responsible Soy (RTRS)
  '01g5knxt6gkj66qrefzkd8t93d': {
    imgUrl: '/images/RTRS.png',
    website: 'https://responsiblesoy.org/?lang=en ',
    linkedin: 'https://www.linkedin.com/company/round-table-for-responsible-soy-association/',
  },
  // UL Greenguard Gold Certification
  '01hhj8kxch9t5mdwx8fm49xysf': {
    imgUrl: '/images/ul-greenguard-gold.png',
    website: 'https://www.ul.com/services/ul-greenguard-certification ',
    linkedin: '',
  },
  // ISO 20121 - Sustainable Events
  '01hh50g4cvqnkbsa9b2h5cdvcp': {
    imgUrl: '/images/ISO20121.png',
    website: 'https://www.iso.org/home.html ',
    linkedin: 'https://www.linkedin.com/company/isostandards/',
  },
  // Oeko-Tex STeP
  '01hnd50vs2djf0e319ef2anyr4': {
    imgUrl: '/images/oeko-tex-step.png',
    website: ' https://www.oeko-tex.com/ ',
    linkedin: 'https://www.linkedin.com/company/oeko-tex/',
  },
  // ISO 14155 - Good clinical practice
  '01h666arkdbv41r0ysb6y4v9yx': {
    imgUrl: '/images/ISO14155.png',
    website: 'https://www.iso.org/home.html ',
    linkedin: 'https://www.linkedin.com/company/isostandards/',
  },
  // ISO 9002 - Quality Management Systems Guidelines
  '01gp11fc2736wfjwwvhp1t3j3f': {
    imgUrl: '/images/ISO9002.png',
    website: 'https://www.iso.org/home.html ',
    linkedin: 'https://www.linkedin.com/company/isostandards/',
  },
  // Green-e Certified - Climate
  '01fxqm977vgbfega8bkprez0vb': {
    imgUrl: '/images/green-e.png',
    website: 'https://www.green-e.org/programs/climate ',
    linkedin: '',
  },
  // ISO/IEC 17025 - Testing and calibration laboratories
  '01hrcrjvtnssa6wvw228qhyk0z': {
    imgUrl: '/images/iso17025.png',
    website: 'https://www.iso.org/home.html ',
    linkedin: 'https://www.linkedin.com/company/isostandards/',
  },
  // Technology Sustainability index (TSi)
  '01hr4618gf251wsjcj76xe1fjv': {
    imgUrl: '/images/TSI.png',
    website: 'Technology Sustainability index (TSi) ',
    linkedin: 'https://www.linkedin.com/groups/12715861/',
  },
  // MAS Certified Green
  '01hs17kqapgxhkhkz6wa522w8r': {
    imgUrl: '/images/MAS CG_0.png',
    website: 'Home ',
    linkedin: '',
  },
  // Sustainable Fibre Programme (SFP)
  '01hsbfyw1s81czfdx7bg6n1bw5': {
    imgUrl: '/images/sfp.png',
    website:
      'https://www.controlunion.com/certification-program/sfp-sustainable-fiber-program/#about-the-standard',
    linkedin: '',
  },
  '01hrctatddfjd5azk4ddtk0yzq': {
    imgUrl: '/images/ZNU.png',
    website: 'ZNU Standard ǀ ZNU Standard',
    linkedin: 'https://www.linkedin.com/company/zentrum-für-nachhaltige-unternehmensführung-znu/',
  },
  // Maritime Anti-Corruption Network (MACN)
  '01hvm8dprmadpghjtrq0kdh0pa': {
    imgUrl: '/images/maritime_acn_logo.png',
    website: 'MACN - Maritime Anti-Corruption Network ',
    linkedin: 'https://www.linkedin.com/company/maritime-acn/',
  },
  // ISO 39001 - Road traffic safety management systems (RTS)
  '01hv3z32t66ag40xtc2nb2zz5h': {
    imgUrl: '/images/ISO39001.png',
    website: 'ISO 39001:2012 ',
    linkedin: 'https://www.linkedin.com/company/isostandards/?originalSubdomain=ch',
  },
  // ISO 22301 - Business Continuity Management (BCM)
  '01htm78xxqzfw461fabj16jef1': {
    imgUrl: '/images/ISO22301.png',
    website: 'ISO 22301:2019 ',
    linkedin: 'https://www.linkedin.com/company/isostandards/?originalSubdomain=ch',
  },
  // SASB
  '01h90m9m9fdjrgf7cc3wzxe72c': {
    imgUrl: '/images/sasb.png',
    website: 'https://sasb.ifrs.org/standards/',
  },
  // IFRS
  '01hv0w89bk3674n8900rhv44sp': {
    imgUrl: '/images/ifrs.png',
    website: 'https://www.ifrs.org/',
  },
  // Environmental Performance Index (EPI)
  '01gvfhqryk3w2dxg26bs8zqj2z': {
    imgUrl: '/images/epi.png',
    website: 'https://epi.yale.edu/',
  },
  // Veteran Owned Small Business (VOSB)
  '01j5drg5d61xpffhc2sqvqbstc': {
    imgUrl: '/images/VOSB.png',
    website: 'https://veterans.certify.sba.gov/',
    linkedin: '',
  },
  // Canadian Aboriginal & Minority Supplier Council (CAMSC) Certification
  '01j7xcb2ydwnbw6dmhqvzq6ed2': {
    imgUrl: '/images/CAMSC.png',
    website: 'https://camsc.ca/',
    linkedin: 'https://www.linkedin.com/company/camsc1/',
  },
  // Canadian Aboriginal & Minority Supplier Council (CAMSC)
  '01j3yvr3jtfxey7n1ct06pqbqe': {
    imgUrl: '/images/CAMSC.png',
    website: 'https://camsc.ca/',
    linkedin: 'https://www.linkedin.com/company/camsc1/',
  },
  // Charta der Vielfalt
  '01hpefjdq9ch6r5h975zme225s': {
    imgUrl: '/images/ChartaderVielfalt.png',
    website: 'https://www.charta-der-vielfalt.de/en/',
    linkedin: 'https://www.linkedin.com/company/charta-der-vielfalt/',
  },
  // Disability:IN
  '01j3hhj9kzv2hgd33gwvxprdej': {
    imgUrl: '/images/disabilityin_logo.png',
    website: 'https://disabilityin.org/',
    linkedin: 'https://www.linkedin.com/company/disabilityin/',
  },
  // MSDUK (Minority Supplier Development UK)
  '01j7xbpx83ehpngsknvv0why2g': {
    imgUrl: '/images/minority_supplier_development_uk_logo.png',
    website: 'https://www.msduk.org.uk/',
    linkedin: 'https://www.linkedin.com/company/minority-supplier-development-uk/',
  },
  // Supply Nation
  '01j7xbrq844v7zgbkxn4cgwb53': {
    imgUrl: '/images/supply_nation_logo.png',
    website: 'https://supplynation.org.au/',
    linkedin: 'https://www.linkedin.com/company/supply-nation/',
  },
  // World Benchmarking Alliance
  '01jaaw1e2n7pd451cvd9bq6c6q': {
    imgUrl: '/images/World_benchmarking_Alliance.png',
    website: 'https://www.worldbenchmarkingalliance.org/',
    linkedin: 'https://www.linkedin.com/company/world-benchmarking-alliance/',
  },
  // Worldsteel Sustainability Charter
  '01jad2e5s8wxjv9vg6kk2a0arv': {
    imgUrl: '/images/world_steel_association_logo.png',
    website: 'https://worldsteel.org',
    linkedin: 'https://www.linkedin.com/company/world-steel-association/',
  },
  // The Climate Action 100+ Net-Zero Company Benchmark
  '01j3558dsbbjw1zj10x8aqvdxb': {
    imgUrl: '/images/climate_action_100.png',
    website: 'https://www.climateaction100.org/',
    linkedin: 'https://www.linkedin.com/company/climateaction100/posts/?feedView=all',
  },
  // Towards Sustainable Mining (TSM)
  '01j8etcnan1vpqyjz6pz9bsfdj': {
    imgUrl: '/images/TSM.png',
    website: 'https://tsminitiative.com/',
    linkedin: 'https://www.linkedin.com/company/towards-sustainable-mining-tsm/',
  },
  // 4evergreen alliance
  '01j3z03w9c623pmmebxfagtrxn': {
    imgUrl: '/images/4evergreen_alliance_logo.png',
    website: 'https://4evergreenforum.eu/',
    linkedin: 'https://www.linkedin.com/company/4evergreen-alliance/',
  },
  // Action for Sustainable Derivatives (ASD)
  '01j2k6yz0qx0nf5qywrzyr6vqp': {
    imgUrl: '/images/ASD.png',
    website: 'https://sustainablederivatives.org/',
    linkedin: 'https://www.linkedin.com/company/action-for-sustainable-derivatives/',
  },
  // Deutsche Gesellschaft für Nachhaltiges Bauen (DGNB) - Membership
  '01hsy429v1thq36jty9ape9gdt': {
    imgUrl: '/images/dgnb_german_sustainable_building_council_logo.png',
    website: 'https://www.dgnb.de/en',
    linkedin: 'https://www.linkedin.com/company/dgnb-german-sustainable-building-council/',
  },
  // econsense - The Forum for Sustainable Development of German Business
  '01hy2zffj5w64rewrqe9cye88r': {
    imgUrl: '/images/econsense_logo.png',
    website: 'https://econsense.de/',
    linkedin: 'https://www.linkedin.com/company/econsense/',
  },
  // Forum Nachhaltiges Palmöl (FONAP)
  '01j358be588cqghq2mmc0rv0dj': {
    imgUrl: '/images/FONAP.png',
    website: 'https://forumpalmoel.org/',
    linkedin: 'https://www.linkedin.com/company/palmoelforum/posts/?feedView=all',
  },
  // Global Water Partnership (GWP)
  '01j1vvayzm8gqjjv27zz1vyga2': {
    imgUrl: '/images/global_water_partnership_logo.png',
    website: 'https://www.gwp.org/',
    linkedin: 'https://www.linkedin.com/company/global-water-partnership/',
  },
  // IntegrityNext
  '01g8ak62ev1densc8db54ctdbb': {
    imgUrl: '/images/integritynext_logo.png',
    website: 'https://www.integritynext.com/',
    linkedin: 'https://www.linkedin.com/company/integritynext/',
  },
} as {
  [key: string]: DataSourceStaticMetadata
}

// const mappedTest = Object.keys(staticMetaData).map((x) => {
//   const dataSourceId = x
//   const { imgUrl } = staticMetaData[x]
//   return {
//     dataSourceId,
//     imgUrl,
//   }
// })
// console.log(mappedTest)
// https://www.convertsimple.com/convert-javascript-array-to-csv/

export default staticMetaData

/* TEMPLATE
  //
  "": {
    imgUrl: "/images/.png",
    website: "",
    linkedin: ""
  },
*/
