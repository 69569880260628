import { tokenAxiosInstance } from 'services/apiClient'
import mingle from 'services/mingle'
import { DataFeedItemsOptionsV2, DataRequestScoringRule } from 'services/mingle/__generated__/Api'
import { SupplierDiscoveryItem } from './model/SupplierDiscoveryItem'
import { IndustryDiscoveryItem } from './model/IndustryDiscoveryItem'
import { DataDiscoveryItem } from './model/DataDiscoveryItem'

const baseURL = '/.netlify/functions'

export enum SubscriptionType {
  WEBSITE = 'WEBSITE',
  INDUSTRY = 'INDUSTRY',
  DATA = 'DATA',
  GLOBAL_DB = 'GLOBAL_DB',
  NEWS_AND_MEDIA = 'NEWS_AND_MEDIA',
  SUPPLIER_ALIAS = 'SUPPLIER_ALIAS',
}

export interface DataSubscription {
  organizationSupplierId: string
  subscriptionType: SubscriptionType
  searchValue: string
}

export interface PostDataFeedSupplierAliasProps {
  supplierName: string
  subscription: {
    organizationId: string
    supplierId: string
  }
}

export const postNewsAndMediaEventScores = async (eventScores: {
  supplierId: string
  dataFeedItemId: string
  parameterScores: { parameterId: string; score: number }[]
}) => {
  return mingle.app.postNewsAndMediaArticleScores(eventScores)
}

export const getEntriesByQuestion = async ({
  dataRequestQuestionId,
  organizationSupplierId,
}: {
  dataRequestQuestionId: string
  organizationSupplierId?: string
}) => {
  const { data } = await mingle.app.getDataRequestAnswerEntriesFromQuestion({
    dataRequestQuestionId,
    organizationSupplierId,
  })

  return data
}

export const getDataFeedItems = async ({
  supplierId,
  dataEntryIds,
  supplierIds,
  page,
  pageSize,
  order,
  filter,
}: DataFeedItemsOptionsV2) => {
  const { data } = await mingle.app.getDataFeedItemsByOrganizationV2({
    supplierId,
    supplierIds,
    dataEntryIds,
    page,
    pageSize,
    order,
    filter,
  })
  return {
    page,
    data,
  }
}

export const getArticleById = async ({ articleId }: { articleId: string }) => {
  const { data } = await mingle.app.getArticlesById({ id: articleId })
  return data
}

export const getDataSubscriptions = async (organizationSupplierId: string) => {
  const { data: response } = await tokenAxiosInstance.get<{ items: DataSubscription[] }>(
    '/getDataSubscriptions',
    {
      baseURL,
      params: { organizationSupplierId },
    }
  )

  return response
}

export const deleteDataSubscription = async (subscription: DataSubscription) => {
  const { data: response } = await tokenAxiosInstance.post(
    '/deleteDataSubscription',
    subscription,
    { baseURL }
  )

  return response
}

export const createDataSubscription = async (subscription: DataSubscription) => {
  const { data: response } = await tokenAxiosInstance.post(
    '/createDataSubscription',
    subscription,
    { baseURL }
  )

  return response
}

export const getSupplierDiscoveries = async (
  searchValues: { supplierName: string; supplierCountry?: string }[]
) => {
  const { data: response } = await tokenAxiosInstance.post<{ items: SupplierDiscoveryItem[] }>(
    '/getSupplierDiscoveries',
    { searchValues },
    { baseURL }
  )

  return response
}

export interface WebsiteDiscoveryItem {
  searchValue: string
  result: string
  createdAt?: string
}

export const getWebsiteDiscoveries = async (
  searchValues: { supplierName: string; supplierCountry?: string }[]
) => {
  const { data: response } = await tokenAxiosInstance.post<{
    items: WebsiteDiscoveryItem[]
  }>('/getWebsiteDiscoveries', { searchValues }, { baseURL })

  return response
}

export const getIndustryDiscoveries = async (domains: string[]) => {
  const { data: response } = await tokenAxiosInstance.post<{ items: IndustryDiscoveryItem[] }>(
    '/getIndustryDiscoveries',
    { domains },
    { baseURL }
  )

  return response
}

export const getDataDiscoveries = async (domains: string[]) => {
  const { data: response } = await tokenAxiosInstance.post<{ items: DataDiscoveryItem[] }>(
    '/getDataDiscoveries',
    { domains },
    { baseURL }
  )

  return response
}

export const createDataFeedSupplierAliasItem = async (props: PostDataFeedSupplierAliasProps) => {
  const { data: response } = await tokenAxiosInstance.post(
    // Note that a suggested alias is generated by the promptGetCleanedCompanyName function in the netlify function for this endpoint, to create cleaned alias data feed
    '/minglePostDataFeedSupplierAliasItem',
    props,
    {
      baseURL,
    }
  )

  return response
}

type UpdateDataFeedItemStatusParams = Parameters<typeof mingle.app.updateDataFeedItemStatus>
export const updateDataFeedItemStatus = async (props: {
  id: string
  status: UpdateDataFeedItemStatusParams[1]['status']
}) => {
  await mingle.app.updateDataFeedItemStatus(props.id, { status: props.status })

  return {}
}

export const getDataRequestAnswerEntriesFromRequest = async (props: { dataRequestId: string }) => {
  const { data } = await mingle.app.getDataRequestAnswerEntriesFromRequest(props)

  return data
}

export const scoreDataRequestAnswers = async (props: {
  supplierId: string
  dataRequestAnswerIds: string[]
  parameterScores: { parameterId: string; score: number }[]
  groupName?: string
}) => {
  const { data } = await mingle.app.postDataRequestAnswerScores(props)

  return data
}

export const deleteDataRequestAnswerScores = async (props: { dataFeedItemId: string }) => {
  const { data } = await mingle.app.deleteDataRequestAnswerScores(props)

  return data
}

export const updateDataRequestAnswerScores = async (props: {
  dataFeedItemId: string
  dataRequestAnswerIds: string[]
  parameterScores: { parameterId: string; score: number }[]
  groupName?: string
}) => {
  const { data } = await mingle.app.updateDataRequestAnswerScores(props)

  return data
}

export const getDataRequestScoringFormulas = async (props: { ids?: string[] }) => {
  const { data } = await mingle.app.getDataRequestScoringFormulas(props)

  return data
}

export const createDataRequestScoringFormula = async (props: {
  title?: string
  parameterIds: string[]
  scoringRules: DataRequestScoringRule[]
}) => {
  const { data } = await mingle.app.postDataRequestScoringFormula(props)

  return data
}

export const updateDataRequestScoringFormula = async (props: {
  id: string
  title?: string
  parameterIds: string[]
  scoringRules: DataRequestScoringRule[]
}) => {
  const { data } = await mingle.app.updateDataRequestScoringFormula(props)

  return data
}

export const deleteDataRequestScoringFormula = async (props: { id: string }) => {
  const { data } = await mingle.app.deleteDataRequestScoringFormula(props)

  return data
}

export const triggerEvaluateFormulas = async (props: { dataRequestQuestionId: string }) => {
  const { data } = await mingle.dataRequestAnswer.triggerEvaluateFormulas(props)

  return data
}

export const createManualDataFeedItem = async (props: {
  supplierId: string
  domain: string
  type: 'domain'
  status:
    | 'approved'
    | 'declined'
    | 'in_review'
    | 'rejected'
    | 'auto_approved'
    | 'auto_declined'
    | 'ai_approved'
    | 'ai_declined'
}) => {
  const { data } = await mingle.app.postDataFeedItems(props)

  return data
}
