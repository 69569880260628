import { useState } from 'react'
import { Dialog, TextField } from 'components'
import { useSnackbar } from 'notistack'
import {
  Box,
  Button,
  ListItem,
  Checkbox,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from '@mui/material'
import { SubscriptionType } from 'hooks/dataFeed/api'
import { useQueryClient } from 'react-query'
import queryKeys from 'hooks/dataFeed/queryKeys'
import { useCreateDataSubscription, useCreateManualDataFeedItem } from 'hooks'

const getBaseDomain = (url?: string) => {
  if (!url || (url && url.includes('.') === false)) {
    return ''
  }

  try {
    const u = url.trim()
    const { hostname } = u.includes('://') ? new URL(u) : new URL(`http://${u}`)

    return hostname.startsWith('www.') ? hostname.slice(4) : hostname
  } catch (error) {
    return ''
  }
}

interface Props {
  otherDomains: string[]
  handleClose: () => void
  supplierId: string
  orgId: string
}

const AddDomainDialog = ({ otherDomains, handleClose, supplierId, orgId }: Props) => {
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  const [domain, setDomain] = useState('')
  const [checked, setChecked] = useState({
    dataDiscovery: false,
    industryDiscovery: false,
  })
  const [loading, setLoading] = useState<boolean>(false)

  const { mutateAsync: createManualDataFeedItem } = useCreateManualDataFeedItem()
  const { mutateAsync: createDataSubscription } = useCreateDataSubscription()

  const baseDomain = getBaseDomain(domain.trim())
  const existsAlready = otherDomains.includes(baseDomain)
  const isValidDomain = baseDomain.length > 0

  const handleSaveChanges = async () => {
    setLoading(true)
    const createItemPromise = createManualDataFeedItem({
      supplierId,
      domain: baseDomain,
      type: 'domain',
      status: 'approved',
    })
    const createDataSubscriptionPromise = checked.dataDiscovery
      ? [
          createDataSubscription({
            organizationSupplierId: `${orgId}#${supplierId}`,
            subscriptionType: SubscriptionType.DATA,
            searchValue: baseDomain,
          }),
        ]
      : []

    const createDataSubscriptionPromise2 = checked.industryDiscovery
      ? [
          createDataSubscription({
            organizationSupplierId: `${orgId}#${supplierId}`,
            subscriptionType: SubscriptionType.INDUSTRY,
            searchValue: baseDomain,
          }),
        ]
      : []

    await Promise.all([
      createItemPromise,
      ...createDataSubscriptionPromise,
      ...createDataSubscriptionPromise2,
    ])

    queryClient.invalidateQueries(queryKeys.allDataFeedItems)
    queryClient.invalidateQueries(queryKeys.dataSubscriptions(`${orgId}#${supplierId}`))

    setLoading(false)
    enqueueSnackbar('Website added!', {
      variant: 'success',
    })
    handleClose()
  }

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked({
      ...checked,
      [event.target.name]: event.target.checked,
    })
  }

  return (
    <Dialog
      open
      title="Add website"
      description="Add a new website to the supplier and select what features to enable."
      onClose={handleClose}
      withForm={false}
      withClose
      scroll="paper"
      maxWidth="xs"
      buttons={
        <>
          <Button variant="text" onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button
            onClick={() => handleSaveChanges()}
            disabled={Boolean(loading || !isValidDomain || existsAlready)}
          >
            {loading && <CircularProgress size={16} sx={{ color: 'common.white ' }} />} Add
          </Button>
        </>
      }
    >
      <Box>
        <TextField
          label="Domain"
          placeholder="Enter a domain"
          value={domain}
          name="domain"
          withDebounce
          required
          onChange={(_name, value) => setDomain(value)}
          fullWidth
          size="small"
          error={existsAlready || (domain.length > 2 && !isValidDomain)}
          helperText={
            (existsAlready && 'This domain is already added.') ||
            (domain.length > 2 && !isValidDomain && 'Please enter a valid domain.') ||
            undefined
          }
        />
        <ListItem sx={{ pl: 0 }}>
          <ListItemIcon sx={{ minWidth: 50 }}>
            <Checkbox
              onChange={handleCheckboxChange}
              name="dataDiscovery"
              checked={checked.dataDiscovery}
            />
          </ListItemIcon>
          <ListItemText
            primary="Data Discovery"
            secondary="Monitor the domain for sustainability related data like certificates & policy statements."
          />
        </ListItem>
        <ListItem sx={{ pl: 0 }}>
          <ListItemIcon sx={{ minWidth: 50 }}>
            <Checkbox
              onChange={handleCheckboxChange}
              name="industryDiscovery"
              checked={checked.industryDiscovery}
            />
          </ListItemIcon>
          <ListItemText
            primary="Industry Discovery"
            secondary="Based on the content of the domain, our AI will try to determine GICS industries for the supplier."
          />
        </ListItem>
      </Box>
    </Dialog>
  )
}

export default AddDomainDialog
