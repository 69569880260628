import { useQuery, UseQueryOptions, useInfiniteQuery } from 'react-query'
import { AxiosError } from 'axios'
import { DataFeedItemsOptionsV2 } from 'services/mingle/__generated__/Api'
import {
  getDataDiscoveries,
  getDataSubscriptions,
  getIndustryDiscoveries,
  getSupplierDiscoveries,
  getArticleById,
  getDataFeedItems,
  getWebsiteDiscoveries,
  getDataRequestAnswerEntriesFromRequest,
  getEntriesByQuestion,
  getDataRequestScoringFormulas,
} from './api'

import queryKeys from './queryKeys'

const { dataFeedItems, articleById, dataRequestAnswerEntriesFromRequest } = queryKeys

export const useGetArticleById = (
  articleId: string,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getArticleById>>,
    AxiosError,
    Awaited<ReturnType<typeof getArticleById>>,
    ReturnType<typeof articleById>
  >
) => {
  return useQuery(articleById(articleId), () => getArticleById({ articleId }), options)
}

export const useGetEntriesByQuestion = ({
  dataRequestQuestionId,
  organizationSupplierId,
  enabled,
}: {
  dataRequestQuestionId: string
  organizationSupplierId?: string
  enabled?: boolean
}) => {
  return useQuery(
    queryKeys.dataRequestAnswerEntriesFromQuestion(dataRequestQuestionId, organizationSupplierId),
    () => getEntriesByQuestion({ dataRequestQuestionId, organizationSupplierId }),
    {
      enabled,
    }
  )
}

export const defaultSupplierViewPayload: DataFeedItemsOptionsV2 = {
  pageSize: 100000,
  filter: {
    types: ['data_request_answer', 'adverse_media'],
  },
}

export const useGetDataFeedItemsV2 = (
  props?: DataFeedItemsOptionsV2,
  options?: {
    enabled?: boolean
    refetchInterval?: number
  }
) => {
  return useInfiniteQuery<
    Awaited<ReturnType<typeof getDataFeedItems>>,
    AxiosError,
    Awaited<ReturnType<typeof getDataFeedItems>>,
    ReturnType<typeof dataFeedItems>
  >(
    dataFeedItems(props),
    ({ pageParam = 1 }) =>
      getDataFeedItems({
        ...props,
        page: pageParam,
      }),
    {
      ...options,
      getNextPageParam: (lastPage) => {
        const total =
          lastPage.data.adverseMedia.length +
          lastPage.data.claims.length +
          lastPage.data.dataEntries.length +
          lastPage.data.dataRequestAnswers.length +
          lastPage.data.domains.length +
          lastPage.data.supplierAliases.length

        return props?.pageSize && total < (props.pageSize || 0)
          ? undefined
          : (lastPage.page || 0) + 1
      },
      select: (data) => {
        return {
          pageParams: data.pageParams,
          pages: data.pages.map((page, index) => {
            const previousPages = data.pages.slice(0, index)
            const previousFlatAdverseMedia = previousPages.flatMap((x) => x.data.adverseMedia)
            const previousFlatClaims = previousPages.flatMap((x) => x.data.claims)
            const previousFlatDataEntries = previousPages.flatMap((x) => x.data.dataEntries)
            const previousFlatDataRequestAnswers = previousPages.flatMap(
              (x) => x.data.dataRequestAnswers
            )
            const previousFlatDomains = previousPages.flatMap((x) => x.data.domains)
            const previousFlatSupplierAliases = previousPages.flatMap((x) => x.data.supplierAliases)

            return {
              ...page,
              data: {
                ...page.data,
                adverseMedia: page.data.adverseMedia.filter(
                  (x, i, self) =>
                    self.findIndex((y) => y.id === x.id) === i &&
                    !previousFlatAdverseMedia.find((y) => y.id === x.id)
                ),
                claims: page.data.claims.filter(
                  (x, i, self) =>
                    self.findIndex((y) => y.id === x.id) === i &&
                    !previousFlatClaims.find((y) => y.id === x.id)
                ),
                dataEntries: page.data.dataEntries.filter(
                  (x, i, self) =>
                    self.findIndex((y) => y.id === x.id) === i &&
                    !previousFlatDataEntries.find((y) => y.id === x.id)
                ),
                dataRequestAnswers: page.data.dataRequestAnswers.filter(
                  (x, i, self) =>
                    self.findIndex((y) => y.id === x.id) === i &&
                    !previousFlatDataRequestAnswers.find((y) => y.id === x.id)
                ),
                domains: page.data.domains.filter(
                  (x, i, self) =>
                    self.findIndex((y) => y === x) === i &&
                    !previousFlatDomains.find((y) => y === x)
                ),
                supplierAliases: page.data.supplierAliases.filter(
                  (x, i, self) =>
                    self.findIndex((y) => y === x) === i &&
                    !previousFlatSupplierAliases.find((y) => y === x)
                ),
              },
            }
          }),
        }
      },
    }
  )
}

export const useGetDataRequestAnswerEntriesFromRequest = (
  dataRequestId: string,
  options?: UseQueryOptions<
    Awaited<ReturnType<typeof getDataRequestAnswerEntriesFromRequest>>,
    AxiosError,
    Awaited<ReturnType<typeof getDataRequestAnswerEntriesFromRequest>>,
    ReturnType<typeof dataRequestAnswerEntriesFromRequest>
  >
) => {
  return useQuery(
    dataRequestAnswerEntriesFromRequest(dataRequestId),
    () => getDataRequestAnswerEntriesFromRequest({ dataRequestId }),
    options
  )
}

export const useGetDataSubscriptions = (organizationSupplierId: string, enabled?: boolean) => {
  return useQuery(
    queryKeys.dataSubscriptions(organizationSupplierId),
    () => getDataSubscriptions(organizationSupplierId),
    {
      retry: true,
      staleTime: 500000,
      enabled,
    }
  )
}

export const useGetSupplierDiscoveries = (
  searchValues: { supplierName: string; supplierCountry?: string }[],
  options?: { enabled?: boolean; refetchInterval?: number }
) => {
  return useQuery(
    queryKeys.supplierDiscoveries(searchValues),
    () => getSupplierDiscoveries(searchValues),
    {
      ...options,
      retry: true,
      staleTime: 500000,
    }
  )
}

export const useGetWebsiteDiscoveries = (
  searchValues: { supplierName: string; supplierCountry?: string }[],
  options?: { enabled?: boolean; refetchInterval?: number }
) => {
  return useQuery(
    queryKeys.websiteDiscoveries(searchValues),
    () => getWebsiteDiscoveries(searchValues),
    {
      ...options,
      retry: true,
      staleTime: 500000,
    }
  )
}

export const useGetIndustryDiscoveries = (
  domains: string[],
  options?: { enabled?: boolean; refetchInterval?: number }
) => {
  return useQuery(queryKeys.industryDiscoveries(domains), () => getIndustryDiscoveries(domains), {
    ...options,
    retry: true,
    staleTime: 500000,
  })
}

export const useGetDataDiscoveries = (domains: string[], enabled?: boolean) => {
  return useQuery(queryKeys.dataDiscoveries(domains), () => getDataDiscoveries(domains), {
    retry: true,
    staleTime: 500000,
    enabled,
  })
}

export const useGetDataRequestScoringFormulas = (
  ids?: string[],
  options?: { enabled?: boolean }
) => {
  return useQuery(
    queryKeys.dataRequestScoringFormulas(ids),
    () => getDataRequestScoringFormulas({ ids }),
    {
      ...options,
      retry: true,
      staleTime: 500000,
    }
  )
}
